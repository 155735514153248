/**
 * Part of Genesis application
 *
 * @copyright 2025 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

class LockData {
    id
    lock_version

    constructor(id, lock_version) {
        let source_id, source_version
        if (typeof id === 'object') {
            source_id = id.id
            source_version = id.lock_version
        } else {
            source_id = id
            source_version = lock_version
        }
        try {
            this.id = Number(source_id)
        } catch (e) {
            console.error('Invalid ID value %o', e)
            throw new Error('Invalid ID value.')
        }
        if (Number.isNaN(this.id)) {
            throw new Error('Invalid ID number.')
        }
        this.id = Math.trunc(this.id)

        try {
            this.lock_version = Number(source_version)
        } catch (e) {
            console.error(e)
            throw new Error('Invalid lock version value.')
        }
        if (Number.isNaN(this.lock_version)) {
            throw new Error('Invalid lock version number.')
        }
        this.lock_version = Math.trunc(this.lock_version)
    }
}

export default LockData
