/**
 * Part of Genesis application
 *
 * @copyright 2025 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const fuelUnit = {
    async list(params) {
        const response = await axios.get('/api/fuel_units', {params})

        return response.data.data
    },
    async show(fuel_unit_id) {
        const response = await axios.get(`/api/fuel_units/${fuel_unit_id}`)

        return response.data
    },
    async store(fuelUnit) {
        const response = await axios.post('/api/fuel_units', fuelUnit)

        return response.data
    },
    async update(fuelUnit) {
        await axios.patch(`/api/fuel_units/${fuelUnit.id}`, fuelUnit)
    },
}

export default fuelUnit