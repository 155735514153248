/**
 * Part of Genesis application
 *
 * @copyright 2021 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import accounting from './accounting'
import accountsReceivable from './accountsReceivable'
import attachment from './attachment'
import bankAccount from './bankAccount'
import axios from 'axios'
import billing from './billing'
import catalog from './catalog'
import coordinated from './coordinated'
import customerGroup from './customerGroup'
import exchangeRate from './exchangeRate'
import Export from './export'
import fuelEfficiency from './fuelEfficiency'
import grid from './grid'
import issuer from './issuer'
import traffic from './traffic'
import position from './position'
import query from './query'

export const Genesis = {
    customerGroup,
    accounting,
    accountsReceivable,
    attachment,
    bankAccount,
    billing,
    catalog,
    coordinated,
    exchangeRate,
    'export': Export,
    fuelEfficiency,
    grid,
    issuer,
    traffic,
    position,
    query,

    async search(url, params = {}) {
        const response = await axios.get(url, {params, responseType: 'json'})

        return response.data.data
    },

    async viewModel() {
        const response = await axios.get('/api/view_model', {responseType: 'json'})

        return response.data
    },
}

export default Genesis