<template>
    <localization :language="$i18n.locale">
        <multi-select
            ref="select"
            v-model="value"
            :auto-close="autoClose"
            :data-item-key="'id'"
            :data-items="filteredData"
            :text-field="'name'"
            :filterable="true"
            :placeholder="placeholder"
            @change="onSelectChange"
            @filterchange="onSelectFilterChange"
        />
    </localization>
</template>
<script>
import {MultiSelect} from '@progress/kendo-vue-dropdowns'
import {filterBy} from '@progress/kendo-data-query'
import {loadMessages, LocalizationProvider} from '@progress/kendo-vue-intl'
import {cloneDeep} from 'lodash'

export default {
    name: 'MultiSelectFilter',
    components: {
        MultiSelect,
        localization: LocalizationProvider,
    },
    props: {
        field: {
            type: String,
            required: true,
        },
        initialValue: {
            type: Array,
            default() {
                return []
            },
        },
        options: {
            type: Array,
            required: true,
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
        },
    },
    data() {
        return {
            filteredData: [],
            value: null,
        }
    },
    mounted() {
        loadMessages(this.$i18n.messages[this.$i18n.locale], this.$i18n.locale)
        this.filteredData = cloneDeep(this.options)
        this.value = this.initialValue
    },
    methods: {
        onSelectChange(event) {
            this.$emit('onChange', {
                operator: 'simple',
                field: this.field,
                value: event.value.map(o => o.id),
            })
        },
        onSelectFilterChange(event) {
            this.filteredData = filterBy(this.options, event.filter)
        },
    },
}
</script>
