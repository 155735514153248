/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import UserAuthorization from './UserAuthorization'
import VueRouter from 'vue-router'

export const RouteSource = {
    mode: 'history',
    routes: [
        {
            path: '/home',
            name: 'home',
            component: () => import('./dashboard/components/Dashboard'),
        },
        {
            path: '/accounts_receivable/payments',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'accounts_receivable.payments.index',
                    component: () => import('./accounts_receivable/payment/PaymentIndex.vue'),
                },
                {
                    path: 'create',
                    name: 'accounts_receivable.payments.create',
                    component: () => import('./accounts_receivable/payment/PaymentDetail.vue'),
                },
                {
                    path: ':id',
                    name: 'accounts_receivable.payments.show',
                    component: () => import('./accounts_receivable/payment/PaymentDetail.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'accounts_receivable.payments.edit',
                    component: () => import('./accounts_receivable/payment/PaymentDetail.vue'),
                },
            ],
        },
        {
            path: '/bank_accounts',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'bank_accounts.index',
                    component: () => import('./bank_account/BankAccountIndex.vue'),
                },
                {
                    path: 'create',
                    name: 'bank_accounts.create',
                    component: () => import('./bank_account/BankAccountDetail.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'bank_accounts.edit',
                    component: () => import('./bank_account/BankAccountDetail.vue'),
                },
                {
                    path: ':id',
                    name: 'bank_accounts.show',
                    component: () => import('./bank_account/BankAccountDetail.vue'),
                },
            ],
        },
        {
            path: '/billing_documents',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'billing_documents.index',
                    component: () => import('./billing/billing_document/BillingDocumentIndex'),
                },
            ],
        },
        {
            path: '/fuel_efficiencies',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'fuel_efficiencies.index',
                    component: () => import('./fuel_efficiency/FuelEfficiencyIndex'),
                },
                {
                    path: 'fuel_consumed',
                    name: 'fuel_efficiencies.fuel_consumed.index',
                    component: () => import('./fuel_efficiency/fuel_consumed/FuelConsumedIndex.vue'),
                },
                {
                    path: 'daily_fuel_consumed',
                    name: 'fuel_efficiencies.daily_fuel_consumed.index',
                    component: () => import('./fuel_efficiency/daily_fuel_consumed/DailyFuelConsumedIndex.vue'),
                },
            ],
        },
        {
            path: '/fuel_loads',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'fuel_loads.index',
                    component: () => import('./fuel_efficiency/fuel_load/FuelLoadIndex.vue'),
                },
                {
                    path: 'create',
                    name: 'fuel_loads.create',
                    component: () => import('./fuel_efficiency/fuel_load/FuelLoadDetail.vue'),
                },
                {
                    path: ':id',
                    name: 'fuel_loads.show',
                    component: () => import('./fuel_efficiency/fuel_load/FuelLoadDetail.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'fuel_loads.edit',
                    component: () => import('./fuel_efficiency/fuel_load/FuelLoadDetail.vue'),
                },
            ],
        },
        {
            path: '/fuel_prices',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'fuel_prices.index',
                    component: () => import('./fuel_efficiency/fuel_price/FuelPriceIndex.vue'),
                },
                {
                    path: 'create',
                    name: 'fuel_prices.create',
                    component: () => import('./fuel_efficiency/fuel_price/FuelPriceDetail.vue'),
                },
                {
                    path: ':id',
                    name: 'fuel_prices.show',
                    component: () => import('./fuel_efficiency/fuel_price/FuelPriceDetail.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'fuel_prices.edit',
                    component: () => import('./fuel_efficiency/fuel_price/FuelPriceDetail.vue'),
                },
            ],
        },
        {
            path: '/fuel_units',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'fuel_units.index',
                    component: () => import('./fuel_efficiency/fuel_unit/FuelUnitIndex.vue'),
                },
                {
                    path: 'create',
                    name: 'fuel_units.create',
                    component: () => import('./fuel_efficiency/fuel_unit/FuelUnitDetail.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'fuel_units.edit',
                    component: () => import('./fuel_efficiency/fuel_unit/FuelUnitDetail.vue'),
                },
                {
                    path: ':id',
                    name: 'fuel_units.show',
                    component: () => import('./fuel_efficiency/fuel_unit/FuelUnitDetail.vue'),
                },
            ],
        },
        {
            path: '/coordinated_fiscal_vouchers',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'coordinated_fiscal_vouchers.index',
                    component: () => import('./coordinated/coordinated_fiscal_voucher/CoordinatedFiscalVoucherIndex'),
                },
            ],
        },
        {
            path: '/coordinated_liquidations',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'coordinated_liquidations.index',
                    component: () => import('./coordinated/coordinated_liquidation/CoordinatedLiquidationIndex'),
                },
                {
                    path: ':id/edit',
                    name: 'coordinated_liquidations.edit',
                    component: () => import('./coordinated/coordinated_liquidation/CoordinatedLiquidationDetail'),
                },
                {
                    path: ':id',
                    name: 'coordinated_liquidations.show',
                    component: () => import('./coordinated/coordinated_liquidation/CoordinatedLiquidationDetail'),
                },
            ],
        },
        {
            path: '/coordinated_routes',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'coordinated_routes.index',
                    component: () => import('./coordinated/coordinated_route/CoordinatedRouteIndex'),
                },
                {
                    path: 'create',
                    name: 'coordinated_routes.create',
                    component: () => import('./coordinated/coordinated_route/CoordinatedRouteDetail'),
                },
                {
                    path: ':id/edit',
                    name: 'coordinated_routes.edit',
                    component: () => import('./coordinated/coordinated_route/CoordinatedRouteDetail'),
                },
                {
                    path: ':id',
                    name: 'coordinated_routes.show',
                    component: () => import('./coordinated/coordinated_route/CoordinatedRouteDetail'),
                },
            ],
        },
        {
            path: '/credit_notes',
            component: () => import('./billing/credit_note/CreditNote'),
            children: [
                {
                    path: ':id/edit',
                    name: 'credit_notes.edit',
                    component: () => import('./billing/credit_note/CreditNoteDetail'),
                },
                {
                    path: ':id',
                    name: 'credit_notes.show',
                    component: () => import('./billing/credit_note/CreditNoteDetail'),
                },
            ],
        },
        {
            path: '/customer_variants',
            component: () => import('./traffic/components/customer_variant/CustomerVariant'),
            children: [
                {
                    path: '/',
                    name: 'customer_variants.index',
                    component: () => import('./traffic/components/customer_variant/CustomerVariantIndex'),
                },
            ],
        },
        {
            path: '/issuers',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'issuers.index',
                    component: () => import('./issuer/IssuerIndex.vue'),
                },
                {
                    path: 'create',
                    name: 'issuers.create',
                    component: () => import('./issuer/IssuerDetail.vue'),
                },
                {
                    path: ':id',
                    name: 'issuers.show',
                    component: () => import('./issuer/IssuerDetail.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'issuers.edit',
                    component: () => import('./issuer/IssuerDetail.vue'),
                },
            ],
        },
        {
            path: '/freight_drivers',
            component: () => import('./traffic/components/driver/Driver'),
            children: [
                {
                    path: '/',
                    name: 'freight_drivers.index',
                    component: () => import('./traffic/components/driver/DriverIndex'),
                },
                {
                    path: 'create',
                    name: 'freight_drivers.create',
                    component: () => import('./traffic/components/driver/DriverDetail'),
                },
                {
                    path: ':id/edit',
                    name: 'freight_drivers.edit',
                    component: () => import('./traffic/components/driver/DriverDetail'),
                },
                {
                    path: ':id/',
                    name: 'freight_drivers.show',
                    component: () => import('./traffic/components/driver/DriverDetail'),
                },
            ],
        },
        {
            path: '/freight_templates',
            component: () => import('./freight_template/components/FreightTemplate.vue'),
            children: [
                {
                    path: '/',
                    name: 'freight_templates.index',
                    component: () => import('./freight_template/components/FreightTemplateIndex.vue'),
                },
                {
                    path: 'create',
                    name: 'freight_templates.create',
                    component: () => import('./freight_template/components/FreightTemplateForm.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'freight_templates.edit',
                    component: () => import('./freight_template/components/FreightTemplateForm.vue'),
                },
                {
                    path: ':id',
                    name: 'freight_templates.show',
                    component: () => import('./freight_template/components/FreightTemplateForm.vue'),
                },
            ],
        },
        {
            path: '/freight_trip_types',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'freight_trip_types.index',
                    component: () => import('./traffic/components/freight_trip_type/FreightTripTypeIndex.vue'),
                },
            ],
        },
        {
            path: '/invoicing_customer_groups/:customer_group_id/invoicing_customers',
            component: () => import('./billing/customer/Customer.vue'),
            children: [
                {
                    path: ':id/edit',
                    name: 'customer_groups.customers.edit',
                    component: () => import('./billing/customer/CustomerDetail.vue'),
                },
            ],
        },
        {
            path: '/queries/account_statement',
            name: 'queries.account_statement',
            component: () => import('./query/account_statement/AccountStatement'),
        },
        {
            path: '/queries/customer_account_statement',
            name: 'queries.customer_account_statement',
            component: () => import('./query/customer_account_statement/CustomerAccountStatement'),
        },
        {
            path: '/queries/invoice_balance_aging',
            name: 'queries.invoice_balance_aging',
            component: () => import('./query/invoice_balance_aging/InvoiceBalanceAging'),
        },
        {
            path: '/queries/daily_billing',
            name: 'queries.daily_billing',
            component: () => import('./query/daily_billing/DailyBilling'),
        },
        {
            path: '/queries/freight_trips_by_vehicle',
            name: 'queries.freight_trips_by_vehicle',
            component: () => import('./query/freight_trips_by_vehicle/FreightTripsByVehicle'),
        },
        {
            path: '/queries/freight_trips_per_invoice',
            name: 'queries.freight_trips_per_invoice',
            component: () => import('./query/trips_per_invoice/TripsPerInvoice'),
        },
        {
            path: '/queries/invoice_payments',
            name: 'queries.invoice_payments',
            component: () => import('./query/invoice_payments/InvoicePayments.vue'),
        },
        {
            path: '/queries/invoices_by_vehicle',
            name: 'queries.invoices_by_vehicle',
            component: () => import('./query/invoices_by_vehicle/InvoicesByVehicle'),
        },
        {
            path: '/queries/invoices_receivable',
            name: 'queries.invoices_receivable',
            component: () => import('./query/invoices_receivable/InvoicesReceivable'),
        },
        {
            path: '/freight_types',
            component: () => import('./freight_type/components/FreightType.vue'),
            children: [
                {
                    path: '/',
                    name: 'freight_types.index',
                    component: () => import('./freight_type/components/FreightTypeIndex.vue'),
                },
                {
                    path: 'create',
                    name: 'freight_types.create',
                    component: () => import('./freight_type/components/FreightTypeDetail'),
                },
                {
                    path: ':id/edit',
                    name: 'freight_types.edit',
                    component: () => import('./freight_type/components/FreightTypeDetail'),
                },
                {
                    path: ':freight_type_id/customer_variants/create',
                    name: 'freight_types.customer_variants.create',
                    component: () => import('./freight_type/components/FreightTypeCustomerVariantDetail'),
                },
                {
                    path: ':freight_type_id/customer_variants/:id/edit',
                    name: 'freight_types.customer_variants.edit',
                    component: () => import('./freight_type/components/FreightTypeCustomerVariantDetail'),
                },
            ],
        },
        {
            path: '/freight_movements',
            name: 'freight_movements',
            component: () => import('./freight_movement/components/FreightMovement.vue'),
        },
        {
            path: '/freights',
            component: () => import('./freight/components/Freight.vue'),
            children: [
                {
                    path: '/',
                    name: 'freights.index',
                    component: () => import('./freight/components/FreightIndex.vue'),
                },
                {
                    path: 'create',
                    name: 'freights.create',
                    component: () => import('./freight/components/FreightForm.vue'),
                },
                {
                    path: ':id/edit',
                    name: 'freights.edit',
                    component: () => import('./freight/components/FreightForm.vue'),
                    beforeEnter: (to, from, next) => {
                        if (UserAuthorization.can(Laravel.Ability.UPDATE, Laravel.Model.TrafficService)) {
                            next()
                        } else {
                            next(false)
                        }
                    },
                },
                {
                    path: ':id',
                    name: 'freights.show',
                    component: () => import('./freight/components/FreightForm.vue'),
                },
            ],
        },
        {
            path: '/invoices',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: ':id/freights',
                    name: 'invoices.freights.index',
                    component: () => import('./invoice/freight/FreightIndex'),
                },
            ],
        },
        {
            path: '/invoicing_vouchers',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: ':invoicing_voucher_id/fiscal_voucher_addendas/:id/edit',
                    name: 'invoicing_vouchers.fiscal_voucher_addendas.edit',
                    component: () => import('./fiscal_voucher/AddendaDetail'),
                },
            ],
        },
        {
            path: '/journal_accounts',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'journal_accounts.index',
                    component: () => import('./accounting/journal_account/JournalAccountIndex'),
                },
            ],
        },
        {
            path: '/journal_voucher_extracts',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'journal_voucher_extracts.index',
                    component: () => import('./accounting/journal_voucher_extract/JournalVoucherExtractIndex'),
                },
                {
                    path: 'create',
                    name: 'journal_voucher_extracts.create',
                    component: () => import('./accounting/journal_voucher_extract/JournalVoucherExtractDetail'),
                },
            ],
        },
        {
            path: '/load_tenders',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'load_tenders.index',
                    component: () => import('./traffic/components/load_tender/LoadTenderIndex.vue'),
                },
                {
                    path: ':id',
                    name: 'load_tenders.show',
                    component: () => import('./traffic/components/load_tender/LoadTenderDetail.vue'),
                },
                {
                    path: ':id/reject',
                    name: 'load_tenders.reject',
                    component: () => import('./traffic/components/load_tender/LoadTenderReject.vue'),
                },
            ],
        },
        {
            path: '/reports',
            name: 'reports',
            component: () => import('./report/components/Report.vue'),
        },
        {
            path: '/traffic_localities',
            component: () => import('./traffic/components/locality/Locality.vue'),
            children: [
                {
                    path: '/',
                    name: 'localities.index',
                    component: () => import('./traffic/components/locality/LocalityIndex.vue'),
                },
            ],
        },
        {
            path: '/freight_trailers',
            component: () => import('./traffic/components/trailer/Trailer'),
            children: [
                {
                    path: '/',
                    name: 'freight_trailers.index',
                    component: () => import('./traffic/components/trailer/TrailerIndex'),
                },
            ],
        },
        {
            path: '/service_request_files',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'service_request_files.index',
                    component: () => import('./traffic/components/service_request_file/ServiceRequestFileIndex.vue'),
                },
            ],
        },
        {
            path: '/trailer_owners',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: '/',
                    name: 'trailer_owners.index',
                    component: () => import('./traffic/components/trailer_owner/TrailerOwnerIndex'),
                },
                {
                    path: ':id',
                    name: 'trailer_owners.show',
                    component: () => import('./traffic/components/trailer_owner/TrailerOwnerDetail'),
                },
            ],
        },
        {
            path: '/vehicles',
            component: () => import('./traffic/components/vehicle/Vehicle'),
            children: [
                {
                    path: '/',
                    name: 'vehicles.index',
                    component: () => import('./traffic/components/vehicle/VehicleIndex'),
                },
                {
                    path: 'create',
                    name: 'vehicles.create',
                    component: () => import('./traffic/components/vehicle/VehicleDetail'),
                },
                {
                    path: 'without_vehicle_permit',
                    name: 'vehicles.without_vehicle_permit',
                    component: () => import('./dashboard/components/WithoutVehiclePermitList'),
                },
                {
                    path: 'without_vehicle_insurance',
                    name: 'vehicles.without_vehicle_insurance',
                    component: () => import('./dashboard/components/WithoutVehicleInsuranceList'),
                },
                {
                    path: ':id',
                    name: 'vehicles.show',
                    component: () => import('./traffic/components/vehicle/VehicleDetail'),
                },
                {
                    path: ':id/edit',
                    name: 'vehicles.edit',
                    component: () => import('./traffic/components/vehicle/VehicleDetail'),
                },
            ],
        },
        {
            path: '/operator_documents',
            component: () => import('./components/MainSection'),
            children: [
                {
                    path: 'to_expire',
                    name: 'operator_documents.to_expire',
                    component: () => import('./dashboard/components/OperatorDocument/OperatorDocumentList'),
                },
            ],
        },
        {
            path: '/vehicle_insurances',
            component: () => import('./traffic/components/vehicle_insurance/VehicleInsurance'),
            children: [
                {
                    path: '/',
                    name: 'vehicle_insurances.index',
                    component: () => import('./traffic/components/vehicle_insurance/VehicleInsuranceIndex'),
                },
                {
                    path: 'to_expire',
                    name: 'vehicle_insurances.to_expire',
                    component: () => import('./dashboard/components/VehicleInsuranceList'),
                },
            ],
        },
        {
            path: '/vehicle_permits',
            component: () => import('./traffic/components/vehicle_permit/VehiclePermit'),
            children: [
                {
                    path: '/',
                    name: 'vehicle_permits.index',
                    component: () => import('./traffic/components/vehicle_permit/VehiclePermitIndex'),
                },
                {
                    path: 'to_expire',
                    name: 'vehicle_permits.to_expire',
                    component: () => import('./dashboard/components/VehiclePermitList'),
                },
            ],
        },
        {
            path: '/voucher_folio_series',
            component: () => import('./billing/voucher_folio_series/VoucherFolioSeries'),
            children: [
                {
                    path: '/',
                    name: 'voucher_folio_series.index',
                    component: () => import('./billing/voucher_folio_series/VoucherFolioSeriesIndex'),
                },
            ],
        },
        {
            path: '*',
        },
    ],
}

export default new VueRouter(RouteSource)
