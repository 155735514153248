/**
 * Part of Genesis application
 *
 * @copyright 2025 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const fuelPrice = {
    async list(params) {
        const response = await axios.get('/api/fuel_prices', {params})

        return response.data.data
    },
    async show(fuel_price_id) {
        const response = await axios.get(`/api/fuel_prices/${fuel_price_id}`)

        return response.data.data
    },
    async store(params) {
        const response = await axios.post('/api/fuel_prices', params)

        return response.data
    },
    async update(fuelPrice) {
        await axios.patch(`/api/fuel_prices/${fuelPrice.id}`, fuelPrice)
    },
}

export default fuelPrice