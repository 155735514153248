/**
 * Part of Genesis application
 *
 * @copyright 2025 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import axios from 'axios'

export const fuelLoad = {
    async cancel(fuelLoad) {
        await axios.patch(`/api/fuel_loads/${fuelLoad.id}/cancel`, fuelLoad)
    },
    async list(params) {
        const response = await axios.get('/api/fuel_loads', {params})

        return response.data.data
    },
    async show(fuel_load_id) {
        const response = await axios.get(`/api/fuel_loads/${fuel_load_id}`)

        return response.data
    },
    async store(params) {
        const response = await axios.post('/api/fuel_loads', params)

        return response.data
    },
    async update(fuelLoad) {
        await axios.patch(`/api/fuel_loads/${fuelLoad.id}`, fuelLoad)
    },
}

export default fuelLoad