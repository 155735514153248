/**
 * Part of Genesis application
 *
 * @copyright 2024 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import fuelLoad from './fuelLoad'
import fuelPrice from './fuelPrice'
import fuelUnit from './fuelUnit'
import axios from 'axios'

export const FuelEfficiency = {
    fuelLoad,
    fuelPrice,
    fuelUnit,
    async dailyFuelConsumed(params) {
        const response = await axios.get('/api/fuel_efficiencies/daily_fuel_consumed', {params})

        return response.data.data
    },
    async fuelConsumed(params) {
        const response = await axios.get('/api/fuel_efficiencies/fuel_consumed', {params})

        return response.data.data
    },
    async list(params) {
        const response = await axios.get('/api/fuel_efficiencies', {params})

        return response.data.data
    },
}

export default FuelEfficiency