/**
 * Part of Genesis application
 *
 * @copyright 2022 Organizaciones Web S. de R.L. de C.V. All Rights Reserved
 *
 * @license Proprietary
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

export default {
    'agGridInterface': {
        'andCondition': 'Y',
        'applyFilter': 'Aplicar filtro...',
        'area': 'Zona',
        'areaChart': 'Zona',
        'areaGroup': 'Área',
        'autosizeAllColumns': 'Reajustar todo',
        'autosizeThiscolumn': 'Reajustar actual',
        'average': 'Promedio',
        'axis': 'Eje',
        'barChart': 'Barra',
        'barGroup': 'Bar',
        'bels': 'Etiquetas',
        'blanks': 'No indicado',
        'blur': 'Difuminar',
        'bold': 'Negrita',
        'boldItalic': 'Negrita cursiva',
        'bottom': 'Fondo',
        'bubble': 'Burbuja',
        'bubbleTooltip': 'Burbuja',
        'callout': 'Mensaje destacado',
        'categories': 'Categorías',
        'chart': 'Gráfico',
        'chartRange': 'Rango de gráfico',
        'clearFilter': 'Limpiar filtros',
        'collapseAll': 'Colapsar todo',
        'color': 'Color',
        'columnChart': 'Columna',
        'columnGroup': 'Columna',
        'columns': 'Columnas',
        'contains': 'Contiene',
        'copy': 'Copiar',
        'copyWithHeaders': 'Copiar con encabezados',
        'count': 'Contar',
        'csvExport': 'Exportar a CSV',
        'ctrlC': 'Ctrl C',
        'ctrlV': 'Ctrl V',
        'customRange': 'Rango personalizado',
        'data': 'Datos',
        'dateFormatOoo': 'Yyyy-mm-dd',
        'doughnut': 'Dona',
        'doughnutTooltip': 'Dona',
        'empty': 'Vacío',
        'endsWith': 'Termina con',
        'equals': 'Igual a',
        'excelExport': 'Exportar a Excel (.xlsx)',
        'excelXmlExport': 'Exportar a Excel (.xml)',
        'expandAll': 'Expandir todo',
        'export': 'Exportar',
        'fillOpacity': 'Opacidad de relleno',
        'filteredRows': 'Filtrados',
        'filterOoo': 'Filtrar...',
        'filters': 'Filtros',
        'first': 'Primero',
        'font': 'Fuente',
        'format': 'Formato',
        'greaterThan': 'Mayor que',
        'greaterThanOrEqual': 'Mayor que o igual',
        'group': 'Grupo',
        'groupBy': 'Agrupar por',
        'groupedAreaTooltip': 'Agrupados',
        'groupedBar': 'Agrupados',
        'groupedBarTooltip': 'Agrupados',
        'groupedColumn': 'Agrupados',
        'groupedColumnTooltip': 'Agrupados',
        'groups': 'Grupos',
        'inRange': 'En el rango',
        'italic': 'Itálica',
        'itemPaddingX': 'Artículo Relleno X',
        'itemPaddingY': 'Artículo Relleno Y',
        'last': 'Último',
        'left': 'Izquierda',
        'legend': 'Leyenda',
        'length': 'Longitud',
        'lessThan': 'Menor que',
        'lessThanOrEqual': 'Menor o igual',
        'line': 'Línea',
        'lineGroup': 'Línea',
        'lineTooltip': 'Línea',
        'lineWidth': 'Ancho de línea',
        'loadingOoo': 'Cargando...',
        'markerPadding': 'Relleno marcador',
        'markers': 'Marcadores',
        'markerSize': 'Tamaño de marcador',
        'markerStroke': 'Trazo de marcador',
        'max': 'Máximo',
        'min': 'Mínimo',
        'month': 'Mes',
        'more': 'Más',
        'next': 'Siguiente',
        'noDataToChart': 'No hay datos disponibles para ser graficados.',
        'noMatches': 'Sin coincidencias',
        'none': 'Ninguna',
        'noPin': 'No anclar <>',
        'normal': 'Normal',
        'normalizedArea': '100% apilado',
        'normalizedAreaTooltip': '100% apilado',
        'normalizedBar': '100% apilado',
        'normalizedBarTooltip': '100% apilado',
        'normalizedColumn': '100% apilado',
        'normalizedColumnTooltip': '100% apilado',
        'noRowsToShow': 'Sin filas',
        'notContains': 'No contiene',
        'notEqual': 'Diferente de',
        'of': 'De',
        'offset': 'Compensar',
        'offsets': 'Compensaciones',
        'orCondition': 'O',
        'padding': 'Relleno',
        'page': 'Página',
        'paste': 'Pegar',
        'pie': 'Pastel',
        'pieChart': 'Gráfica de pastel',
        'pieGroup': 'Pastel',
        'pieTooltip': 'Pastel',
        'pinColumn': 'Columna anclada',
        'pinLeft': 'Anclar <<',
        'pinRight': 'Anclar >>',
        'pivotChart': 'Gráfico dinámico',
        'pivotChartAndPivotMode': 'Gráfico de pivote y modo de pivote',
        'pivotChartRequiresPivotMode': 'La tabla dinámica requiere el modo dinámico activado',
        'pivotChartTitle': 'Gráfico dinámico',
        'pivotColumnsEmptyMessage': 'Arrastre aquí para pivotar',
        'pivotMode': 'Modo Pivote',
        'pivots': 'Pivotes',
        'position': 'Posición',
        'previous': 'Previo',
        'range': 'Rango',
        'rangeChartTitle': 'Gráfico de rango',
        'resetColumns': 'Restablecer columnas',
        'resetFilter': 'Restablecer filtro',
        'right': 'Derecho',
        'rowGroupColumns': 'Columnas pivote',
        'rowGroupColumnsEmptyMessage': 'Arrastrar columnas al grupo',
        'scatter': 'Dispersión',
        'scatterGroup': 'Dispersión',
        'scatterTooltip': 'Dispersión',
        'searchOoo': 'Buscar...',
        'selectAll': 'Seleccionar todo',
        'selectedRows': 'Seleccionados',
        'series': 'Serie',
        'settings': 'Configuraciones',
        'shadow': 'Sombra',
        'size': 'Tamaño',
        'stackedArea': 'Apilado',
        'stackedAreaTooltip': 'Apilado',
        'stackedBar': 'Apilado',
        'stackedBarTooltip': 'Apilado',
        'stackedColumn': 'Apilado',
        'stackedColumnTooltip': 'Apilado',
        'startsWith': 'Comienza con',
        'strokeOpacity': 'Opacidad de línea',
        'strokeWidth': 'Grosor del trazo',
        'sum': 'Suma',
        'thickness': 'Grosor',
        'ticks': 'Marcados',
        'title': 'Título',
        'to': 'A',
        'today': 'Hoy',
        'toolPanel': 'Panel de Herramientas',
        'toolPanelButton': 'Panel de herramientas',
        'tooltips': 'Información sobre herramientas',
        'top': 'Parte superior',
        'totalAndFilteredRows': 'Fila',
        'totalRows': 'Total de filas',
        'ungroupBy': 'Desagrupar por',
        'valueAggregation': 'Agregación de valor',
        'valueColumns': 'Valores de columna',
        'valueColumnsEmptyMessage': 'Arrastre columnas para agregar',
        'values': 'Valores',
        'week': 'Semana',
        'width': 'Ancho',
        'xOffset': 'Desplazamiento X',
        'xRotation': 'Rotación X',
        'xyChart': 'X Y (dispersión)',
        'yOffset': 'Desplazamiento Y',
        'yRotation': 'Rotación Y',
    },
    accounting: {
        journalVoucherExtract: {
            delete: {
                dialogMessage: '¿Estás seguro que deseas eliminar el extracto de pólizas {name}?',
                dialogTitle: 'Eliminar extracto de pólizas',
                failed: '¡Hubo un error al eliminar el extracto de pólizas!',
            },
            grid: {
                columns: {
                    actions: 'Acciones',
                    created_by: 'Creado por',
                    end_date: 'Hasta',
                    name: 'Nombre',
                    start_date: 'Desde',
                },
            },
            list: {
                error: '¡Hubo un error al obtener los extractos de pólizas!',
            },
            title: 'Extractos de pólizas',
        },
    },
    accountsReceivable: {
        payment: {
            applyFailed: '¡Hubo un error al aplicar el pago!',
            fields: {
                amount: {
                    label: 'Importe',
                },
                beneficiary_account_entity_tax_id: {
                    label: 'RFC cuenta banco beneficiario',
                },
                beneficiary_account_number: {
                    label: 'Cuenta beneficiario',
                },
                beneficiary_bank_account: {
                    label: 'Cuenta beneficiario',
                },
                currency_code: {
                    label: 'Moneda',
                },
                customer_id: {
                    label: 'Receptor',
                },
                customer_branch_id: {
                    label: 'Sucursal',
                },
                invoicing_payment_form_code: {
                    label: 'Forma de pago',
                },
                notes: {
                    label: 'Observaciones',
                },
                operation_number: {
                    label: 'Número de operación',
                },
                payer_account_entity_tax_id: {
                    label: 'RFC cuenta banco ordenante',
                },
                payer_account_number: {
                    label: 'Cuenta ordenante',
                },
                payer_bank_account: {
                    label: 'Cuenta ordenante',
                },
                payer_bank_name: {
                    label: 'Nombre banco cuenta ordenante',
                },
                payment_certificate: {
                    label: 'Certificado de pago',
                },
                payment_date: {
                    label: 'Fecha de pago',
                },
                payment_exchange_rate: {
                    label: 'Tipo de cambio',
                },
                payment_original_string: {
                    label: 'Cadena de pago',
                },
                payment_seal: {
                    label: 'Sello de pago',
                },
                payment_string_type_id: {
                    label: 'Tipo de cadena de pago',
                },
                voucher_folio_series_id: {
                    label: 'Serie de folios',
                },
            },
            grid: {
                actions: {
                    downloadPaymentLiquidationsPdf: 'Liquidaciones PDF',
                    downloadPaymentLiquidationsXlsx: 'Liquidaciones XLSX',
                    edit: 'Editar',
                    view: 'Mostrar',
                },
                columns: {
                    actions: 'Acciones',
                    amount: 'Importe',
                    cancel_date: 'Cancelado el',
                    cancelled_by: 'Cancelado por',
                    created_at: 'Creado el',
                    created_by: 'Creado por',
                    currency_code: 'Moneda',
                    customer: 'Razón social',
                    customer_branch: 'Sucursal',
                    customer_group: 'Cliente',
                    fiscal_voucher_cancellation_request_status: 'Estatus cancelación',
                    folio: 'Folio',
                    notification_sent: 'Notificación',
                    payment_date: 'Pagado el',
                    series: 'Serie',
                    stamp_date: 'Timbrado el',
                    stamped_by: 'Timbrado por',
                    status: 'Estatus pago',
                    tax_id: 'RFC',
                    voucher_status: 'Estatus CFDI',
                },
                tooltip: {
                    notification_sent: 'Notificación enviada',
                },
            },
            list: {
                error: '¡Hubo un error al obtener los pagos!',
            },
            nullify: {
                error: '¡Hubo un error al anular el pago!',
                messageWithoutVoucher: '¿Desea anular el pago con fecha de pago el {payment_date}?',
                messageWithVoucher: '¿Desea anular el pago con fecha de pago el {payment_date} y CFDI {series}-{folio}?',
                success: 'El pago se anuló exitosamente',
                title: 'Anular pago',
            },
            nullifyFailed: '¡Hubo un error al anular el pago!',
            paymentElectronicVoucher: {
                dialogTitle: 'Creando comprobante electrónico de pago',
                fields: {
                    customer_id: {
                        label: 'Razón social',
                    },
                    file: {
                        label: 'Archivo CEP',
                    },
                },
                storeFailed: '¡Hubo un error al guardar el comprobante electrónico de pago!',
                title: 'Crear comprobante electrónico de pago',
            },
            paymentDetails: 'Detalles de pago',
            paymentItem: {
                addFilesByCsv: {
                    fields: {
                        file: {
                            label: 'Archivo CSV',
                        },
                    },
                    title: 'Relacionar documentos',
                    uploadFile: 'Relacionar facturas desde un archivo CSV',
                    storeFailed: '¡Hubo un error al relacionar los documentos!',
                },
                destroyFailed: '¡Hubo un error al eliminar el documento!',
                downloadButton: 'Descargar',
                downloadFailed: '¡Hubo un error al descargar el archivo!',
                grid: {
                    actions: {
                        add: 'Agregar',
                    },
                    columns: {
                        actions: 'Acciones',
                        balance: 'Nuevo saldo',
                        currency_code: 'Moneda',
                        exchange_rate_equivalence: 'Tipo cambio',
                        folio: 'Folio',
                        installment_number: 'Parcialidad',
                        payment_amount: 'Monto pagado',
                        previous_balance: 'Saldo',
                        series: 'Serie',
                        stamp_date: 'Fecha timbrado',
                    },
                },
                listFailed: '¡Hubo un error al obtener los documentos del pago!',
                title: 'Documentos relacionados {documents} -  Monto pagado: ${amount_paid} - Monto disponible: ${amount_available}',
                updateFailed: '¡Hubo un error al actualizar los datos del documento!',
            },
            paymentLiquidationsFilename: 'Liquidaciones_{series}-{folio}.{format}',
            paymentStampModal: 'Datos de timbrado',
            pendingInvoices: {
                addInvoices: 'Agregar facturas ({quantity})',
                grid: {
                    actions: {
                        add: 'Agregar',
                    },
                    columns: {
                        actions: 'Acciones',
                        balance: 'Saldo pendiente',
                        currency_code: 'Moneda',
                        folio: 'Folio',
                        series: 'Serie',
                        stamp_date: 'Fecha timbrado',
                        total: 'Importe facturado',
                        total_paid: 'Importe abonado',
                    },
                },
                listFailed: '¡Hubo un error al obtener las facturas con balance disponible!',
                storeFailed: '¡Hubo un error al relacionar el documento!',
                storeManyFailed: '¡Hubo un error al relacionar los documentos!',
            },
            relatedPayment: {
                grid: {
                    columns: {
                        amount: 'Importe',
                        currency_code: 'Moneda',
                        folio: 'Folio',
                        series: 'Serie',
                        stamp_date: 'Fecha timbrado',
                    },
                },
            },
            reopenFailed: '¡Hubo un error al reabrir el pago!',
            saveFailed: '¡Hubo un error al guardar el pago!',
            selectCustomer: 'Debes seleccionar el receptor para mostrar las cuentas bancarias del ordenante',
            showFailed: '¡Hubo un error al obtener los datos del pago!',
            showReplacedPaymentFailed: '¡Hubo un error al obtener los datos del pago sustituido!',
            stampFailed: '¡Hubo un error al timbrar el pago!',
            stampSuccess: 'El pago se envió a timbrar exitosamente.',
            title: 'Pagos',
            titleCollapse: {
                create: 'Datos del pago',
                show: '{legal_name} ({tax_id}) - {fecha} - {payment_form} - {currency} - ${total}',
            },
        },
    },
    attachments: {
        show: {
            fails: '¡Hubo un error al descargar el archivo!',
        },
        delete: {
            fails: '¡Hubo un error al eliminar el archivo!',
        },
    },
    bankAccount: {
        create: 'Crear cuenta bancaria',
        delete: {
            dialogTitle: 'Eliminar cuenta bancaria',
            dialogMessage: '¿Estas seguro de eliminar la cuenta bancaria {name}?',
            success: 'La cuenta bancaria se eliminó exitosamente.',
            fails: '¡Hubo un error al eliminar la cuenta bancaria!',
        },
        form: {
            account_number: {
                label: 'Número de cuenta',
            },
            clabe: {
                label: 'CLABE',
            },
            complementary_journal_account: {
                label: 'Cuenta contable complementaria',
            },
            currency_code: {
                label: 'Moneda',
            },
            entity_name: {
                label: 'Nombre emisor',
            },
            entity_tax_id: {
                label: 'RFC emisor',
            },
            journal_account: {
                label: 'Cuenta contable',
            },
            name: {
                label: 'Nombre',
            },
        },
        grid: {
            columns: {
                account_number: 'Número de cuenta',
                clabe: 'CLABE',
                currency_code: 'Moneda',
                entity_name: 'Nombre emisor',
                name: 'Nombre',
            },
        },
        list: {
            error: '¡Hubo un error al obtener las cuentas bancarias!',
        },
        saveFailed: '¡Hubo un error al guardar la cuenta bancaria!',
        showFailed: '¡Hubo un error al obtener la cuenta bancaria!',
        title: 'Cuentas bancarias',
    },
    'billingDocument': {
        'title': 'Documentos de facturación',
        relatedFiscalVoucher: {
            failed: '¡Hubo un error al obtener los comprobantes fiscales relacionados!',
            grid: {
                columnHeaders: {
                    cancellation_date: {
                        value: 'Fecha cancelación',
                    },
                    cfdi_type_id: {
                        value: 'Tipo CFDI',
                    },
                    creation_date: {
                        value: 'Fecha emisión',
                    },
                    folio: {
                        value: 'Folio',
                    },
                    series: {
                        value: 'Serie',
                    },
                    uuid: {
                        value: 'UUID',
                    },
                    voucher_status: {
                        value: 'Estatus',
                    },
                },
            },
            modal: {
                title: 'Comprobantes fiscales relacionados',
            },
            title: 'CFDI relacionados',
        },
    },
    calendar: {
        today: 'Hoy',
        prevView: 'Navegar a vista anterior',
        nextView: 'Navegar a vista siguiente',
    },
    customerBankAccounts: {
        listFailed: '¡Hubo un error al obtener las cuentas bancarias del cliente!',
    },
    catalogFile: {
        importModal: {
            failed: '¡Hubo un error al importar los datos!',
            file: {
                label: 'Archivo',
            },
            file_type: {
                label: 'Cátalogo',
            },
            fileUnidentified: 'El archivo de {catalog_file_type} no se pudo identificar.',
            ready: 'El archivo fue {status}.',
            ready_with_url: '<p>El archivo fue {status}. <a target="_blank" href="{url}">Descargar</a></p>',
            success: 'El archivo se está procesando.',
            title: 'Importar catálogo',
        },
    },
    coordinated: {
        coordinatedLiquidation: {
            administrativeExpense: {
                add: 'Agregar gasto administrativo',
                grid: {
                    columns: {
                        amount: 'Monto',
                        description: 'Descripción',
                    },
                },
            },
            cancel: {
                dialogMessage: 'Cancelar cédula de liquidación {series}-{folio}.',
                dialogTitle: 'Cancelar cédula de liquidación',
                fails: '¡Hubo un error al cancelar la cédula de liquidación!',
            },
            coordinatedFiscalVoucher: {
                availableFailed: '¡Hubo un error al obtener las liquidaciones disponibles!',
                relatedFailed: '¡Hubo un error al obtener las liquidaciones de la cédula de liquidación!',
                grid: {
                    actions: {
                        add: 'Agregar',
                        delete: 'Eliminar',
                    },
                    columns: {
                        amount: 'Monto',
                        description: 'Descripción',
                    },
                },
            },
            create: 'Crear cédula de liquidación ({quantity})',
            form: {
                bank_account_id: {
                    label: 'Cuenta bancaria',
                },
                beneficiary_account_entity_tax_id: {
                    label: 'RFC cuenta banco beneficiario',
                },
                beneficiary_account_number: {
                    label: 'Cuenta beneficiario',
                },
                beneficiary_bank_name: {
                    label: 'Nombre banco beneficiario',
                },
                customer_id: {
                    label: 'Cliente',
                },
                folio: {
                    label: 'Folio',
                },
                liquidated_at: {
                    label: 'Fecha liquidación',
                },
                operation_number: {
                    label: 'Número de operación',
                },
                owner_id: {
                    label: 'Coordinado',
                },
                payment_form_code: {
                    label: 'Forma de pago',
                },
                series: {
                    label: 'Serie',
                },
                subtotal_administrative_expenses: {
                    label: 'Gastos administrativos',
                },
                subtotal_coordinated_fiscal_vouchers: {
                    label: 'Liquidaciones',
                },
                total: {
                    label: 'Total',
                },
            },
            grid: {
                actions: {
                    download_pdf: 'Descargar PDF',
                },
                columns: {
                    actions: 'Acciones',
                    base: 'Base',
                    coordinated_liquidation: 'Cédula',
                    customer_id: 'Razón social',
                    emitted_at: 'Fecha creación',
                    liquidated_at: 'Fecha liquidación',
                    owner_id: 'Coordinado',
                    status: 'Estatus',
                },
            },
            liquidatingModal: {
                liquidateFailed: '¡Hubo un error al liquidar la cédula de liquidación!',
                liquidateSuccess: 'Se liquidó la cédula {series}-{folio} con éxito',
                title: 'Liquidar cédula de liquidación {series}-{folio}',
            },
            liquidateButton: 'Liquidar',
            list: {
                error: '¡Hubo un error al obtener las cédulas de liquidación!',
            },
            previewPdfButton: 'Previsualizar PDF',
            previewPdfFailed: '¡Hubo un error al generar el PDF de la cédula de liquidación!',
            showFailed: '¡Hubo un error al obtener la cédula de liquidación!',
            storeFailed: '¡Hubo un error al guardar la cédula de liquidación!',
            tabs: {
                administrative_expenses: 'Gastos administrativos',
                available_coordinated_fiscal_vouchers: 'Liquidaciones disponibles',
                coordinated_fiscal_vouchers: 'Liquidaciones',
            },
            title: 'Cédulas de liquidación',
            updateFailed: '¡Hubo un error al actualizar la cédula de liquidación!',
        },
        coordinatedFiscalVoucher: {
            grid: {
                actions: {
                    quote: 'Cotizar',
                    regenerate: 'Regenerar',
                },
                columns: {
                    base: 'Base',
                    currency_code: 'Moneda',
                    customer_id: 'Razón social',
                    customer_tax_id: 'RFC Cliente',
                    exchange_rate: 'Tipo de cambio',
                    freight_id: 'Flete',
                    invoice: 'Factura',
                    liquidated_at: 'Fecha liquidación',
                    liquidation: 'Liquidación',
                    owner_id: 'Coordinado',
                    owner_tax_id: 'RFC Coordinado',
                    retained_tax_amount: 'IVA Retenido',
                    stamp_date: 'Fecha timbrado',
                    status: 'Estatus',
                    total: 'Total',
                    transferred_tax_amount: 'IVA Trasladado',
                },
            },
            list: {
                error: '¡Hubo un error al obtener las liquidaciones!',
            },
            quote: {
                dialogMessage: 'Cotizar liquidación {series}-{folio}.',
                dialogTitle: 'Cotizar liquidación',
                fails: '¡Hubo un error al cotizar la liquidación!',
            },
            regenerate: {
                dialogMessage: 'Regenerar liquidación {series}-{folio}.',
                dialogTitle: 'Regenerar liquidación',
                fails: '¡Hubo un error al regenerar la liquidación!',
                missing_coordinated_trip: '¡El flete ya no tiene movimiento de coordinados!',
                success: 'La liquidación se regeneró exitosamente.',
            },
            title: 'Liquidaciones',
        },
        coordinatedRoute: {
            form: {
                name: {
                    label: 'Nombre',
                },
                currency_code: {
                    label: 'Moneda',
                },
                amount: {
                    label: 'Importe',
                },
                origins: {
                    label: 'Origen',
                },
                destinations: {
                    label: 'Destino',
                },
            },
            grid: {
                columns: {
                    amount: 'Monto',
                    currency_code: 'Moneda',
                    name: 'Nombre',
                },
            },
            list: {
                error: '¡Hubo un error al obtener las rutas!',
            },
            saveFailed: '¡Hubo un error al guardar la ruta!',
            showFailed: '¡Hubo un error al obtener la ruta!',
            title: 'Rutas',
        },
        coordinatedTrip: {
            deleteFailed: '¡Hubo un error al eliminar el movimiento!',
            confirmDialog: {
                message: '¿Estás seguro que deseas {action} el movimiento {freight_id}-{order}?',
                title: `{action} movimiento`,
            },
            grid: {
                actions: {
                    delete: 'Eliminar',
                    liquidate: 'Liquidar',
                    revert: 'Revertir',
                },
                columns: {
                    completed_at: 'Completado',
                    destination_id: 'Destino',
                    origin_id: 'Origen',
                    status: 'Estatus',
                    trip_coordinate: 'Coordenada',
                    trip_type_id: 'Tipo',
                },
            },
            liquidateFailed: '¡Hubo un error al liquidar el movimiento!',
            list: {
                error: '¡Hubo un error al obtener los movimientos!',
            },
            revertFailed: '¡Hubo un error al revertir el movimiento!',
        },
    },
    'customerGroup': {
        'carrierId': 'Identificador de transportista',
        'carrierIdQualifier': 'Calificador de transportista',
        'confirmDelete': {
            'title': 'Eliminar cliente',
            'message': '¿Está seguro de que desea eliminar el cliente {name}?',
        },
        'connection': 'Conexión',
        'deleteCustomerGroupError': 'El cliente no se pudo eliminar.',
        'deleteCustomerGroupSuccess': 'El cliente se eliminó exitosamente.',
        'driver': 'Controlador',
        'ediConfiguration': 'Configuración EDI',
        'enable': 'Habilitar',
        'enableHelp': 'Es necesario guardar la configuración EDI para habilitar o deshabilitar.',
        'fileSchema': 'Esquema de archivos',
        'generalInformation': 'Datos Generales',
        'inboundDirectory': 'Directorio recepción',
        'label': 'Cliente',
        'name': 'Nombre',
        'outboundDirectory': 'Directorio envío',
        'password': 'Contraseña',
        'port': 'Puerto',
        'server': 'Servidor',
        'shipperId': 'Identificador de cliente',
        'shipperIdQualifier': 'Calificador de cliente',
        'testMode': 'Activar modo de prueba',
        'testModeWarning': 'La configuración EDI esta en modo de prueba.',
        'updateError': 'El cliente no se pudo actualizar.',
        'updateSuccess': 'El cliente se actualizó exitosamente.',
        'username': 'Usuario',
        'version': 'Versión',
    },
    'customerVariant': {
        'title': 'Cotizaciones',
        'delete': {
            'title': 'Eliminar cotización',
            'message': '¿Estas seguro que deseas eliminar la cotización del cliente "{customer}" código "{code}"?',
            'success': 'La cotización se eliminó exitosamente.',
            'error': '¡No se pudo eliminar la cotización!',
        },
        'grid': {
            'actions': 'Acciones',
            'billing_currency_code': 'Moneda facturación',
            'code': 'Código',
            'currency_code': 'Moneda cotización',
            'customer_group_id': 'Cliente',
            'exchange_rate': 'Tipo de cambio',
            'price': 'Precio',
            'tax_set_id': 'Impuestos',
            'is_active': 'Estatus',
        },
    },
    'createLadingBillLabel': 'Traslado',
    'createLadingBillTitle': 'Relacionar CFDI de traslado',
    'creditNote': {
        'alreadyNullified': 'La nota de crédito ya esta anulada.',
        'applyFailed': '¡Hubo un error al aplicar la nota de crédito!',
        'availableInvoicingVouchers': {
            'grid': {
                'columnHeaders': {
                    'balance': {
                        'tooltip': 'Balance',
                        'value': 'Balance',
                    },
                    'currency_code': {
                        'tooltip': 'Moneda',
                        'value': 'Moneda',
                    },
                    'id': {
                        'tooltip': 'Selecciona',
                        'value': '',
                    },
                    'invoice': {
                        'tooltip': 'Factura',
                        'value': 'Factura',
                    },
                    'legal_name': {
                        'tooltip': 'Razón social',
                        'value': 'Razón social',
                    },
                    'stamp_date': {
                        'tooltip': 'Fecha de timbrado',
                        'value': 'Fecha de timbrado',
                    },
                    'tax_id': {
                        'tooltip': 'RFC',
                        'value': 'RFC',
                    },
                    'total': {
                        'tooltip': 'Total',
                        'value': 'Total',
                    },
                },
            },
        },
        'cancelFailed': '¡Hubo un error al cancelar la nota de crédito!',
        'fields': {
            'cfdi_use_code': {
                'label': 'Uso de CFDI',
            },
            'currency_code': {
                'label': 'Moneda',
            },
            'customer_branch_id': {
                'label': 'Sucursal',
            },
            'description': {
                'label': 'Descripción',
                'validationError': 'La descripción es obligatoria.',
            },
            'exchange_rate': {
                'label': 'Tipo de cambio',
                'validationError': 'El tipo de cambio es obligatorio cuando la moneda es USD.',
            },
            'measurement_unit_code': {
                'label': 'Clave unidad de medida',
            },
            'notes': {
                'label': 'Observaciones',
            },
            'payment_conditions': {
                'label': 'Condiciones de pago',
            },
            'payment_form_code': {
                'label': 'Forma de pago',
            },
            'payment_method_code': {
                'label': 'Método de pago',
            },
            'product_service_code': {
                'label': 'Clave producto/servicio',
            },
            'quantity': {
                'label': 'Cantidad',
            },
            'retained_tax_amount': {
                'label': 'Impuesto retenido',
            },
            'series': {
                'label': 'Serie',
            },
            'subtotal': {
                'label': 'Subtotal',
            },
            'total': {
                'label': 'Total nota de crédito',
            },
            'transferred_tax_amount': {
                'label': 'Impuesto trasladado',
            },
        },
        'creditNoteItem': {
            'title': 'Concepto',
        },
        'getFailed': '¡No se pudo obtener la nota de crédito!',
        'invoicingVouchersFailed': '¡Hubo un error al obtener las facturas!',
        'invoicingVouchers': {
            'grid': {
                'columnHeaders': {
                    'actions': {
                        'tooltip': 'Acciones',
                        'value': 'Acciones',
                    },
                    'invoice': {
                        'tooltip': 'Factura',
                        'value': 'Factura',
                    },
                    'subtotal': {
                        'tooltip': 'Subtotal',
                        'value': 'Subtotal (requerido)',
                    },
                    'subtotal_available': {
                        'tooltip': 'Balance disponible',
                        'value': 'Balance disponible',
                    },
                    'total': {
                        'tooltip': 'Total',
                        'value': 'Total',
                    },
                    'transferred_tax_amount': {
                        'tooltip': 'Impuesto trasladado',
                        'value': 'Impuesto trasladado',
                    },
                    'retained_tax_amount': {
                        'tooltip': 'Impuesto retenido',
                        'value': 'Impuesto retenido',
                    },
                },
            },
        },
        'nullify': {
            'message': '¿Desea anular la nota de crédito {series}-{folio}?',
            'title': 'Anular nota de crédito',
        },
        'nullifyFailed': '¡Hubo un error al anular la nota de crédito!',
        'recalculateDocumentsFailed': '¡Hubo un error al calcular el impuesto de los documentos!',
        'relateDocuments': 'Relacionar documentos',
        'removeDocuments': {
            'title': 'Eliminar factura',
            'message': '¿Desea eliminar la factura {series}-{folio}?',
        },
        'relateDocumentsFailed': '¡Hubo un error al relacionar facturas a la nota de crédito!',
        'removeDocumentsFailed': '¡Hubo un error al eliminar el documento!',
        'saveFailed': '¡Hubo un error al actualizar la nota de crédito!',
        'stampFailed': '¡Hubo un error al timbrar la nota de crédito!',
        'title': 'Notas de crédito',
    },
    'customer': {
        'customerGroup': 'Cliente',
        'fields': {
            'cfdi_use_code': {
                'label': 'Uso de CFDI',
            },
            'country_code': {
                'label': 'País',
            },
            'credit_days': {
                'label': 'Días de crédito',
                'validationError': 'Días de crédito debe ser un número entero entre 0 y 360',
            },
            'email': {
                'label': 'Correo electrónico',
                'validationError': 'El correo electrónico no corresponde con una dirección de e-mail válida',
            },
            'fiscal_regime_code': {
                'label': 'Régimen fiscal',
            },
            'foreign_tax_id': {
                'label': 'Núm. Reg. Id. Trib.',
                'validationError': 'El Núm. Reg. Id. Trib. debe contener entre 1 y 40 caracteres cuando el país no es MEX',
            },
            'journal_account': {
                'label': 'Cuenta contable',
                'validationError': 'La cuenta contable es obligatoria.',
            },
            'legal_name': {
                'label': 'Razón social',
                'validationError': 'La razón social debe contener entre 1 y 255 caracteres',
            },
            'postal_code': {
                'label': 'Código postal',
                'validationError': 'El código postal debe ser un número entero de 5 posiciones.',
            },
            'primary_branch_id': {
                'label': 'Sucursal principal',
            },
            'schema_version': {
                'label': 'Versión del CFDI',
            },
            'tax_id': {
                'label': 'RFC',
                'validationError': 'La RFC es obligatorio',
            },
        },
        'getFailed': '¡No se pudo obtener la razón social!',
        'label': 'Razón social',
        'saveFailed': '¡Hubo un error al actualizar la razón social!',
    },
    'dailyBilling': {
        'groupBy': 'Agrupar',
        'grid': {
            'columnHeaders': {
                'antiquity_code': {
                    'value': 'Antigüedad',
                    'tooltip': 'Antigüedad',
                },
                'antiquity_days': {
                    'value': 'Días',
                    'tooltip': 'Días de antigüedad',
                },
                'antiquity_status': {
                    'value': 'Estatus vencimiento',
                    'tooltip': 'Estatus de vencimiento',
                },
                'business_segment': {
                    'tooltip': 'Segmento de negocio',
                    'value': 'Segmento',
                },
                'code': {
                    'tooltip': 'Código',
                    'value': 'Código',
                },
                'currency': {
                    'tooltip': 'Moneda factura',
                    'value': 'Moneda fact',
                },
                'customer_branch': {
                    'tooltip': 'Sucursal',
                    'value': 'Sucursal',
                },
                'customer_group': {
                    'tooltip': 'Cliente',
                    'value': 'Cliente',
                },
                'description': {
                    'tooltip': 'Descripción',
                    'value': 'Descripción',
                },
                'discount': {
                    'tooltip': 'Descuento',
                    'value': 'Descuento',
                },
                'due_date': {
                    'value': 'Fecha vencimiento',
                    'tooltip': 'Fecha de vencimiento',
                },
                'credit_days': {
                    'value': 'Plazo',
                    'tooltip': 'Días de crédito',
                },
                'exchange_rate': {
                    'tooltip': 'Tipo de cambio factura',
                    'value': 'TC fact',
                },
                'freight': {
                    'tooltip': '# Flete',
                    'value': '# Flete',
                },
                'freight_status': {
                    'tooltip': 'Estatus de flete',
                    'value': 'Estatus flete',
                },
                'freight_type': {
                    'tooltip': 'Tipo de flete',
                    'value': 'Tipo flete',
                },
                'income_type': {
                    'tooltip': 'Clasificación',
                    'value': 'Clasificación',
                },
                'invoice': {
                    'tooltip': 'Factura',
                    'value': 'Factura',
                },
                'invoice_status': {
                    'tooltip': 'Estatus factura',
                    'value': 'Estatus fact',
                },
                'item_balance': {
                    'tooltip': 'Saldo',
                    'value': 'Saldo',
                },
                'item_paid': {
                    'tooltip': 'Pagado',
                    'value': 'Pagado',
                },
                'iva_08': {
                    'tooltip': 'IVA 8%',
                    'value': 'IVA 8%',
                },
                'iva_16': {
                    'tooltip': 'IVA 16%',
                    'value': 'IVA 16%',
                },
                'payment': {
                    'tooltip': 'REP',
                    'value': 'REP',
                },
                'payment_amount': {
                    'tooltip': 'Monto pago',
                    'value': 'Monto pago',
                },
                'payment_currency': {
                    'tooltip': 'Moneda pago',
                    'value': 'Moneda pago',
                },
                'payment_date': {
                    'tooltip': 'Fecha pago',
                    'value': 'Fecha pago',
                },
                'payment_exchange_rate': {
                    'tooltip': 'Tipo de cambio pago',
                    'value': 'TC pago',
                },
                'period': {
                    'tooltip': 'Periodo semana',
                    'value': 'Periodo',
                },
                'reference': {
                    'tooltip': 'Referencia',
                    'value': 'Referencia',
                },
                'remarks': {
                    'tooltip': 'Observaciones',
                    'value': 'Observaciones',
                },
                'ret_04': {
                    'tooltip': 'Ret 4%',
                    'value': 'Ret 4%',
                },
                'stamp_date': {
                    'tooltip': 'Fecha factura',
                    'value': 'Fecha fac',
                },
                'subtotal': {
                    'tooltip': 'Subtotal',
                    'value': 'Subtotal',
                },
                'total': {
                    'tooltip': 'Total',
                    'value': 'Total',
                },
                'uuid': {
                    'tooltip': 'UUID',
                    'value': 'UUID',
                },
            },
            'groupCellRenderer': {
                'totalInvoiceMxn': 'Total MXN',
                'totalInvoiceUsd': 'Total USD',
            },
        },
        'title': 'Consultas - Cobranza diaria',
    },
    'dashboard': {
        'activeVehicles': '{quantity} vehículos activos (GPS)',
        'Create invoice': 'Crear factura',
        'Create payment': 'Crear pago',
        'Exchange rate of the day': 'Tipo de cambio del día',
        'freights': {
            'title': 'Fletes',
            'active': 'activos',
            'completed': 'completos hoy',
            'created': 'pendientes',
        },
        'Generate extract': 'Generar extracto',
        'Home': 'Inicio',
        'main navigation': 'navegación principal',
        'maintenanceVehicles': '{quantity} vehículos en mantenimiento (GPS)',
        'Name': 'Nombre',
        operatorDocument: {
            title: 'Documentos de conductor',
            to_expire: '{count} documentos de conductor por expirar',
        },
        operatorDocumentList: {
            grid: {
                document_number: 'Número',
                driver_name: 'Conductor',
                operator_type: 'Tipo de documento',
                valid_from: 'Válido desde',
                valid_to: 'Válido hasta',
            },
            title: 'Conductores con documentos por expirar',
            to_expire: '{count} documentos de conductor por expirar',
        },
        'Release notes': 'Notas de liberación',
        'Shortcuts': 'Atajos',
        'statistics': {
            'cancelled': 'Cancelado',
            'completed': 'Completo',
            'false': 'Falso',
            'full_inspection_mex': 'Rojo',
            'not_completed': 'No completo',
            'previous_days': 'Días anteriores',
            'quantity': 'Cantidad',
            'refresh': 'Actualizar',
            'shown': 'Total',
            'title': 'Movimientos de fletes',
            'today': 'Hoy',
            'type': 'Tipo',
        },
        'vehicles': {
            'active': 'activos',
            'list': 'Ver listado',
            'maintenance': 'en mantenimiento',
            'map': 'Ver en mapa',
            'title': 'Vehículos',
        },
        'vehicleInsuranceList': {
            'title': 'Vehículos con seguro vehicular por expirar en los próximos 30 días',
            'redirectTooltip': 'Ver detalle de seguro vehicular',
            'grid': {
                'company': 'Compañía',
                'endDate': 'Fecha término',
                'policyNumber': 'Núm. póliza',
                'type': 'Tipo póliza',
                'vehicles': 'Vehículos',
                'vehicle_name': 'Vehículo',
                'vehicle_model': 'Modelo',
                'vehicle_license_plate': 'Placa',
                'vehicle_type_code': 'Tipo',
            },
        },
        'vehicleInsurances': {
            'title': 'Seguros vehiculares',
            'withoutPolicy': 'sin póliza vigente',
            'vehicles': '{count} vehículos',
            'toExpirePolicies': '{count} vehículos con póliza por expirar',
        },
        'vehiclePermitList': {
            'title': 'Vehículos con permisos SCT por expirar en los próximos 30 días',
            'grid': {
                'end_date': 'Fecha término',
                'start_date': 'Fecha inicio',
                'number': 'Número',
                'type': 'Tipo',
                'vehicles': 'Vehículos',
                vehicle_name: 'Vehículo',
                vehicle_model: 'Modelo',
                vehicle_license_plate: 'Placa',
                vehicle_type_code: 'Tipo',
            },
        },
        'vehiclePermits': {
            'title': 'Permisos SCT',
            'withoutPermit': 'sin permiso vigente',
            'vehicles': '{count} vehículos',
            'toExpirePermits': '{count} vehículos con permiso por expirar',
        },
        'vehiclesMap': {
            'title': '{count} vehículos en movimiento activo',
            'freightTrip': 'Movimiento',
            'customer': 'Cliente',
            'origin': 'Origen',
            'destination': 'Destino',
            'timeAgo': 'Hace {time}',
        },
        'withoutVehicleInsuranceList': {
            'title': 'Vehículos sin seguro vehicular',
            'grid': {
                'name': 'Nombre',
                'vehicleType': 'Tipo',
                'brand': 'Marca',
                'modelYear': 'Modelo',
                'licensePlate': 'Placa',
                'serie': 'No. de serie',
            },
        },
        'withoutVehiclePermitList': {
            'title': 'Vehículos sin permiso SCT',
            'grid': {
                'name': 'Nombre',
                'vehicleType': 'Tipo',
                'brand': 'Marca',
                'modelYear': 'Modelo',
                'licensePlate': 'Placa',
                'serie': 'No. de serie',
            },
        },
    },
    'dateFilter': {
        'date': 'Fecha',
        'from': 'Del',
        'to': 'Al',
    },
    datepicker: {
        toggleCalendar: 'Abrir calendario',
    },
    'datePicker': {
        'from': 'Del',
        'to': 'Al',
    },
    datetimepicker: {
        date: 'Fecha',
        time: 'Hora',
    },
    'debitNote': {
        'title': 'Notas de débito',
        'nullify': {
            'title': 'Anular nota de débito',
            'message': '¿Desea anular la nota de débito {series}-{folio}?',
        },
    },
    'departurePass': {
        download: 'Descargar',
        fields: {
            'seal': {
                'label': 'Sellos',
                'validationError': 'El sello debe contener 255 caracteres como máximo.',
            },
            'type': {
                'label': 'Tipo',
            },
        },
        generate: 'Generar',
        getFreightSealsFailed: '¡Hubo un error al obtener los sellos!',
        getFreightTripFailed: '¡Hubo un error al obtener el movimiento!',
        store: {
            'failed': '¡Hubo un error al guardar el pase de salida!',
            'success': 'El pase de salida se está generando, espere el resultado.',
        },
        title: 'Pase de salida',
        titleWithSeals: 'Se va a generar el pase de salida con los siguientes sellos: ',
        titleWithoutSeals: 'Se va a generar el pase de salida sin sellos',
    },
    'destination': {
        'label': 'Destino',
    },
    'driver': {
        'confirmDelete': {
            'failed': '¡No se pudo eliminar el conductor!',
            'message': '¿Estás seguro que deseas eliminar el conductor {name}?',
            'notFound': 'No se encontró el conductor {name}',
            'success': 'El conductor se eliminó exitosamente.',
            'title': 'Eliminar conductor',
        },
        downloadTemplate: {
            failed: '¡Hubo un error al descargar la plantilla de operadores!',
            title: 'Descargar plantilla de operadores',
        },
        'driverDetail': {
            'getDriverFailed': '¡No se pudo cargar el conductor!',
            listFailed: '¡No se pudieron obtener los datos del conductor!',
            operatorDialog: {
                message: 'Ya existe un operador con el mismo nombre ({employee_code} - {tax_id}), ¿Deseas continuar?',
                title: 'El operador ya existe',
            },
            'saveFailed': '¡No se pudo guardar el conductor!',
            'saveSuccess': '¡Se guardó exitosamente el conductor!',
            'title': 'Crear conductor',
            tabs: {
                driver_form: 'Información',
                operator_documents: 'Documentos',
            },
        },
        'driverForm': {
            'getPostalCodeFailed': 'Error al validar el código postal.',
        },
        'fields': {
            'driver_type_id': {
                'label': 'Tipo',
                'validationError': 'El tipo es obligatorio.',
            },
            'employee_code': {
                'label': 'Número de empleado',
                'validationError': 'El número de empleado debe contener entre 3 y 10 caracteres.',
            },
            'foreign_postal_code': {
                'label': 'Código postal',
                'validationError': 'El código postal debe coincidir con el patrón requerido por el SAT.',
            },
            'foreign_tax_id': {
                'label': 'Núm. Reg. Trib.',
                'validationError': 'El núm. reg. trib. debe coincidir con el patrón requerido por el SAT.',
            },
            'last_name': {
                'label': 'Apellido(s)',
                'validationError': 'El apellido(s) debe contener entre 1 y 50 caracteres.',
            },
            'license_number': {
                'label': 'Número de licencia de conducir',
                'validationError': 'El número de licencia de conducir debe contener entre 6 y 16 caracteres.',
            },
            'name': {
                'label': 'Nombre(s)',
                'validationError': 'El nombre(s) debe contener entre 1 y 50 caracteres.',
            },
            'postal_code': {
                'label': 'Código postal',
                'validationError': 'El código postal debe contener 5 dígitos.',
            },
            'tax_id': {
                'label': 'RFC',
                'validationError': 'El RFC debe coincidir con el patrón requerido por el SAT de personas físicas.',
            },
        },
        'grid': {
            'actions': 'Acciones',
            'driver_type_id': 'Tipo',
            'employee_code': 'Núm. empleado',
            'full_name': 'Nombre completo',
            'country': 'País',
            'geo_state': 'Estado',
            'postal_code': 'CP',
            'street': 'Calle',
            'license_number': 'Licencia',
            'tax_id': 'RFC',
            'is_active': 'Estatus',
        },
        importModal: {
            failed: '¡Hubo un error al importar los operadores!',
            file: {
                label: 'Archivo',
            },
            fileUnidentified: 'El archivo de Operadores no se pudo identificar.',
            ready: 'El archivo fue {status}.',
            ready_with_url: '<p>El archivo fue {status}. <a target="_blank" href="{url}">Descargar</a></p>',
            success: 'El archivo de operadores se está procesando.',
            title: 'Importar operadores',
        },
        operatorDocument: {
            create: {
                title: 'Crear documento',
            },
            edit: {
                title: 'Editar documento',
            },
            deleteDialogMessage: '¿Estás seguro que deseas eliminar el documento {document_type}-{document_number}?',
            deleteDialogTitle: 'Eliminar documento',
            deleteFailed: '¡Hubo un error al eliminar el documento!',
            grid: {
                actions: 'Acciones',
                document_number: 'Número',
                document_type_id: 'Tipo',
                valid_from: 'Valido desde',
                valid_to: 'Valido hasta',
            },
            fields: {
                document_type_id: {
                    label: 'Tipo de documento',
                },
                document_number: {
                    label: 'Número de documento',
                },
                valid_from: {
                    label: 'Válido desde',
                },
                valid_to: {
                    label: 'Válido hasta',
                },
            },
            saveFailed: '¡No se pudo guardar el documento!',
            saveSuccess: '¡Se guardó exitosamente el documento!',
        },
        'subtitles': {
            'address': 'Domicilio',
            'documents': 'Documentos',
            'generalInformation': 'Información General',
        },
        'title': 'Conductores',
        'updateDriver': {
            'failure': '¡No se pudo actualizar el conductor!',
        },
    },
    'dropdowns': {
        'nodata': 'No se encontraron resultados',
    },
    'ediConfiguration': {
        'confirmDelete': {
            'title': 'Eliminar Configuración EDI',
            'message': '¿Está seguro que desea eliminar la configuración EDI?',
        },
        'saveSuccess': 'La configuración EDI se guardó exitosamente.',
        'saveFailed': '¡No se pudo guardar la configuración EDI!',
        'deleteSuccess': 'La configuración EDI se eliminó exitosamente.',
        'deleteFailed': '¡No se pudo eliminar la configuración EDI!',
    },
    email: {
        message: {
            label: 'Mensaje',
        },
        sent_to: {
            label: 'Enviar a',
        },
        subject: {
            label: 'Asunto',
        },
    },
    'enums': {
        'serviceRequestFileLocationType': {
            '1': 'Carga',
            '2': 'Descarga',
            '3': 'Carga/Descarga',
        },
    },
    'exchangeRate': {
        'getFailed': '¡Hubo un error al obtener el tipo de cambio!',
    },
    'externalNotification': {
        'create': 'Alertar',
        'message': 'El Centro de Alertamiento Empresarial será contactado para proveer asistencia',
        'panicButton': 'Pánico',
        'store': {
            'failed': '¡Hubo un error al enviar la notificación!',
            'success': 'La notificación fue enviada exitosamente.',
        },
        'testButton': 'Prueba',
        'title': 'Enviar notificación',
        'vehicle': {
            'label': 'Vehículo',
        },
    },
    'feedbackModal': {
        'errors': {
            'sendFailed': '¡No se pudo enviar tu comentario!',
        },
        'labelTechnicalSupport': '¿En qué te podemos ayudar?',
        'success': 'El comentario fue enviado con éxito.',
        'title': 'Título',
        'titleModal': 'Comentarios',
    },
    'fiscalVoucher': {
        'addenda': {
            'GrupoAPMM': {
                'title': 'Addenda Grupo APMM',
                'providerNumber': {
                    'label': 'Número de proveedor',
                    'validationMessage': 'Valor requerido de 7 hasta 10 caracteres numéricos.',
                },
                'reference': {
                    'label': 'Referencia',
                    'validationMessage': 'Valor requerido de 8 hasta 12 caracteres alfanuméricos.',
                },
                'referenceUUID': {
                    'label': 'Referencia UUID',
                    'validationMessage': 'Valor requerido debe ser PRIMERA o un UUID válido.',
                },
                'trailer': {
                    'label': 'Número de caja',
                    'validationMessage': 'Valor requerido de hasta 20 caracteres alfanuméricos.',
                },
            },
            'KNRECEPCION': {
                'title': 'Addenda KNRECEPCION',
                'branchCentre': {
                    'label': 'Código de sucursal',
                    'validationMessage': 'Valor requerido de hasta 7 caracteres alfanuméricos.',
                },
                'fileNumberGL': {
                    'label': 'Número de expediente',
                    'validationMessage': 'Valor opcional de hasta 16 caracteres alfanuméricos.',
                },
                'purchaseOrder': {
                    'label': 'Orden de compra',
                    'validationMessage': 'Valor opcional de hasta 14 caracteres alfanuméricos.',
                },
                'transportRef': {
                    'label': 'Referencia de transporte',
                    'validationMessage': 'Valor opcional de hasta 7 caracteres alfanuméricos.',
                },
            },
            ReferenciaExpeditors: {
                title: 'Addenda ReferenciaExpeditors',
                referenciaExpeditors: {
                    label: 'Referencia expeditors',
                    validationMessage: 'Valor requerido de hasta 100 caracteres alfanuméricos.',
                },
            },
            'Sanmina': {
                'title': 'Addenda Sanmina',
                'customerCode': {
                    'label': 'Código de cliente',
                    'validationMessage': 'Valor requerido de hasta 100 caracteres alfanuméricos.',
                },
                'email': {
                    'label': 'Correo electrónico',
                    'validationMessage': 'Valor requerido de hasta 100 caracteres alfanuméricos.',
                },
                'purchaseOrderNumber': {
                    'label': 'Orden de compra',
                    'validationMessage': 'Valor opcional de hasta 100 caracteres alfanuméricos.',
                },
            },
            'taxIdNotAllowEditAddenda': 'RFC no permite editar addendas.',
        },
        'error': {
            'title': 'Error de Timbrado',
            'alreadyHandled': 'El comprobante no se puede modificar, el error de timbrado ya se ha procesado',
            'fetchFailed': '¡No se pudo obtener el último error de timbrado!',
        },
        'notify': {
            'error': '¡Falló el envío de la notificación al cliente!',
        },
    },
    'fiscalVoucherCancellationRequest': {
        'grid': {
            'columnHeaders': {
                'cancellation_event_type': {
                    'value': 'Tipo evento',
                    'tooltip': 'Tipo de evento',
                },
                'from_status': {
                    'value': 'Estatus inicial',
                    'tooltip': 'Estatus inicial',
                },
                'occurred_at': {
                    'value': 'Fecha evento',
                    'tooltip': 'Fecha de evento',
                },
                'params': {
                    'value': 'Parámetros',
                    'tooltip': 'Parámetros',
                },
                'result': {
                    'value': 'Resultado',
                    'tooltip': 'Resultado',
                },
                'to_status': {
                    'value': 'Estatus final',
                    'tooltip': 'Estatus final',
                },
            },
            'groupCellRenderer': {
                'cancel_date': 'Cancelación',
                'cancellation_reason_code': 'Motivo',
                'created_at': 'Creado',
                'query_at': 'Próxima consulta',
                'replacement_voucher': 'Sustituto',
                'requested_at': 'Solicitado',
                'requested_by_user': 'Solicitada por',
                'status': 'Estatus',
            },
        },
        'modal': {
            'title': 'Solicitudes de cancelación CFDI {fiscal_voucher}',
        },
        'listFails': '¡Falló al obtener las solicitudes de cancelación del CFDI!',
    },
    'fiscal_vouchers': {
        'status': {
            'C': 'Cancelado',
            'E': 'Error de timbrado',
            'L': 'Bloqueado',
            'N': 'Anulado',
            'O': 'Abierto',
            'P': 'Cancelación pendiente',
            'S': 'Firmado',
            'T': 'Timbrado',
            'unknown': 'Desconocido',
            'withoutStamp': 'Sin timbrar',
        },
        'types': {
            'E': 'Egreso',
            'I': 'Ingreso',
            'T': 'Traslado',
        },
    },
    'freight': {
        'cancelFreightTripConfirmation': {
            'message': '¿Deseas cancelar el movimiento?',
            'title': 'Cancelar movimiento',
            'requiredComments': 'Agregar comentarios',
            'error': 'No se pudo cancelar el movimiento.',
        },
        carrierShipmentStatus: {
            grid: {
                columns: {
                    bill_of_lading: 'Referencia',
                    code: 'Tipo',
                    occurred_at: 'Fecha',
                    sequence: '# Parada',
                    status: 'Estatus',
                },
            },
            listFailed: '¡Hubo un error al obtener los datos de las actualizaciones de ofertas de carga!',
            title: 'Actualizaciones de ofertas de carga',
        },
        'created_at': {
            'label': 'Fecha',
        },
        'freightCommentGrid': {
            'comment': 'Comentario',
            'date': 'Fecha',
            'reference': 'Referencia',
            'user': 'Usuario',
        },
        'freightDetail': {
            attachments: {
                delete: {
                    title: 'Eliminar documento',
                    success: 'Se eliminó el documento {filename} con éxito',
                    message: '¿Estas seguro que deseas eliminar el documento {filename}?',
                },
                grid: {
                    actions: 'Acciones',
                    filename: 'Nombre documento',
                },
                modal: {
                    fields: {
                        file: {
                            label: 'Documento',
                        },
                    },
                    saveFailed: '¡Hubo un error al guardar el documento!',
                    title: 'Agregar documento',
                },
                title: 'Documentos',
            },
            'confirmationModal': {
                'optionalComment': 'Agregar comentarios (opcional):',
                'requiredComment': 'Agregar comentarios *:',
            },
            'confirmAuthorizeCancellation': {
                'message': '¿Deseas autorizar la cancelación del flete?',
                'title': 'Autorizar cancelación de flete',
            },
            'confirmComplete': {
                'message': '¿Deseas completar el flete?',
                'title': 'Completar flete',
            },
            'confirmRejectCancellation': {
                'message': '¿Deseas rechazar la cancelación del flete?',
                'title': 'Rechazar cancelación de flete',
            },
            'confirmRequestCancellation': {
                'message': '¿Deseas solicitar la cancelación del flete?',
                'title': 'Solicitud de cancelación de flete',
            },
            'setFreightToFalseStart': {
                'message': '¿Deseas establecer el estatus del flete como falso?',
                'title': 'Establecer flete a falso',
            },
            'grid': {
                'action': {
                    'addAfter': 'Agregar una nueva línea abajo',
                    'addBefore': 'Agregar una nueva línea arriba',
                    'deleteRow': 'Eliminar línea',
                    'moveAfter': 'Mover línea hacia abajo',
                    'moveBefore': 'Mover línea hacia arriba',
                },
                'columnHeaders': {
                    'actions': {
                        'tooltip': 'Botones de acciones',
                        'value': 'Acciones',
                    },
                    'border_crossing_id': {
                        'tooltip': 'Cruce',
                        'value': 'Cruce',
                    },
                    'customer_branch_id': {
                        'tooltip': 'Sucursal de cobro',
                        'value': 'Sucursal',
                    },
                    'destination_id': {
                        'tooltip': 'Localidad de destino',
                        'value': 'Destino',
                    },
                    'driver_id': {
                        'tooltip': 'Conductor',
                        'value': 'Conductor',
                    },
                    'freight_movement_type_id': {
                        'tooltip': 'Tipo de movimiento',
                        'value': 'Tipo *',
                    },
                    'move_trips': {
                        'tooltip': 'Mover movimientos',
                        'value': '',
                    },
                    'origin_id': {
                        'tooltip': 'Origen',
                        'value': 'Origen *',
                    },
                    'second_driver_id': {
                        'tooltip': 'Segundo conductor',
                        'value': 'Seg. conductor',
                    },
                    'second_trailer_id': {
                        'tooltip': 'Segundo remolque',
                        'value': 'Seg. remolque',
                    },
                    'status': {
                        'tooltip': 'Estatus',
                        'value': 'Estatus',
                    },
                    'tractor_id': {
                        'tooltip': 'Tractor asignado',
                        'value': 'Tractor',
                    },
                    'trailer_id': {
                        'tooltip': 'Remolque asignado',
                        'value': 'Remolque',
                    },
                },
            },
            'updateCustomer': {
                'message': '¿Deseas continuar con la actualización?',
                'title': 'Actualización de cliente del flete #{freight_id}',
            },
            'authorizeCancellation': 'Autorizar cancelación',
            'authorizeCancellationFailed': '¡Falló la autorización de la cancelación del flete!',
            'authorizeCancellationSuccess': 'Se autorizó la cancelación del flete.',
            'billedAfterComplete': '¡El flete se completó con éxito y su estatus ha cambiado a facturado!',
            'billOfLading': 'Guía de carga',
            'branch': 'Sucursal',
            'comments': 'Comentarios',
            'complete': 'Completar',
            'completeFailed': '¡No se pudo completar el flete!',
            'completeSuccess': '¡El flete se completó con éxito!',
            'createFreight': 'Crear flete',
            'customer': 'Cliente',
            'destination': 'Destino',
            'doda_integration_number': 'Número de integración DODA',
            'editFreight': 'Editar flete',
            'false': 'Falso',
            'freight_movements': 'Movimientos',
            'freightStatusUpdates': 'Estatus',
            'freightType': 'Tipo de flete',
            'hasSecondDriver': 'Seg. conductor',
            'isExpedited': 'Expeditado',
            'listIsEmpty': 'La lista está vacía',
            'loadFailed': '¡No se pudieron cargar los registros!',
            'name': 'Nombre',
            'origin': 'Origen',
            'rejectCancellation': 'Rechazar cancelación',
            'rejectCancellationFailed': '¡Falló el rechazo de la cancelación del flete!',
            'rejectCancellationSuccess': 'Se rechazó la cancelación del flete.',
            'remarks': 'Observaciones',
            'requestCancellation': 'Solicitar cancelación',
            'requestCancellationFailed': '¡Falló la solicitud de cancelación del flete!',
            'requestCancellationSuccess': 'Se solicitó la cancelación del flete exitosamente.',
            'saveFailed': '¡No se pudo guardar el flete!',
            'saveSuccess': '¡Se guardó exitosamente el flete #{freight_id}!',
            'setFreightToFalseStartFailed': '¡Falló la actualización del estatus del flete a falso!',
            'setFreightToFalseStartSuccess': 'Se actualizó el estatus del flete exitosamente.',
            'template': 'Plantilla',
            'title': 'Flete',
            'updateFailed': '¡No se pudo actualizar el flete!',
            'updateSuccess': '¡Se actualizó exitosamente el flete #{freight_id}!',
        },
        'freightDetailComment': {
            'columns': {
                'created_at': 'Fecha',
                'created_by': 'Usuario',
                'comment': 'Comentario',
            },
            'withoutComments': 'No hay comentarios',
        },
        'freightGrid': {
            'actionsCellRenderer': {
                'activateFreight': 'Activar flete',
                'authorizeFreightCancellation': 'Autorizar cancelación de flete',
                'completeFreight': 'Completar flete',
                dodaIntegrationNumber: 'Número de integración DODA',
                'rejectFreightCancellation': 'Rechazar cancelación de flete',
                'requestFreightCancellation': 'Solicitar cancelación de flete',
                'trackFreight': 'Ver ubicación en tiempo real',
                'viewFreight': 'Ver flete',
            },
            'columnHeaders': {
                'actions': {
                    'tooltip': 'Botones de acciones',
                    'value': 'Acciones',
                },
                'bill_of_lading': {
                    'tooltip': 'Guía de carga',
                    'value': 'Guía de carga',
                },
                'customer_branch_id': {
                    'tooltip': 'Sucursal',
                    'value': 'Sucursal',
                },
                'customer_group_id': {
                    'tooltip': 'Cliente',
                    'value': 'Cliente',
                },
                'comments': {
                    'tooltip': 'Comentarios del flete',
                    'value': 'C.',
                },
                'created_at': {
                    'tooltip': 'Fecha de creación',
                    'value': 'Fecha',
                },
                'created_by': {
                    'tooltip': 'Usuario de creación',
                    'value': 'Usuario',
                },
                'destination_id': {
                    'tooltip': 'Localidad de destino',
                    'value': 'Destino',
                },
                'freight_type_id': {
                    'tooltip': 'Tipo de flete',
                    'value': 'Tipo de flete',
                },
                'id': {
                    'tooltip': 'Número de flete',
                    'value': '# Flete',
                },
                'lading_bill_status': {
                    'tooltip': 'Comprobante Fiscal Digital por Internet',
                    'value': 'CFDI',
                },
                'origin_id': {
                    'tooltip': 'Localidad de origen',
                    'value': 'Origen',
                },
                seals: {
                    tooltip: 'Sellos',
                    value: 'S.',
                },
                'status': {
                    'tooltip': 'Estatus',
                    'value': 'Estatus',
                },
            },
            'errors': {
                'loadFailed': '¡No se pudieron cargar los fletes!',
            },
        },
        'freightIndex': {
            'activateFreight': {
                'failed': '¡Falló la activación del flete!',
                'message': '¿Deseas reactivar el flete?',
                'success': 'Se activó el flete exitosamente',
                'title': 'Activar flete - {freight_id}',
            },
            'authorizeFreightCancellationModal': {
                'message': '¿Deseas autorizar la cancelación del flete?',
                'title': 'Autorizar cancelación de flete - {freight_id}',
            },
            'confirmationModal': {
                'message': '¿Deseas completar el flete?',
                'optionalComment': 'Agregar comentarios (opcional)',
                'title': 'Completar flete - {freight_id}',
            },
            'rejectFreightCancellationModal': {
                'message': '¿Deseas rechazar la cancelación del flete?',
                'title': 'Rechazar cancelación de flete - {freight_id}',
            },
            'requestFreightCancellationModal': {
                'message': '¿Deseas solicitar la cancelación del flete?',
                'title': 'Solicitud de cancelación de flete - {freight_id}',
            },
            'requiredComment': 'Agregar comentarios (requerido)',
            'revertFreightTripStatus': {
                'failed': '¡Falló la modificación del estatus del movimiento!',
                'message': '¿Deseas revertir el estatus del movimiento?',
                'title': 'Revertir estatus del movimiento - {freight_id}-{order}',
                'success': 'Se modificó con éxito el estatus del movimiento',
            },
            'setFullInspectionMex': {
                'success': 'Se marcó en rojo el movimiento',
                'failed': '¡No se pudo marcar/desmarcar en rojo el movimiento!',
            },
            'unsetFullInspectionMex': {
                'success': 'Se desmarcó de rojo el movimiento',
            },
            'windowTitleLong': 'Consulta de movimientos',
            'windowTitleShort': 'Movimientos - {criteria}',
        },
        'freightIndexAdvancedFilters': {
            'apply': 'Aplicar',
            'billOfLading': 'Guía de carga',
            'clear': 'Limpiar',
            'current': 'Actuales',
            'customerGroup': 'Cliente',
            'filters': 'Filtros',
            'freightNumber': '# Flete',
            'freightStatus': 'Estatus de flete',
            'freightType': 'Tipo de flete',
            'freightUser': 'Usuario',
            'tripDateType': 'Fecha',
            'tripDriver': 'Conductor',
            'tripStatus': 'Estatus de movimiento',
            'tripTractor': 'Tractor',
            'tripTrailer': 'Remolque',
            'tripType': 'Tipo de movimiento',
        },
        freightStatusUpdate: {
            download: {
                failed: '¡Hubo un error al descargar el archivo!',
                label: 'Notificaciones',
            },
            grid: {
                columns: {
                    bill_of_lading: 'Referencia',
                    code: 'Tipo',
                    occurred_at: 'Fecha',
                    sequence: '# Parada',
                    status: 'Estatus',
                },
            },
            listFailed: '¡Hubo un error al obtener los datos de las actualizaciones de estatus de flete!',
            title: 'Actualizaciones de estatus',
        },
        'freightTripCommentsModal': {
            'comment': 'Comentario',
            'date': 'Fecha',
            'emptyComments': 'No hay comentarios para el movimiento.',
            'saveFailed': '¡No se pudo guardar el comentario!',
            'title': 'Comentarios del movimiento',
            'user': 'Usuario',
        },
        'freightTripCreateModal': {
            'destination': 'Destino',
            'origin': 'Origen',
            'saveError': '¡No se pudo guardar el movimiento!',
            'title': 'Agregar movimiento al flete #{freightId}',
            'trailer': 'Remolque',
            'trailer_type': 'Tipo de remolque',
            'type': 'Tipo',
        },
        'freightTripDetailsGrid': {
            'columnHeaders': {
                'actions': {
                    'tooltip': 'Acciones',
                    'value': 'Acciones',
                },
                'authorization': {
                    'tooltip': 'Autorización',
                    'value': 'Autorización',
                },
                'border_crossing_id': {
                    'tooltip': 'Cruce fronterizo',
                    'value': 'Cruce',
                },
                'comments': {
                    'tooltip': 'Comentarios',
                    'value': 'C.',
                },
                'coordinate': {
                    'tooltip': 'Coordenada',
                    'value': 'Coord.',
                },
                'customer_branch_id': {
                    'tooltip': 'Sucursal de cobro',
                    'value': 'Sucursal',
                },
                'destination_arrival': {
                    'tooltip': 'Arribo destino',
                    'value': 'AD',
                },
                'destination_id': {
                    'tooltip': 'Destino',
                    'value': 'Destino',
                },
                'driver_id': {
                    'tooltip': 'Conductor',
                    'value': 'Conductor',
                },
                'freight_movement_type_id': {
                    'tooltip': 'Tipo de movimiento',
                    'value': 'Tipo',
                },
                'foreign_customs_arrival': {
                    'tooltip': 'Arribo aduana USA',
                    'value': 'AAUs',
                },
                'foreign_customs_departure': {
                    'tooltip': 'Salida aduana USA',
                    'value': 'SAUs',
                },
                'full_inspection_mex_event_id': {
                    'tooltip': 'Rojo',
                    'value': 'Rojo',
                },
                'local_customs_arrival': {
                    'tooltip': 'Arribo aduana MEX',
                    'value': 'AAMx',
                },
                'local_customs_departure': {
                    'tooltip': 'Salida aduana MEX',
                    'value': 'SAMx',
                },
                'origin_arrival': {
                    'tooltip': 'Arribo origen',
                    'value': 'AO',
                },
                'origin_departure': {
                    'tooltip': 'Salida origen',
                    'value': 'SO',
                },
                'origin_id': {
                    'tooltip': 'Origen',
                    'value': 'Origen',
                },
                'preinspection_arrival': {
                    'tooltip': 'Arribo precos',
                    'value': 'AP',
                },
                'preinspection_departure': {
                    'tooltip': 'Salida precos',
                    'value': 'SP',
                },
                'second_driver_id': {
                    'tooltip': 'Segundo conductor',
                    'value': 'Seg. conductor',
                },
                'second_trailer_id': {
                    'tooltip': 'Segundo remolque',
                    'value': 'Seg. remolque',
                },
                'started_at': {
                    'tooltip': 'Fecha de arranque',
                    'value': 'Arranque',
                },
                'status': {
                    'tooltip': 'Estatus',
                    'value': 'Estatus',
                },
                'ticket': {
                    'tooltip': 'Boleta',
                    'value': 'Boleta',
                },
                'tractor_id': {
                    'tooltip': 'Tractor',
                    'value': 'Tractor',
                },
                'trailer_id': {
                    'tooltip': 'Remolque',
                    'value': 'Remolque',
                },
            },
            'columns': {
                'authorization': {
                    'error': 'No se pudo actualizar la autorización del movimiento.',
                },
                'border_crossing_id': {
                    'error': 'No se pudo actualizar el cruce fronterizo del movimiento.',
                },
                'customer_branch_id': {
                    'error': 'No se pudo actualizar la sucursal del movimiento.',
                },
                'destination_arrival': {
                    'error': 'No se pudo registrar arribo destino del movimiento.',
                },
                'destination_id': {
                    'error': 'No se pudo actualizar el destino del movimiento.',
                },
                'driver_id': {
                    'error': 'No se pudo actualizar el conductor del movimiento.',
                    'tooltip': 'Requerido para capturar eventos y completar el movimiento.',
                },
                'foreign_customs_arrival': {
                    'error': 'No se pudo registrar arribo aduana USA del movimiento.',
                },
                'foreign_customs_departure': {
                    'error': 'No se pudo registrar salida aduana USA del movimiento.',
                },
                'freight_movement_type_id': {
                    'error': 'No se pudo actualizar el tipo de movimiento.',
                },
                'local_customs_arrival': {
                    'error': 'No se pudo registrar arribo aduana MEX del movimiento.',
                },
                'local_customs_departure': {
                    'error': 'No se pudo registrar salida aduana MEX del movimiento.',
                },
                'origin_arrival': {
                    'error': 'No se pudo registrar arribo origen del movimiento.',
                },
                'origin_departure': {
                    'error': 'No se pudo registrar salida origen del movimiento.',
                    'tooltip': 'Requerido para completar el movimiento.',
                },
                'origin_id': {
                    'error': 'No se pudo actualizar el origen del movimiento.',
                },
                'preinspection_arrival': {
                    'error': 'No se pudo registrar arribo PRECOS del movimiento.',
                },
                'preinspection_departure': {
                    'error': 'No se pudo registrar salida PRECOS del movimiento.',
                },
                'second_driver_id': {
                    'error': 'No se pudo actualizar el segundo conductor del movimiento.',
                },
                'status': {
                    'error': 'No se pudo actualizar el estatus del movimiento.',
                },
                'ticket': {
                    'error': 'No se pudo actualizar la boleta del movimiento.',
                },
                'tractor_id': {
                    'error': 'No se pudo actualizar el tractor del movimiento.',
                    'tooltip': 'Requerido para capturar eventos y completar el movimiento.',
                },
                'trailer_id': {
                    'error': 'No se pudo actualizar el remolque del movimiento.',
                },
                'second_trailer_id': {
                    'error': 'No se pudo actualizar el segundo remolque del movimiento.',
                },
            },
            'actionsCellRenderer': {
                'addFreightTrip': 'Agregar movimiento después',
                'cancelFreightTrip': 'Cancelar movimiento',
                'completeFreightTrip': 'Completar movimiento',
                'createDeparturePass': 'Pase de salida',
                'revertFreightTripStatus': 'Revertir estatus del movimiento',
            },
            'fullInspectionMexCellRenderer': {
                'fullInspectionSwitch': 'Marcar/desmarcar movimiento en rojo',
            },
        },
        'freightTripHistoryForm': {
            'driver': 'Conductor',
            'tractor': 'Tractor',
            'trailer': 'Remolque',
            'searchButton': 'Consultar',
        },
        'freightTripHistory': {
            'grid': {
                'coordinate': 'Coordenada',
                'status': 'Estatus',
                'client': 'Cliente',
                'origin': 'Origen',
                'destination': 'Destino',
                'type': 'Tipo',
                'driver': 'Conductor',
                'tractor': 'Tractor',
                'trailer': 'Remolque',
                'bill_of_lading': 'Guía de carga',
                'start_date': 'Arranque',
                'destination_arrival': 'A. D.',
            },
            'loadFailed': '¡No se pudieron cargar los registros!',
        },
        ladingBill: {
            destroyFailed: '¡No se pudo eliminar la Carta Porte!',
            storeFailed: '¡Hubo un error al crear la carta Porte!',
        },
        ladingBillModal: {
            showFailed: '¡No se pudo obtener los datos de la Carta Porte!',
            title: 'Carta Porte flete #{freight_id} - {reference}',
            titleWithoutReference: 'Carta Porte flete #{freight_id}',
            updateFailed: '¡Falló la creación de la Carta Porte!',
        },
        'addFreight': 'Agregar flete',
        'bill_of_lading': {
            'label': 'BOL',
        },
        'freights': 'Fletes',
        'getFailed': '¡No se pudo obtener el flete!',
        'getInvoiceFailed': '¡Hubo un error al obtener la factura!',
        'label': 'Flete',
        'refreshGridButtonTitle': 'Refrescar tabla de fletes.',
        seals: {
            add: 'Agregar sellos',
            date: 'Fecha',
            deleteFailed: '¡Hubo un error al eliminar el sello del flete!',
            grid: {
                actions: 'Acciones',
                created_by_user: 'Usuario',
                date: 'Fecha',
                emptySeals: 'Sin registros',
                seal: 'Sello',
            },
            saveFailed: '¡Hubo un error al guardar el sello del flete!',
            title: 'Sellos',
        },
        'searchByTrailer': 'Búsqueda por remolque',
        'status': {
            'label': 'Estatus',
        },
        'title': 'Flete',
        'unitTrackWindow': {
            'title': 'Flete',
            'noActiveTrip': 'No se encuentra ningún movimiento activo',
        },
    },
    'freight_movement': {
        'billingBranches': {
            'loadFailed': 'No se puedieron cargar las sucursales.',
        },
        'closeFreightMovement': {
            'success': 'Los movimientos de fletes fueron cerrados con éxito.',
            'title': 'Cerrar Movimientos',
            'message': '¿Deseas cerrar los movimientos seleccionados?',
            'default_message_error': 'Ocurrió un error al cerrar los movimientos seleccionados.',
            'button_tooltip': {
                'disabled_without_movements': 'No se seleccionaron movimientos de flete.',
                'disabled_with_movements': 'Solo se pueden cerrar los movimientos de flete ' +
                    'con estatus falso, completado o cancelado.',
                'enabled': 'Cerrar movimiento(s) de flete.',
            },
        },
        'commentModal': {
            'comment': 'Comentario',
            'date': 'Fecha',
            'emptyComments': 'No hay comentarios para el movimiento.',
            'title': 'Comentarios del movimiento',
            'user': 'Usuario',
        },
        'completeModal': {
            'success': 'El movimiento de flete fue completado con éxito.',
            'authorization': 'Autorización',
            'errors': {
                'markCompleteFailed': '¡No se pudo marcar como completo el movimiento!',
            },
            'instructions': '¿Desea completar este movimiento?',
            'recipient': 'Receptor',
            'title': 'Completar movimiento',
        },
        'datePicker': {
            'from:': 'Desde:',
            'to:': 'Hasta:',
        },
        'datepickerModal': {
            'instructions': 'Indica el rango de fechas con el que desees filtrar.',
            'title': 'Filtrado de movimientos',
        },
        'freightMovementsGrid': {
            'columnHeaders': {
                'actions': {
                    'value': 'Acciones',
                    'tooltip': 'Botones de acciones',
                },
                'authorization': {
                    'value': 'Autorización',
                    'tooltip': 'Autorización',
                },
                'bill_of_lading': {
                    'value': 'Guía de carga',
                    'tooltip': 'Guía de carga',
                },
                'borderCrossing': {
                    'value': 'Cruce',
                    'tooltip': 'Cruce',
                },
                'coordinate': {
                    'value': 'Coord.',
                    'tooltip': 'Número de coordenada',
                },
                'customer': {
                    'value': 'Cliente',
                    'tooltip': 'Cliente',
                },
                'customer_branch_id': {
                    'value': 'Sucursal',
                    'tooltip': 'Sucursal',
                },
                'destination': {
                    'value': 'Destino',
                    'tooltip': 'Localidad de destino',
                },
                'destinationArrival': {
                    'value': 'AD',
                    'tooltip': 'Arribo destino',
                },
                'driver': {
                    'value': 'Conductor',
                    'tooltip': 'Conductor asignado',
                },
                'foreignCustomsArrival': {
                    'value': 'AAUs',
                    'tooltip': 'Arribo aduana USA',
                },
                'foreignCustomsDeparture': {
                    'value': 'SAUs',
                    'tooltip': 'Salida aduana USA',
                },
                'freightMovementComment': {
                    'value': 'C.',
                    'tooltip': 'Comentarios del movimiento',
                },
                'freightTripCoordinate': {
                    'value': 'Coord.',
                    'tooltip': 'Nueva Coordenada',
                },
                'fullExamination': {
                    'value': 'Rojo',
                    'tooltip': 'Rojo',
                },
                'localCustomsArrival': {
                    'value': 'AAMx',
                    'tooltip': 'Arribo aduana MEX',
                },
                'localCustomsDeparture': {
                    'value': 'SAMx',
                    'tooltip': 'Salida aduana MEX',
                },
                'movementType': {
                    'value': 'Tipo',
                    'tooltip': 'Tipo de movimiento',
                },
                'origin': {
                    'value': 'Origen',
                    'tooltip': 'Origen',
                },
                'originArrival': {
                    'value': 'AO',
                    'tooltip': 'Arribo origen',
                },
                'originDeparture': {
                    'value': 'SO',
                    'tooltip': 'Salida origen',
                },
                'preinspectionArrival': {
                    'value': 'AP',
                    'tooltip': 'Arribo precos',
                },
                'preinspectionDeparture': {
                    'value': 'SP',
                    'tooltip': 'Salida precos',
                },
                'requirementTimestamp': {
                    'value': 'Arranque',
                    'tooltip': 'Fecha de arranque',
                },
                'second_driver_id': {
                    'value': 'Seg. conductor',
                    'tooltip': 'Segundo conductor',
                },
                'second_trailer_id': {
                    'value': 'Seg. remolque',
                    'tooltip': 'Segundo remolque',
                },
                'select': 'Selección',
                'status': {
                    'value': 'Estatus',
                    'tooltip': 'Estatus actual',
                },
                'ticket': {
                    'value': 'Boleta',
                    'tooltip': 'Boleta',
                },
                'tractor': {
                    'value': 'Tractor',
                    'tooltip': 'Tractor asignado',
                },
                'trailer': {
                    'value': 'Remolque',
                    'tooltip': 'Remolque asignado',
                },
                'user': {
                    'value': 'Usuario',
                    'tooltip': 'Usuario',
                },
            },
            'options': {
                'freight': 'Flete',
            },
            'ladingBillNonExistent': 'Generar CFDI',
            'ladingBillStamped': 'Timbrado',
            'ladingBillStamping': 'En proceso de timbrado',
            'ladingBillError': 'Ocurrió un error de timbrado',
            'Loading': 'Cargando...',
        },
        'freightMovementStatistics': {
            'errors': {
                'loadFailed': '¡No se pudieron cargar las estadísticas!',
            },
        },
        'freightMovementTypes': {
            'loadFailed': 'No se pudieron cargar los tipos de movimiento.',
        },
        'gridExport': {
            'baseFilename': 'movimientos_de_fletes{timestamp}',
            'billingByTrailer': 'Movimientos por remolque',
            'button': {
                'tooltip': 'Exportar como archivo de Microsoft Excel',
            },
            'errors': {
                'exportGenerationFailed': '¡No se pudo generar el archivo!',
            },
            'freightTrips': 'Movimientos de flete',
            'ready': '<p>El resultado de la consulta está listo. <a target="_blank" href="{url}">Descargar</a></p>',
            'success': 'Tu consulta se está procesando, espera el resultado en tu correo.',
        },
        'lastFreightTrip': {
            'success': {
                'title': 'Verificar flete',
                'message': 'Has actualizado el último movimiento activo del flete.'
                    + ' ¿Quieres modificar el estatus del flete?',
            },
            'error': {
                'title': 'Verificar flete',
                'message': 'No se pudo verificar si se actualizó el último movimiento del flete.'
                    + ' ¿Quieres revisar el flete y sus movimientos?',
            },
        },
        'statistics': {
            'shown': 'Total',
            'completed': 'Completo',
            'not_completed': 'No completo',
            'full_inspection_mex': 'Rojo',
            'false': 'Falso',
            'cancelled': 'Cancelado',
            'loading': 'Cargando...',
        },
        'toolbar': {
            'buttons': {
                'clearSelection': {
                    'label': '',
                    'tooltip': 'Limpiar selección',
                },
                'resetFilters': {
                    'label': 'Actuales',
                    'tooltip': 'Restablecer filtrado de movimientos actuales no completados',
                },
            },
        },
        'All': 'Todos',
        'apply': 'Aplicar',
        'billingBranchAll': 'Todas',
        'Billing branch': 'Sucursal',
        'Complete': 'Completar',
        'createFreight': 'Crear flete',
        'Customer': 'Cliente',
        'Date': 'Fecha',
        'Destination': 'Destino',
        'errorUpdatingStatus': 'Hubo un error al actualizar el estatus.',
        'Export': 'Exportar',
        'Filters': 'Filtros',
        'Freight movements': 'Movimientos',
        'List is empty': 'La lista está vacía',
        'Movement type': 'Tipo de movimiento',
        'No results found': 'No se encontraron resultados',
        'Not completed': 'No completados',
        'Not specified': 'No indicado',
        'Origin': 'Origen',
        'Own': 'Del catálogo',
        'Refresh freight movement table': 'Refrescar table de movimientos de fletes.',
        'Save': 'Guardar',
        'Search by coordinate': 'Búsqueda por coordenada',
        'There was an error updating the authorization': 'Hubo un error al actualizar la autorización.',
        'There was an error updating the billing branch': 'Hubo un error al actualizar la sucursal.',
        'There was an error updating the border crossing': 'Hubo un error al actualizar el cruce fronterizo.',
        'There was an error updating the destination': 'Hubo un error al actualizar el destino.',
        'There was an error updating the event time': 'Hubo un error al actualizar el tiempo del evento.',
        'There was an error updating the freight driver': 'Hubo un error al actualizar el conductor.',
        'There was an error updating the freight movement type': 'Hubo un error al actualizar el tipo de movimiento.',
        'There was an error updating the freight tractor': 'Hubo un error al actualizar el tractor.',
        'There was an error updating the freight trailer': 'Hubo un error al actualizar el trailer.',
        'There was an error updating the origin': 'Hubo un error al actualizar el origen.',
        'There was an error updating the recipient': 'Hubo un error al actualizar el receptor.',
        'There was an error updating the ticket': 'Hubo un error al actualizar la boleta.',
        'Third-party': 'Ajeno',
        'Trailer type': 'Tipo de remolque',
        'Trailer': 'Remolque',
        'Without trailer': 'Sin remolque',
    },
    'freightTemplate': {
        'addFreightTemplate': 'Agregar plantilla de flete',
        'confirmDelete': {
            'message': '¿Está seguro de que desea eliminar la plantilla de flete {name}?',
            'title': 'Eliminar plantilla de flete',
        },
        'deleteError': 'La plantilla de flete no se pudo eliminar.',
        'deleteSuccess': 'La plantilla de flete se eliminó exitosamente.',
        'freightTemplateForm': {
            'grid': {
                'action': {
                    'addDown': 'Agregar una nueva línea abajo',
                    'addUp': 'Agregar una nueva línea arriba',
                    'delete': 'Eliminar línea',
                    'moveDown': 'Mover línea hacia abajo',
                    'moveUp': 'Mover línea hacia arriba',
                },
                'addFreightMovement': 'Agregar movimiento',
                'columnHeaders': {
                    'actions': {
                        'tooltip': 'Botones de acciones',
                        'value': 'Acciones',
                    },
                    'border_crossing_id': {
                        'tooltip': 'Cruce fronterizo',
                        'value': 'Cruce',
                    },
                    'destination_id': {
                        'tooltip': 'Localidad de destino',
                        'value': 'Destino',
                    },
                    'driver_id': {
                        'tooltip': 'Conductor asignado',
                        'value': 'Conductor',
                    },
                    'freight_movement_type_id': {
                        'tooltip': 'Tipo de movimiento',
                        'value': 'Tipo de movimiento *',
                    },
                    'origin_id': {
                        'tooltip': 'Localidad de origen',
                        'value': 'Origen',
                    },
                    'tractor_id': {
                        'tooltip': 'Tractor asignado',
                        'value': 'Tractor',
                    },
                    'trailer_id': {
                        'tooltip': 'Remolque asignado',
                        'value': 'Remolque',
                    },
                },
            },
            'branch': 'Sucursal',
            'create': 'Crear plantilla de flete',
            'customer': 'Cliente',
            'destination': 'Destino',
            'edit': 'Editar plantilla de flete',
            'freightType': 'Tipo de flete',
            'listIsEmpty': 'La lista está vacía',
            'loadFailed': '¡No se pudieron cargar los registros!',
            'name': 'Nombre',
            'noResultsFound': 'No se encontraron resultados',
            'origin': 'Origen',
            'saveFailed': '¡No se pudo guardar la plantilla de flete!',
            'saveSuccess': '¡Se guardó exitosamente la plantilla de flete!',
            'title': 'Plantilla de flete',
        },
        'freightTemplatesGrid': {
            'columnHeaders': {
                'actions': {
                    'tooltip': 'Botones de acciones',
                    'value': 'Acciones',
                },
                'customer_branch': {
                    'tooltip': 'Sucursal',
                    'value': 'Sucursal',
                },
                'customer_group': {
                    'tooltip': 'Cliente',
                    'value': 'Cliente',
                },
                'freight_type_id': {
                    'tooltip': 'Tipo de flete',
                    'value': 'Tipo de flete',
                },
                'name': {
                    'tooltip': 'Nombre',
                    'value': 'Nombre',
                },
            },
            'errors': {
                'loadFailed': '¡No se pudieron cargar las plantillas de flete!',
            },
            'Loading': 'Cargando...',
        },
        'title': 'Plantillas de flete',
    },
    'freightTripsByVehicle': {
        'grid': {
            'columnHeaders': {
                'destination': {
                    'tooltip': 'Destino del movimiento',
                    'value': 'Destino',
                },
                'driver': {
                    'tooltip': 'Conductor',
                    'value': 'Conductor',
                },
                'coordinate': {
                    'tooltip': 'Coordenada',
                    'value': 'Coordenada',
                },
                'customer_group': {
                    'tooltip': 'Cliente',
                    'value': 'Cliente',
                },
                'customer_branch': {
                    'tooltip': 'Sucursal',
                    'value': 'Sucursal',
                },
                'freight_destination': {
                    'tooltip': 'Destino de flete',
                    'value': 'Destino flete',
                },
                'freight_id': {
                    'tooltip': 'Número de flete',
                    'value': 'Flete',
                },
                'freight_origin': {
                    'tooltip': 'Origen de flete',
                    'value': 'Origen flete',
                },
                'freight_reference': {
                    'tooltip': 'Guía de carga',
                    'value': 'BOL',
                },
                'freight_status': {
                    'tooltip': 'Estatus de flete',
                    'value': 'Estatus flete',
                },
                'freight_type': {
                    'tooltip': 'Tipo de flete',
                    'value': 'Tipo flete',
                },
                'gps_item_imei': {
                    'tooltip': 'IMEI del GPS',
                    'value': 'IMEI',
                },
                'origin': {
                    'tooltip': 'Origen del movimiento',
                    'value': 'Origen',
                },
                'started_at': {
                    'tooltip': 'Fecha de arranque',
                    'value': 'Arranque',
                },
                'status': {
                    'tooltip': 'Estatus del movimiento',
                    'value': 'Estatus',
                },
                'trailer': {
                    'tooltip': 'Remolque',
                    'value': 'Remolque',
                },
                'type': {
                    'tooltip': 'Tipo de movimiento',
                    'value': 'Tipo',
                },
                'vehicle': {
                    'tooltip': 'Vehículo',
                    'value': 'Vehículo',
                },
            },
        },
        'title': 'Consultas - Movimientos',
    },
    freightTripType: {
        create: {
            title: 'Crear tipo de movimiento',
        },
        downloadTemplate: {
            failed: '¡Hubo un error al descargar la plantilla de tipos de movimientos!',
            title: 'Descargar plantilla de tipos de movimientos',
        },
        show: {
            title: 'Ver tipo de movimiento',
        },
        edit: {
            title: 'Editar tipo de movimiento',
        },
        createModal: {
            saveFailed: '¡Hubo un error al guardar el tipo de movimiento!',
            saveSuccess: '¡Se guardó exitosamente el tipo de movimiento!',
        },
        fields: {
            description: {
                label: 'Descripción',
                validationError: 'El descripción debe contener 30 caracteres com máximo',
            },
            name: {
                label: 'Nombre',
                validationError: 'El nombre debe contener 30 caracteres com máximo',
            },
        },
        grid: {
            columns: {
                actions: 'Acciones',
                name: 'Nombre',
                description: 'Descripción',
                status: 'Estatus',
            },
        },
        list: {
            error: '¡Hubo un error al obtener los tipos de movimiento!',
        },
        showFailed: '¡Hubo un error al obtener el tipo de movimiento!',
        title: 'Tipos de movimiento',
        updateStatusFailed: '¡Hubo un error al actualizar el estatus del tipo de movimiento!',
    },
    'freightType': {
        'detail': {
            'saveFailed': '¡No se pudo guardar el tipo de flete!',
            'saveSuccess': '¡Se guardó exitosamente el tipo de flete!',
        },
        downloadTemplate: {
            failed: '¡Hubo un error al descargar la plantilla de tipos de fletes!',
            title: 'Descargar plantilla de tipos de fletes',
        },
        'form': {
            'code': {
                'label': 'Código',
                'validationError': 'El código debe contener entre 1 y 20 caracteres.',
            },
            'description': {
                'label': 'Descripción',
                'validationError': 'La descripción debe contener entre 1 y 255 caracteres.',
            },
            'itemTypeDescription': {
                'label': 'Descripción de facturación',
            },
            'internationalTransportType': {
                'label': 'Transporte internacional',
            },
            'requireLadingBill': {
                'label': 'Carta porte',
            },
            'measurementUnit': {
                'label': 'Clave unidad de medida',
            },
            'productService': {
                'label': 'Clave producto/servicio',
            },
            'taxSet': {
                'label': 'Impuestos',
            },
        },
        'freightTypesGrid': {
            'columnHeaders': {
                'actions': {
                    'tooltip': 'Botones de acciones',
                    'value': 'Acciones',
                },
                'code': {
                    'tooltip': 'Código',
                    'value': 'Código',
                },
                'description': {
                    'tooltip': 'Descripción',
                    'value': 'Descripción',
                },
                'international_transport_type': {
                    'value': 'Transporte internacional',
                    'tooltip': 'Transporte internacional',
                },
                'is_active': {
                    'value': 'Estatus',
                    'tooltip': 'Estatus',
                },
            },
        },
        'getFailed': '¡No se pudo obtener el tipo de flete!',
        'label': 'Tipo',
        'listError': '¡No se pudieron obtener los tipos de flete!',
        'title': 'Tipos de flete',
    },
    'freightTypeCustomerVariant': {
        'addButton': 'Agregar cliente',
        'additionalCharge': {
            'delete': {
                'title': 'Eliminar cargo adicional',
                'message': '¿Estas seguro que deseas eliminar el cargo adicional "{code}"?',
            },
            'form': {
                'title': {
                    'create': 'Agregar cargo adicional',
                    'edit': 'Editar cargo adicional',
                },
            },
            'saveError': '¡No se pudo guardar el cargo adicional!',
            'saveSuccess': '¡Se guardó exitosamente el cargo adicional!',
        },
        'breadcrumb': 'Ruta {customer_group} - {code}',
        'delete': {
            'error': '¡No se pudo eliminar el cliente!',
            'message': '¿Estas seguro que deseas eliminar el cliente "{name}"?',
            'success': 'El cliente se eliminó exitosamente.',
            'title': 'Eliminar cliente',
        },
        'form': {
            'billingCurrency': {
                'label': 'Moneda facturación',
            },
            'currency': {
                'label': 'Moneda cotización',
            },
            'customerGroup': {
                'label': 'Cliente',
            },
            'destinationLocalities': {
                'label': 'Localidades de destino',
            },
            'distance': {
                'label': 'Distancia (km)',
                'validationError': 'La distancia debe ser mayor a 0.01 y menor que 99999.',
            },
            'code': {
                'label': 'Código',
                'validationError': 'El código debe contener entre 1 y 20 caracteres.',
            },
            'exchangeRate': {
                'label': 'Tipo de cambio',
                'validationError': 'El tipo de cambio debe ser mayor a 0.000001.',
            },
            'originLocalities': {
                'label': 'Localidades de origen',
            },
            'price': {
                'label': 'Precio',
                'validationError': 'El precio debe ser mayor de 0.01.',
            },
            'series': {
                'label': 'Serie',
            },
            'taxSet': {
                'label': 'Impuestos',
            },
        },
        'getFailed': '¡No se pudo obtener el cliente!',
        'grid': {
            'actions': 'Acciones',
            'billing_currency_code': 'Moneda facturación',
            'code': 'Código',
            'currency_code': 'Moneda cotización',
            'customer_group_id': 'Cliente',
            'distance': 'Distancia (km)',
            'exchange_rate': 'Tipo de cambio',
            'price': 'Precio',
            'tax_set_id': 'Impuestos',
        },
        'label': 'Ruta',
        'listError': '¡No se pudieron obtener las variantes del tipo de flete!',
        'listLocalitiesError': '¡No se pudieron obtener las localidades del cliente!',
        'price': {
            'basePriceLabel': 'Base',
            'delete': {
                'title': 'Eliminar variante de precio',
                'message': '¿Estas seguro que deseas eliminar la variante de precio "{code}"?',
            },
            'deleteFailed': '¡No se pudo eliminar la variante de precio!',
            'deleteSuccess': 'La variante de precio se eliminó exitosamente',
            'form': {
                'code': {
                    'label': 'Código',
                    'validationError': 'El código debe contener entre 1 y 20 caracteres.',
                },
                'conditions': 'Condiciones: (Requerido indicar al menos una condición)',
                'crossingDirection': {
                    'label': 'Dirección de cruce',
                },
                'currencyCode': {
                    'label': 'Moneda',
                },
                'escort': {
                    'label': 'Flete con escolta',
                },
                'expedite': {
                    'label': 'Flete expeditado',
                },
                'borderCrossing': {
                    'label': 'Cruce',
                },
                'hazardousMaterial': {
                    'label': 'Material peligroso',
                },
                'income_type': {
                    'label': 'Tipo de ingreso',
                },
                'price': {
                    'label': 'Precio',
                    'validationError': 'El precio debe ser igual o mayor a 0.01',
                },
                'secondDriver': {
                    'label': 'Segundo conductor',
                },
                'scope': {
                    'label': 'Cargo aplicable al',
                },
                'title': {
                    'create': 'Agregar variante de precio',
                    'edit': 'Editar variante de precio',
                },
                'trailerConfiguration': {
                    'label': 'Número llantas remolque',
                },
                'trailerOrigin': {
                    'label': 'Origen remolque',
                },
                'trailerSize': {
                    'label': 'Medida de remolque',
                },
                'trailerTypeCode': {
                    'label': 'Tipo de remolque',
                },
                'vehicleTypeCode': {
                    'label': 'Tipo de vehículo',
                },
            },
            'grid': {
                'actions': 'Acciones',
                'code': 'Código',
                'currency_code': 'Moneda',
                'income_type': 'Tipo de ingreso',
                'price': 'Precio',
                'scope': 'Cargo aplicable al',
            },
            'label': 'Precio',
            'listFailed': '¡No se pudieron obtener las variantes de precio!',
            'saveError': '¡No se pudo guardar la variante de precio!',
            'saveSuccess': '¡Se guardó exitosamente la variante de precio!',
            'title': 'Variantes de precio',
        },
        'saveError': '¡No se pudo guardar el cliente!',
        'saveSuccess': '¡Se guardo exitosamente el cliente!',
        'serviceRequestListError': '¡No se pudieron obtener las referencias de archivos de mercancías!',
        'tabs': {
            'additionalCharges': 'Cargos adicionales',
            'customerVariant': 'Ruta',
            'customerVariantPrice': 'Variantes de precio',
        },
        'tripTypes': {
            'delete': {
                'title': 'Eliminar detalle de movimiento',
                'message': '¿Estas seguro que deseas eliminar el detalle de movimiento "{name}"?',
            },
            'emptyTripTypes': 'Se debe agregar al menos un detalle de movimiento.',
            'form': {
                'freightTripType': {
                    'label': 'Tipo',
                },
                'includeInInvoiceDetailReport': {
                    'true': 'Incluir en reporte',
                    'false': 'No incluir en reporte',
                },
                'isPrimary': {
                    'true': 'Movimiento principal',
                    'false': 'Movimiento secundario',
                },
                'maximum': {
                    'label': 'Máximo movimientos',
                    'validationError': 'La cantidad máxima de movimientos debe ser igual o mayor a 0.',
                },
                'minimum': {
                    'isGreaterThanMaximum': 'La cantidad mínima debe ser menor o igual que la cantidad máxima.',
                    'label': 'Mínimo movimientos',
                    'validationError': 'La cantidad mínima de movimientos debe ser igual o mayor a 0.',
                },
                'price': {
                    'label': 'Precio movimiento excedente',
                    'validationError': 'El precio del movimiento excedente debe ser igual o mayor a 0.01',
                },
                'priceFalse': {
                    'label': 'Precio movimiento falso',
                    'validationError': 'El precio del movimiento falso debe ser igual o mayor a 0.01',
                },
                'ratio': {
                    'label': 'Proporción',
                    'validationError': {
                        'isPrimary': 'La proporción debe ser mayor a 0.00 y menor o igual a 100 si es el movimiento principal',
                        'notPrimary': 'La proporción debe ser igual o mayor a 0.00 y menor o igual a 100',
                    },
                },
                'title': {
                    'create': 'Agregar tipo de movimiento',
                    'edit': 'Editar tipo de movimiento',
                },
            },
            'grid': {
                'actions': 'Acciones',
                'freight_trip_type_id': 'Tipo',
                'include_in_invoice_detail_report': 'Incluir en reporte',
                'is_primary': 'Mov principal',
                'maximum': 'Máx movs',
                'measurement_unit_code': 'Clave unidad de medida',
                'minimum': 'Mín movs',
                'price': 'Precio mov excedente',
                'price_false': 'Precio mov falso',
                'product_service_code': 'Clave producto/servicio',
                'ratio': 'Proporción',
                'tax_set_id': 'Impuestos',
            },
            'missingPrimaryTripType': 'Se debe especificar un detalle de movimiento principal.',
            'title': 'Detalle de movimientos',
        },
    },
    fuelEfficiency: {
        dailyFuelConsumed: {
            error: '¡Hubo un error al obtener el consumo diario de combustible!',
            fuelUnits: {
                label: 'Unidades',
                placeholder: 'Seleccionar unidades',
            },
            grid: {
                columns: {
                    liters: 'Litros',
                    loaded_at: 'Fecha',
                    name: 'Unidad',
                    price: 'Precio',
                    total_price: 'Total',
                },
            },
            title: 'Consumo diario de combustible',
        },
        fuelConsumed: {
            error: '¡Hubo un error al obtener los consumos de combustible!',
            fuelUnits: {
                label: 'Unidades',
                placeholder: 'Seleccionar unidades',
            },
            grid: {
                columns: {
                    id: 'Unidad',
                    liters: 'Litros',
                    price: 'Precio por litro',
                    total_price: 'Pesos',
                },
            },
            title: 'Consumo de combustible',
        },
        fuelLoad: {
            cancel: {
                dialogTitle: 'Cancelar carga de combustible',
                fails: '¡Hubo un error al cancelar la carga de combustible!',
                title: '¿Estás seguro que deseas cancelar la carga de combustible?',
            },
            form: {
                date: {
                    label: 'Fecha de carga',
                },
                fuel_type: {
                    label: 'Tipo de combustible',
                },
                fuel_unit_id: {
                    label: 'Unidad',
                },
                liters: {
                    label: 'Litros',
                    placeholder: 'Litros',
                },
                odometer: {
                    label: 'Odómetro',
                    placeholder: 'Odómetro',
                },
                odometer_unit_code: {
                    label: 'Unidad de medida de odómetro',
                },
                seals: {
                    label: 'Sellos',
                    placeholder: 'Sellos',
                },
                time: {
                    label: 'Hora de carga',
                },
            },
            getFuelUnitFailed: '¡Hubo un error al obtener los datos de combustible de la unidad!',
            grid: {
                columns: {
                    fuel_unit_id: 'Unidad',
                    liters: 'Litros',
                    loaded_at: 'Fecha carga',
                    odometer: 'Odómetro',
                    seals: 'Sellos',
                    status: 'Estatus',
                },
            },
            list: {
                error: '¡Hubo un error al obtener las cargas de combustible!',
            },
            saveFailed: '¡Hubo un error al guardar la carga de combustible!',
            saveSuccess: 'La carga de combustible se guardó exitosamente.',
            showFailed: '¡Hubo un error al obtener la carga de combustible!',
            title: 'Cargas de combustible',
        },
        fuelPrice: {
            form: {
                date: {
                    label: 'Fecha',
                },
                fuel_type_id: {
                    label: 'Tipo de combustible',
                },
                price: {
                    label: 'Precio',
                    placeholder: 'Precio',
                },
            },
            grid: {
                columns: {
                    fuel_type_id: 'Tipo de combustible',
                    date: 'Fecha',
                    price: 'Precio',
                },
            },
            list: {
                error: '¡Hubo un error al obtener los precios de combustible!',
            },
            title: 'Precios de combustible',
            saveFailed: '¡Hubo un error al guardar el precio de combustible!',
            saveSuccess: 'El precio de combustible se guardó exitosamente.',
            showFailed: '¡Hubo un error al obtener el precio de combustible!',
        },
        fuelUnit: {
            entity_type: {
                options: {
                    other: 'Otro',
                    trailer: 'Remolque',
                    vehicle: 'Vehículo',
                },
            },
            form: {
                entity_id: {
                    label: 'Unidad',
                },
                entity_type: {
                    label: 'Tipo de unidad',
                },
                expected_efficiency: {
                    label: 'Rendimiento esperado',
                    placeholder: 'Rendimiento esperado',
                },
                fuel_type_id: {
                    label: 'Tipo de combustible',
                },
                name: {
                    label: 'Nombre',
                    placeholder: 'Nombre',
                },
                odometer_unit_code: {
                    label: 'Unidad de medida de odómetro',
                },
            },
            grid: {
                columns: {
                    entity_type: 'Tipo',
                    name: 'Nombre',
                },
            },
            list: {
                error: '¡Hubo un error al obtener las unidades de combustible!',
            },
            saveFailed: '¡Hubo un error al guardar la unidad de combustible!',
            saveSuccess: 'La unidad de combustible se guardó exitosamente.',
            showFailed: '¡Hubo un error al obtener la unidad de combustible!',
            title: 'Unidades de combustible',
        },
        grid: {
            columns: {
                efficiency: 'Rendimiento',
                end_odometer: 'Odómetro final',
                kilometers_distance_traveled: 'Kilómetros recorridos',
                fuel_unit_id: 'Unidad',
                liters: 'Litros',
                liters_consumed: 'Combustible gastado',
                liters_remainder: 'Litros restantes',
                loaded_at: 'Fecha carga',
                miles_distance_traveled: 'Millas recorridas',
                start_odometer: 'Odómetro inicial',
            },
        },
        list: {
            error: '¡Hubo un error al obtener las eficiencias de combustible!',
        },
        title: 'Rendimiento de combustible',
    },
    'gpsService': {
        'getUnitFailed': '¡Hubo un error al obtener la ubicación del vehículo!',
    },
    'grid': {
        'columnMenu': 'Menú de columna',
        'filterAfterOperator': 'Es después',
        'filterAfterOrEqualOperator': 'Es igual o posterior a',
        'filterAndLogic': 'Y',
        'filterAriaLabel': 'Filtrar',
        'filterBeforeOperator': 'Es antes',
        'filterBeforeOrEqualOperator': 'Es anterior o igual a',
        'filterBooleanAll': '(Todos)',
        'filterCheckAll': 'Comprobar todo',
        'filterChooseOperator': 'Filtro',
        'filterClearButton': 'Borrar filtro',
        'filterContainsOperator': 'Contiene',
        'filterEndsWithOperator': 'Termina con',
        'filterEqOperator': 'Es igual a',
        'filterGteOperator': 'Es mayor o igual a',
        'filterGtOperator': 'Es mayor que',
        'filterIsEmptyOperator': 'Está vacío',
        'filterIsFalse': 'Es falso',
        'filterIsNotEmptyOperator': 'No esta vacío',
        'filterIsNotNullOperator': 'No es nulo',
        'filterIsNullOperator': 'Es nulo',
        'filterIsTrue': 'Es verdadero',
        'filterLteOperator': 'Es menor o igual que',
        'filterLtOperator': 'Es menos que',
        'filterNotContainsOperator': 'No contiene',
        'filterNotEqOperator': 'No es igual a',
        'filterOrLogic': 'O',
        'filterSelectedItems': 'elementos seleccionados',
        'filterStartsWithOperator': 'Comienza con',
        'filterSubmitButton': 'Filtrar',
        'filterTitle': 'Filtrar',
        'groupPanelEmpty': 'Arrastre un encabezado de columna y suéltelo aquí para agrupar por esa columna',
        'noRecords': 'No hay registros disponibles.',
        'pagerFirstPage': 'Ir a la primera pagina',
        'pagerInfo': '{0} - {1} de {2} elementos',
        'pagerItemsPerPage': 'elementos por página',
        'pagerLastPage': 'Ir a la última página',
        'pagerNextPage': 'Ir a la página siguiente',
        'pagerOf': 'de',
        'pagerPage': 'Página',
        'pagerPreviousPage': 'Ir a la pagina anterior',
        'pagerTotalPages': '{0}',
        'searchPlaceholder': 'Buscar',
        'sortAriaLabel': 'Ordenable',
        'sortAscending': 'Orden ascendente',
        'sortDescending': 'Orden descendente',
    },
    'gridConfigurations': {
        'errors': {
            'get': 'No se pudo obtener la vista seleccionada',
            'set_default': 'El filtro personalizado no pudo ser establecido como predeterminado.',
        },
        'confirmDelete': {
            'error': 'El filtro personalizado no pudo ser eliminado.',
            'message': '¿Estas seguro que deseas eliminar el filtro personalizado <b>{name}</b>?',
            'success': 'El filtro personalizado fue eliminado con éxito.',
            'title': 'Eliminar filtro personalizado',
        },
        'gridConfigModal': {
            'success': 'El filtro personalizado fue creado con éxito.',
            'title': 'Guardar filtro personalizado',
            'instructions': 'Si existe un filtro personalizado con el mismo nombre, será reemplazado.',
            'name': 'Nombre',
            'errors': {
                'storeConfigurationFailed': '¡No se pudo guardar el filtro personalizado!',
            },
        },
        'List is empty': 'La lista está vacía',
        'No results found': 'No se encontraron resultados',
        'Save filters': 'Guardar',
    },
    'gridExport': {
        'failed': '¡No se pudo generar el archivo!',
        'success': 'Tu consulta se está procesando, espera el resultado en tu correo.',
    },
    'invoice': {
        'addendas': {
            'title': 'Addendas',
            'saveSuccess': '¡Se guardó exitosamente la addenda!',
            'saveFailed': '¡No se pudo guardar la addenda!',
            'getFailed': '¡No se pudo obtener la información de la addenda!',
        },
        'cancel': {
            'title': 'Cancelar CFDI',
            'message': 'El CFDI es {status}. ¿Solicitar cancelación del CFDI {series}-{folio}?',
            'messageNotCancelable': 'El CFDI no es cancelable porque tiene documentos relacionados.',
            'messageNotFound': 'El CFDI no se puede cancelar porque aún no está registrado en el SAT.',
            'queryFailed': '¡Falló la consulta de estatus del CFDI en el SAT! Intente de nuevo.',
            'requestFailed': '¡Falló la solicitud de cancelación!',
            'requestSuccess': 'Se envió la solicitud de cancelación del CFDI.',
            'cancellation_reason': {
                'label': 'Motivo de cancelación',
            },
        },
        'downloadCancellationAcknowledgment': 'Acuse cancelación',
        'edit': 'Editar factura',
        'credit_note': {
            'title': 'Crear nota de crédito',
            'failed': '¡Hubo un error al crear la nota de crédito!',
        },
        'freightDetailModal': {
            'title': 'Flete #{id} {origin} - {destination}',
        },
        'freights': {
            'title': 'Agregar flete',
            'fetchFreightsError': '¡No se pudieron obtener los fletes facturables!',
            'storeError': '¡No se pudo agregar el flete a la factura!',
        },
        'invoiceDetail': 'Detalle factura',
        'invoiceGrid': {
            'columnHeaders': {
                'cfdi_type_id': {'tooltip': 'Tipo de CFDI', 'value': 'Tipo CFDI'},
                'actions': {'tooltip': 'Acciones', 'value': 'Acciones'},
                'cancel_date': {'tooltip': 'Fecha de cancelación', 'value': 'Fecha cancelación'},
                'cancelled_by': {'tooltip': 'Usuario de cancelación', 'value': 'Usuario cancelación'},
                'created_by': {'tooltip': 'Usuario de creación', 'value': 'Usuario creación'},
                'creation_date': {'tooltip': 'Fecha de creación', 'value': 'Fecha creación'},
                'currency_code': {'tooltip': 'Moneda', 'value': 'Moneda'},
                'customer': {'tooltip': 'Razón social', 'value': 'Razón social'},
                'customer_branch': {'tooltip': 'Sucursal', 'value': 'Sucursal'},
                'customer_group': {'tooltip': 'Cliente', 'value': 'Cliente'},
                'document_status': {'tooltip': 'Estatus del documento', 'value': 'Estatus documento'},
                'fiscal_voucher_cancellation_request_status': {
                    'tooltip': 'Estatus de cancelación',
                    'value': 'Estatus cancelación',
                },
                'folio': {'tooltip': 'Folio', 'value': 'Folio'},
                'notification_sent': {'tooltip': 'Notificación enviada', 'value': 'Notificación'},
                'series': {'tooltip': 'Serie', 'value': 'Serie'},
                'stamp_date': {'tooltip': 'Fecha de timbrado', 'value': 'Fecha timbrado'},
                'stamped_by': {'tooltip': 'Usuario de timbrado', 'value': 'Usuario timbrado'},
                'tax_id': {'tooltip': 'RFC', 'value': 'RFC'},
                'total': {'tooltip': 'Total', 'value': 'Total'},
                'voucher_status': {'tooltip': 'Estatus del CFDI', 'value': 'Estatus CFDI'},
            },
        },
        'listFailed': '¡No se pudieron obtener las facturas!',
        'massDownload': {
            'button': 'Descargar CFDIs',
            'failedRequest': '¡No se pudo procesar la petición!',
            'requireVoucherStatusFilter': 'Solamente se pueden descargar CFDIs en estatus timbrado, cancelación pendiente y/o cancelado, aplique filtro e intente nuevamente',
            'successRequest': 'Descarga de CFDIs en proceso espere notificación por correo',
        },
        'nullify': {
            'title': 'Anular factura',
            'message': '¿Desea anular la factura {series}-{folio}?',
        },
        'nullifyFailed': '¡No se pudo anular el CFDI!',
        'replace': {
            'title': 'Sustituir CFDI',
            'message': 'El CFDI es {status}.<br>¿Sustituir y solicitar cancelación del CFDI {series}-{folio}?',
            'messageNotCancelable': 'El CFDI no se puede sustituir porque tiene documentos relacionados.',
            'messageNotFound': 'El CFDI no se puede sustituir porque aún no está registrado en el SAT.',
            'queryFailed': '¡Falló la consulta de estatus del CFDI en el SAT! Intente de nuevo.',
            'requestFailed': '¡Falló la solicitud de sustitución!',
        },
        'replaceVoucherAlternative': {
            'title': 'Sustituir CFDI método alternativo',
            'message': 'Los siguientes folios "{folios}" se cancelaran por motivo 02, favor de confirmar',
            'missingRelatedVouchers': 'El CFDI no se puede sustituir porque no tiene comprobantes previos.',
            'creditNote': 'El documento "{child}" tiene relación con "{parent}" nota de crédito',
            'debitNote': 'El documento "{child}" tiene relación con "{parent}" nota de débito',
            'relatedPayment': 'El documento "{folio}" tiene "{payments}" pagos relacionados',
            'proceedToCancel': 'proceda a cancelar por separado.',
        },
        'stampFailed': '¡Falló la generación del CFDI! Intente de nuevo.',
        'stampRequestSuccess': 'Se solicitó el timbrado del CFDI.',
        'storeFailed': '¡No se pudo crear el CFDI!',
        'title': 'Facturas',
        'tripsPdf': 'Traslados PDF',
        'tripsCsv': 'Traslados CSV',
        'import': {
            'title': 'Importar XML',
            'success': 'Se importó exitosamente la factura.',
            'message': 'Selecciona el XML de la factura a importar',
            'branchMessage': 'Selecciona una sucursal',
            'file': {
                'label': 'Archivo XML',
            },
            'customer': {
                'label': 'Razón Social',
            },
            'customer_branch': {
                'label': 'Sucursal',
            },
        },
    },
    'invoiceBalanceAging': {
        'title': 'Consultas - Antigüedad saldos factura',
        'grid': {
            'columnHeaders': {
                'customer_branch': {
                    'tooltip': 'Sucursal',
                    'value': 'Sucursal',
                },
                'MXN-D-<31': {
                    'tooltip': '<31 DÍAS POR VENCER (MXN)',
                    'value': '<31',
                },
                'MXN-D-31-60': {
                    'tooltip': '31-60 DÍAS POR VENCER (MXN)',
                    'value': '31-60',
                },
                'MXN-D-61-90': {
                    'tooltip': '61-90 DÍAS POR VENCER (MXN)',
                    'value': '61-90',
                },
                'MXN-D-91-120': {
                    'tooltip': '91-120 DÍAS POR VENCER (MXN)',
                    'value': '91-120',
                },
                'MXN-D->120': {
                    'tooltip': '>120 DÍAS POR VENCER (MXN)',
                    'value': '>120',
                },
                'MXN-O-<31': {
                    'tooltip': '<31 DÍAS VENCIDO (MXN)',
                    'value': '<31',
                },
                'MXN-O-31-60': {
                    'tooltip': '31-60 DÍAS VENCIDO (MXN)',
                    'value': '31-60',
                },
                'MXN-O-61-90': {
                    'tooltip': '61-90 DÍAS VENCIDO (MXN)',
                    'value': '61-90',
                },
                'MXN-O-91-120': {
                    'tooltip': '91-120 DÍAS VENCIDO (MXN)',
                    'value': '91-120',
                },
                'MXN-O->120': {
                    'tooltip': '>120 DÍAS VENCIDO (MXN)',
                    'value': '>120',
                },
                'USD-D-<31': {
                    'tooltip': '<31 DÍAS POR VENCER (USD)',
                    'value': '<31',
                },
                'USD-D-31-60': {
                    'tooltip': '31-60 DÍAS POR VENCER (USD)',
                    'value': '31-60',
                },
                'USD-D-61-90': {
                    'tooltip': '61-90 DÍAS POR VENCER (USD)',
                    'value': '61-90',
                },
                'USD-D-91-120': {
                    'tooltip': '91-120 DÍAS POR VENCER (USD)',
                    'value': '91-120',
                },
                'USD-D->120': {
                    'tooltip': '>120 DÍAS POR VENCER (USD)',
                    'value': '>120',
                },
                'USD-O-<31': {
                    'tooltip': '<31 DÍAS VENCIDO (USD)',
                    'value': '<31',
                },
                'USD-O-31-60': {
                    'tooltip': '31-60 DÍAS VENCIDO (USD)',
                    'value': '31-60',
                },
                'USD-O-61-90': {
                    'tooltip': '61-90 DÍAS VENCIDO (USD)',
                    'value': '61-90',
                },
                'USD-O-91-120': {
                    'tooltip': '91-120 DÍAS VENCIDO (USD)',
                    'value': '91-120',
                },
                'USD-O->120': {
                    'tooltip': '>120 DÍAS VENCIDO (USD)',
                    'value': '>120',
                },
            },
        },
    },
    'invoicesByVehicle': {
        'groupBy': 'Agrupar',
        'grid': {
            'columnHeaders': {
                'bill_of_lading': {
                    'tooltip': 'Guía de carga',
                    'value': 'BOL',
                },
                'coordinate': {
                    'tooltip': 'Coordenada',
                    'value': 'Coord',
                },
                'currency': {
                    'tooltip': 'Moneda',
                    'value': 'Moneda',
                },
                'customer_branch': {
                    'tooltip': 'Sucursal',
                    'value': 'Sucursal',
                },
                'customer_group': {
                    'tooltip': 'Cliente',
                    'value': 'Cliente',
                },
                'destination': {
                    'tooltip': 'Destino',
                    'value': 'Destino',
                },
                'discount': {
                    'tooltip': 'Descuento',
                    'value': 'Dto',
                },
                'driver': {
                    'tooltip': 'Conductor',
                    'value': 'Conductor',
                },
                'exchange_rate': {
                    'tooltip': 'Tipo de cambio',
                    'value': 'TC',
                },
                'freight_id': {
                    'tooltip': 'Flete',
                    'value': 'Flete',
                },
                'freight_status': {
                    'tooltip': 'Estatus de flete',
                    'value': 'Estatus flete',
                },
                'freight_type': {
                    'tooltip': 'Tipo de flete',
                    'value': 'Tipo flete',
                },
                'invoice': {
                    'tooltip': 'Factura',
                    'value': 'Factura',
                },
                'iva_08': {
                    'tooltip': 'IVA 8%',
                    'value': 'IVA 8%',
                },
                'iva_16': {
                    'tooltip': 'IVA 16%',
                    'value': 'IVA 16%',
                },
                'origin': {
                    'tooltip': 'Origen',
                    'value': 'Origen',
                },
                'ret_04': {
                    'tooltip': 'Ret 4%',
                    'value': 'Ret 4%',
                },
                'stamp_date': {
                    'tooltip': 'Fecha factura',
                    'value': 'Fecha fac',
                },
                'started_at': {
                    'tooltip': 'Fecha de arranque',
                    'value': 'Arranque',
                },
                'subtotal': {
                    'tooltip': 'Subtotal',
                    'value': 'Subtotal',
                },
                'total': {
                    'tooltip': 'Total',
                    'value': 'Total',
                },
                'trailer': {
                    'tooltip': 'Remolque',
                    'value': 'Remolque',
                },
                'type': {
                    'tooltip': 'Tipo de movimiento',
                    'value': 'Tipo',
                },
                'uuid': {
                    'tooltip': 'UUID',
                    'value': 'UUID',
                },
                'vehicle': {
                    'tooltip': 'Vehículo',
                    'value': 'Vehículo',
                },
            },
            'groupCellRenderer': {
                'totalInvoiceMxn': 'Total MXN',
                'totalInvoiceUsd': 'Total USD',
            },
        },
        'title': 'Consultas - Facturación de servicios',
    },
    'invoicesReceivable': {
        branch: 'Sucursal',
        email: {
            default_subject: 'Estado de cuenta del cliente {customer_group_name} {customer_branch_name} corte al {date}',
        },
        mail_button: 'Enviar por correo',
        sent_mail_success: 'El reporte se envió por correo electrónico con éxito.',
        show: {
            failure: '¡Hubo un error al generar el reporte de facturas por cobrar!',
        },
        title: 'Consultas - Facturas por cobrar',
    },
    'invoicingVoucher': {
        'cancel': {
            'title': 'Cancelar CFDI',
            'message': 'El CFDI es {status}.<br>¿Solicitar cancelación del CFDI {series}-{folio}?',
            'messageNotCancelable': 'El CFDI no es cancelable.',
            'messageNotFound': 'El CFDI no se puede cancelar porque aún no está registrado en el SAT.',
            'queryFailed': '¡Falló la consulta de estatus del CFDI en el SAT! Intente de nuevo.',
            'failure': '¡No se pudo cancelar el CFDI!',
            'retry': {
                'message': '¿Desea reintentar la cancelación del comprobante fiscal {series}-{folio}?',
                'title': 'Reintentar cancelación',
                'storeFailed': '¡No se pudo reintentar la cancelación del CFDI!',
            },
            'requestSuccess': 'Se envió la solicitud de cancelación del CFDI.',
        },
        'replace': {
            'title': 'Sustituir CFDI',
            'message': 'El CFDI es {status}.<br>¿Sustituir y solicitar cancelación del CFDI {series}-{folio}?',
            'messageNotCancelable': 'El CFDI no es cancelable.',
            'messageNotFound': 'El CFDI no se puede sustituir porque aún no está registrado en el SAT.',
            'requestFailed': '¡Falló la solicitud de sustitución!',
        },
    },
    issuer: {
        currentFailed: '¡Hubo un error al obtener los datos del transportista!',
        digitalSealCertificate: {
            grid: {
                columns: {
                    certificate_serial: 'No. certificado',
                    created_at: 'Creado',
                    end_date: 'Fecha fin',
                    start_date: 'Fecha inicio',
                    subject_id: 'Identificador',
                    subject_name: 'Nombre',
                    updated_at: 'Actualizado',
                },
            },
            list: {
                error: '¡Hubo un error al obtener los certificados de sello digital!',
            },
            modal: {
                fields: {
                    certificate_file: {
                        label: 'Certificado sello digital',
                    },
                    private_key_file: {
                        label: 'Clave privada',
                    },
                    private_key_password: {
                        label: 'Contraseña clave privada',
                    },
                },
                saveFailed: '¡Hubo un error al guardar el certificado de sello digital!',
                title: 'Guardar certificado de sello digital',
            },
        },
        downloadTemplate: {
            buttonTitle: 'Plantillas',
            failed: '¡Hubo un error al descargar la plantilla!',
            catalogFiles: {
                bank_accounts: 'Cuentas bancarias',
                invoicing_item_types: 'Conceptos',
                users: 'Usuarios',
            },
        },
        fields: {
            discount_journal_account: {
                label: 'Cuenta contable descuento',
            },
            fiscal_regime_code: {
                label: 'Regimen fiscal',
            },
            issuance_place: {
                label: 'Lugar de emisión',
            },
            legal_name: {
                label: 'Razón social',
            },
            postal_code: {
                label: 'Código postal',
            },
            tax_id: {
                label: 'RFC',
            },
        },
        grid: {
            columns: {
                actions: 'Acciones',
                created_at: 'Creado',
                fiscal_regime_code: 'Régimen fiscal',
                legal_name: 'Razón social',
                postal_code: 'Código postal',
                tax_id: 'RFC',
                updated_at: 'Actualizado',
            },
        },
        importModal: {
            fileUnidentified: '¡El catálogo no se pudo identificar!',
        },
        list: {
            error: '¡Hubo un error al obtener las configuraciones de transportistas!',
        },
        saveFailed: '¡Hubo un error al guardar los datos de la configuración del transportista!',
        saveSuccess: '¡Se guardó exitosamente la configuración del transportista!',
        showFailed: '¡No se pudo obtener la configuración del transportista!',
        tabs: {
            catalogs: 'Catálogos',
            digital_seal_certificates: 'Certificados',
            information: 'Información',
        },
        title: 'Configuraciones de transportista',
    },
    'journalAccount': {
        'create': {
            'title': 'Crear cuenta contable',
        },
        downloadTemplate: {
            failed: '¡Hubo un error al descargar la plantilla de cuentas contables!',
            title: 'Descargar plantilla de cuentas contables',
        },
        'edit': {
            'title': 'Editar cuenta contable',
        },
        'fields': {
            'number': {
                'label': 'Número de cuenta',
                'validationError': 'El número de cuenta es obligatorio',
            },
            'customers': {
                'label': 'Razón social',
            },
            'customer_branches': {
                'label': 'Sucursal',
            },
            'journal_account_type_id': {
                'label': 'Tipo de cuenta contable',
            },
            'description': {
                'label': 'Descripción',
                'validationError': 'La descripción debe contener entre 1 y 255 caracteres',
            },
        },
        'title': 'Cuentas contables',
        'grid': {
            'number': 'Número de cuenta',
            'journal_account_type_id': 'Tipo de cuenta',
            'created_at': 'Creado el',
            'created_by': 'Creado por',
            'updated_at': 'Actualizado el',
            'updated_by': 'Actualizado por',
            'description': 'Descripción',
            'relations_count': 'Número de relaciones',
            'actions': 'Acciones',
        },
        'saveSuccess': '¡Se guardó exitosamente la cuenta contable!',
        'saveFailed': '¡No se pudo guardar la cuenta contable!',
    },
    'journalVoucherExtract': {
        'fields': {
            'name': {
                'label': 'Nombre',
                'validationError': 'El nombre debe contener entre 1 y 255 caracteres',
            },
            'start_date': {
                'label': 'Fecha inicio',
            },
            'end_date': {
                'label': 'Fecha término',
            },
            'filters': {
                'sequence_type': {
                    'label': 'Tipo de secuencia',
                },
                'base_sequence_number': {
                    'label': 'Número de secuencia',
                    'validationError': 'El número de secuencia debe ser mayor o igual que 1',
                },
            },
        },
        'subtitles': {
            'generalInformation': 'Datos Generales',
            'filters': 'Filtros',
        },
        'title': 'Extractos',
        'saveSuccess': '¡Se guardó exitosamente el extracto!',
        'saveFailed': '¡No se pudo guardar el extracto!',
    },
    'ladingBill': {
        'cancel': {
            'title': 'Cancelar carta de porte',
            'message': '¿Desea cancelar la carta de porte <b>{series} - {folio}</b>?',
        },
        'error': {
            'title': 'Error de Timbrado',
            'message': '<b>{error_message}</b><br>¿Desea editar la factura?',
            'failed': '¡Falló la modificación del estatus de la factura!',
            'fetchFailed': '¡No se pudo obtener el último error de timbrado!',
        },
        'ladingBillForm': {
            'declared_value': 'Valor declarado',
            'delay_compensation': 'Indemnización por demora',
            'delivery_term': 'Fecha/Plazo de entrega',
            'destination': 'Destino',
            'destination_address': 'Domicilio de destino',
            'drop_off_location': 'Se entregará en',
            'is_hazardous': 'Material/Residuo peligroso',
            'items': 'Cargas',
            'origin': 'Origen',
            'origin_address': 'Domicilio de origen',
            'pick_up_location': 'Se recogerá en',
            'recipient': 'Destinatario',
            'sender': 'Remitente',
            'services': 'Servicios',
        },
        'ladingBillItemGrid': {
            'columnHeaders': {
                'actions': {
                    'tooltip': 'Botones de acciones',
                    'value': 'Acciones',
                },
                'description': {
                    'tooltip': 'Descripción',
                    'value': 'Descripción',
                },
                'packaging': {
                    'tooltip': 'Embalaje',
                    'value': 'Embalaje',
                },
                'weight': {
                    'tooltip': 'Peso',
                    'value': 'Peso',
                },
            },
            'add': 'Agregar carga',
        },
        'ladingBillServiceGrid': {
            'addService': 'Agregar servicio',
            'columnHeaders': {
                'actions': {
                    'value': 'Acciones',
                    'tooltip': 'Acciones',
                },
                'measurement_unit_code': {
                    'value': 'Unidad de medida',
                    'tooltip': 'Unidad de medida',
                },
                'product_service_code': {
                    'value': 'Clave Producto/Servicio',
                    'tooltip': 'Clave Producto/Servicio',
                },
                'description': {
                    'value': 'Descripción',
                    'tooltip': 'Descripción',
                },
            },
        },
        'ladingBillTable': {
            'headers': {
                'actions': 'Acciones',
                'folio': 'Folio',
                'series': 'Serie',
                'status': 'Estatus',
            },
        },
        'ladingBillTableActions': {
            'generatingPDF': 'Generando PDF',
            'inCancellationProcess': 'En proceso de cancelación',
            'inStampingProcess': 'En proceso de timbrado',
        },
        'downloadFailed': '¡No se pudo descargar la carta de porte!',
        'emptyLadingBills': 'No existen cartas de porte.',
        'fetchProductServiceFailed': 'No se pudo leer la descripción del producto/servicio.',
        'filename': 'Carta de porte',
        'getFreightLocalitiesFailed': 'No se pudieron obtener las localidades del flete',
        'loadFailed': 'No se pudo cargar la carta de porte.',
        'saveFailed': '¡No se pudo guardar la carta de porte!',
        'saveSuccess': 'Se solicitó el timbrado de la carta de porte.',
        'stampLadingBillFailed': '¡No se pudo timbrar la carta de porte!',
        'storeFailed': '¡No se pudo crear el CFDI de traslado!',
        'title': 'Carta de porte',
        'vehicleConfiguration': 'Config. Vehicular',
    },
    'ladingBillModal': {
        'createLadingBill': {
            'label': 'Traslado',
            'title': 'Relacionar CFDI de traslado',
            'message': '¿Relacionar CFDI de traslado a CFDI {series}-{folio}?',
        },
        'ladingBillTable': {
            actions: {
                label: 'Acciones',
                download_lading_bill: {
                    label: 'CCP PDF',
                    title: 'PDF Complemento Carta Porte',
                },
            },
            'cfdi_type_id': 'Tipo',
            'fiscal_folio': 'Folio fiscal',
            'folio': 'Folio',
            'idCCP': 'ID CCP',
            'series': 'Serie',
            'stamp_date': 'Fecha',
            'uuid': 'UUID',
            'voucher_status': 'Estatus',
        },
        'invalidVehicleConfiguration': 'Asignar configuración vehicular válida.',
        'title': 'Carta de porte flete #{freight_id} - {reference}',
        'fiscalVoucherTitle': 'CFDI flete #{freight_id} - {reference}',
        'fiscalVoucherTitleWithoutReference': 'CFDI flete #{freight_id}',
        'previewCfdi': 'Vista previa PDF',
        'vehicleConfigurationsFailed': '¡Hubo un error al obtener las configuraciones vehiculares!',
        vehicleGrossWeight: {
            label: 'Peso bruto vehicular (Tn)',
            validationError: 'El peso bruto vehicular debe tener 2 decimales y ser mayor o igual que 0.01.',
        },
        internationalTransportType: {
            label: 'Tipo transporte internacional',
            validationError: 'El tipo de transporte internacional del flete ({freight_international_transport_type}) no es igual que el archivo de mercancías ({service_request_international_transport_type}).',
        },
    },
    'ladingBillStop': {
        'stop': 'Parada',
        'grid': {
            'columnHeaders': {
                'distance': {
                    'value': 'Distancia (km)',
                    'tooltip': 'Distancia en kilómetros',
                },
                'estimated_arrival': {
                    'value': 'Hora llegada',
                    'tooltip': 'Hora estimada llegada',
                },
                'estimated_departure': {
                    'value': 'Hora salida',
                    'tooltip': 'Hora estimada salida',
                },
                'locality_id': {
                    'value': 'Localidad',
                    'tooltip': 'Localidad',
                },
                'location': {
                    'value': 'Ubicación',
                    'tooltip': 'Ubicación',
                },
                'location_type': {
                    'value': 'Tipo',
                    'tooltip': 'Tipo',
                },
                'refresh_distance': {
                    'value': 'Actualizar distancia',
                    'tooltip': 'Actualizar distancia',
                },
                'subject': {
                    'value': 'Remitente/Destinatario',
                    'tooltip': 'Remitente/Destinatario',
                },
                'tax_id': {
                    'value': 'ID fiscal',
                    'tooltip': 'ID fiscal',
                },
                'tax_residence': {
                    'value': 'País',
                    'tooltip': 'País de residencia fiscal',
                },
            },
            locationsDistance: {
                storeFailed: '¡Hubo un error al guardar la distancia!',
            },
        },
    },
    'loadTender': {
        'getEdiLocationsFailed': '¡Ocurrió un error al obtener los datos de las localidades EDI de la oferta de carga!',
        'grid': {
            'actions': {
                'accept': 'Aceptar',
                'reject': 'Rechazar',
                'view': 'Ver detalle',
            },
            'columns': {
                'actions': 'Acciones',
                'customer': 'Cliente',
                'destination': 'Destino',
                'destination_date_end': 'Hasta',
                'destination_date_start': 'Desde',
                'freight_id': '# Flete',
                'freight_status': 'Estatus flete',
                'origin': 'Origen',
                'origin_date_end': 'Hasta',
                'origin_date_start': 'Desde',
                'purpose_code': 'Propósito',
                'service_date': 'Fecha',
                'shipment_id': 'Referencia',
                'stop_offs': 'Paradas',
            },
        },
        'loadTenderResponse': {
            '01': {
                'A': 'Aceptar solicitud de cancelación de oferta de carga',
                'D': 'Rechazar solicitud de cancelación de oferta de carga',
            },
            'charactersNotAllowed': 'Los observaciones no pueden contener los siguientes caracteres: > ~ ^ *',
            'charactersRemaining': 'caracteres restantes',
            'customerGroup': 'Cliente',
            'fetchFreightFailed': '¡No se pudo cargar el flete!',
            'freightId': 'Flete',
            'rejectSuccess': '¡La oferta de carga fue rechazada exitosamente!',
            'remarks': 'Agregar observaciones (Opcional)',
            'saveFailed': '¡No se pudo responder la oferta de carga!',
            'saveSuccess': 'La oferta de carga se respondió exitosamente.',
            'shipmentId': 'Guía de carga',
            'title': 'Responder oferta de carga',
        },
        'showFailed': '¡Ocurrió un error al obtener los datos de la oferta de carga!',
        'stop_offs': {
            'headerColumns': {
                'address': 'Dirección',
                'locality': 'Localidad',
                'sequence': '# Parada',
            },
            'listLocalitiesError': '¡No se pudieron obtener las localidades del cliente!',
            'locality_id': 'Localidad',
            'storeFailed': '¡Ocurrió un error al guardar la localidad de EDI!',
        },
        'title': 'Oferta de carga',
        'updateFailed': '¡Ocurrió un error al actualizar la oferta de carga!',
        'window': {
            'accept': 'La oferta de carga {reference} se aceptó exitosamente con el flete #{freight_id}',
            'title': 'Ofertas de carga pendientes',
            'viewModelFail': 'No se pudieron cargar los catálogos.',
        },
    },
    loadTenders: {
        grid: {
            actions: {
                accept: 'Aceptar',
                reject: 'Rechazar',
                view: 'Ver detalle',
            },
            columns: {
                actions: 'Acciones',
                customer: 'Cliente',
                destination: 'Descargar en',
                destination_date_end: 'Hasta',
                destination_date_start: 'Desde',
                freight_id: 'Flete',
                freight_status: 'Estatus flete',
                origin: 'Recolectar en',
                origin_date_end: 'Hasta',
                origin_date_start: 'Desde',
                purpose_code: 'Propósito',
                service_date: 'Fecha',
                shipment_id: 'Referencia',
                status: 'Estatus oferta carga',
                status_update: 'Actualización estatus',
                stop_offs: 'Paradas',
                trailer_name: 'Remolque',
                vehicle_name: 'Vehículo',
            },
        },
        list: {
            error: '¡Hubo un error al obtener las ofertas de carga!',
        },
        loadTenderHistory: {
            grid: {
                actions: {
                    functional_acknowledgement: {
                        title: 'Descargar EDI 997',
                        label: 'EDI 997',
                    },
                    load_tender: {
                        title: 'Descargar EDI 204',
                        label: 'EDI 204',
                    },
                    load_tender_response: {
                        title: 'Descargar EDI 990',
                        label: 'EDI 990',
                    },
                },
                columns: {
                    actions: 'Acciones',
                    acknowledged_at: 'Fecha Resp EDI 997',
                    answered_by: 'Respondido por',
                    created_at: 'Fecha Recep EDI 204',
                    freight_id: '# Flete',
                    purpose_code: 'Propósito',
                    response_date: 'Fecha Resp EDI 990',
                    shipment_id: 'Referencia',
                    status: 'Estatus',
                },
            },
            listFailed: '¡Hubo un error al obtener el historial de ofertas de carga!',
            tabs: {
                content: 'Contenido Oferta de carga',
                grid: 'Historial ofertas de carga',
            },
        },
        loadTenderResponse: {
            saveFailed: '¡No se pudo responder la oferta de carga!',
            saveSuccess: 'La oferta de carga se respondió exitosamente.',
        },
        loadTenderStopOffsModal: {
            title: 'Relacionar localidades EDI',
        },
        show: {
            title: 'Detalle de oferta de carga',
        },
        title: 'Ofertas de carga',
    },
    'loadTenderDetail': {
        'getDetail': {
            'error': '¡No se pudo procesar la petición!',
        },
        'actual': 'actual',
        'Customer': 'Cliente',
        'Load tender Id': 'Identificador de oferta de carga',
        'Shipment Id': 'Identificador de envío',
        'Version': 'Versión',
    },
    'loadTenderGrid': {
        'Loading': 'Cargando...',
        'columnHeaders': {
            'testing': {
                'value': 'Prueba',
                'tooltip': 'Modalidad de prueba',
            },
            'loadTenderId': {
                'value': 'ID de oferta',
                'tooltip': 'ID de oferta',
            },
            'shipmentId': {
                'value': 'Clave del remitente',
                'tooltip': 'Clave del remitente',
            },
            'version': {
                'value': 'Versión',
                'tooltip': 'Versión',
            },
            'customer': {
                'value': 'Cliente',
                'tooltip': 'Cliente',
            },
            'serviceDate': {
                'value': 'Fecha de servicio',
                'tooltip': 'Fecha de servicio',
            },
            'status': {
                'value': 'Estatus',
                'tooltip': 'Estatus',
            },
            'origin': {
                'value': 'Origen',
                'tooltip': 'Origen',
            },
            'originDateStart': {
                'value': 'Desde',
                'tooltip': 'Desde',
            },
            'originDateEnd': {
                'value': 'Hasta',
                'tooltip': 'Hasta',
            },
            'stopOffs': {
                'value': 'Paradas',
                'tooltip': 'Paradas',
            },
            'destination': {
                'value': 'Destino',
                'tooltip': 'Destino',
            },
            'destinationDateStart': {
                'value': 'Desde',
                'tooltip': 'Desde',
            },
            'destinationDateEnd': {
                'value': 'Hasta',
                'tooltip': 'Hasta',
            },
            'actions': {
                'value': 'Acciones',
                'tooltip': 'Acciones',
            },
        },
        'actionButtonsResponse': {
            'success': 'Se procesó con éxito la petición.',
            'error': '¡No se pudo procesar la petición!',
        },
    },
    'locality': {
        'assignmentModal': {
            'customerAssignment': {
                'error': '¡No se pudieron asignar los clientes!',
                'saveSuccess': '¡Se guardaron las cambios Localidad-Clientes exitosamente!',
                'title': 'Clientes de {locality}',
            },
            'geofenceAssignment': {
                'error': '¡No se pudieron asignar las geocercas!',
                'saveSuccess': '¡Se guardaron las cambios Localidad-Geocercas exitosamente!',
                'title': 'Geocercas de {locality}',
            },
        },
        'createModal': {
            'title': 'Crear localidad',
        },
        downloadTemplate: {
            failed: '¡Hubo un error al descargar la plantilla de localidades!',
            title: 'Descargar plantilla de localidades',
        },
        'editModal': {
            'title': 'Editar localidad',
        },
        'fields': {
            'addressLine1': {
                'label': 'Calle',
                'placeholder': 'Calle',
                'validationError': 'La calle debe contener 100 caracteres como máximo.',
            },
            'city': {
                'label': 'Localidad',
                'placeholder': 'Localidad',
            },
            'country': {
                'label': 'País',
                'placeholder': 'País',
            },
            'exteriorNumber': {
                'label': 'Número exterior',
                'placeholder': 'Número exterior',
            },
            'geoState': {
                'label': 'Estado',
                'placeholder': 'Estado',
            },
            'interiorNumber': {
                'label': 'Número interior',
                'placeholder': 'Número interior',
            },
            'localityTypeId': {
                'label': 'Tipo',
            },
            'municipality': {
                'label': 'Municipio',
                'placeholder': 'Municipio',
            },
            'name': {
                'label': 'Nombre',
                'placeholder': 'Nombre',
                'validationError': 'El nombre debe contener entre 3 y 50 caracteres.',
            },
            'postalCode': {
                'label': 'Código postal',
                'placeholder': 'Código postal',
                'validationError': 'El campo código postal es obligatorio.',
            },
            'reference': {
                'label': 'Referencia',
                'placeholder': 'Referencia',
            },
            'settlement': {
                'label': 'Colonia',
                'placeholder': 'Colonia',
            },
        },
        'grid': {
            'columnHeaders': {
                'actions': {
                    'tooltip': 'Acciones',
                    'value': 'Acciones',
                },
                'address_line_1': {
                    'tooltip': 'Calle',
                    'value': 'Calle',
                },
                'city': {
                    'tooltip': 'Localidad',
                    'value': 'Localidad',
                },
                'country': {
                    'tooltip': 'País',
                    'value': 'País',
                },
                'exterior_number': {
                    'tooltip': 'Número exterior',
                    'value': 'Núm. ext.',
                },
                'geo_state': {
                    'tooltip': 'Estado',
                    'value': 'Estado',
                },
                'interior_number': {
                    'tooltip': 'Número interior',
                    'value': 'Núm. int.',
                },
                'is_active': {
                    'tooltip': 'Estatus',
                    'value': 'Estatus',
                },
                'locality_type_id': {
                    'tooltip': 'Tipo de localidad',
                    'value': 'Tipo',
                },
                'municipality': {
                    'tooltip': 'Municipio',
                    'value': 'Municipio',
                },
                'name': {
                    'tooltip': 'Nombre',
                    'value': 'Nombre',
                },
                'postal_code': {
                    'tooltip': 'Código postal',
                    'value': 'CP',
                },
                'reference': {
                    'tooltip': 'Referencia',
                    'value': 'Referencia',
                },
                'settlement': {
                    'tooltip': 'Colonia',
                    'value': 'Colonia',
                },
            },
            'tooltip': {
                'customerAssignment': 'Asignación de clientes',
                'edit': 'Editar',
                'geofenceAssignment': 'Asignación de geocercas',
            },
        },
        'saveFailed': '¡No se pudo guardar la localidad!',
        'saveSuccess': '¡Se guardó exitosamente la localidad!',
        'title': 'Localidades',
        updateGeofences: {
            getCatalogFailed: '¡Hubo un error al refrescar el catálogo de geocercas!',
            label: 'Actualizar geocercas',
            updateFailed: '¡Hubo un error al actualizar las geocercas!',
            updateSuccess: 'Geocercas importadas: {imported}, creadas: {created}, activadas: {activated}, desactivadas: {deactivated}.',
        },
        'updateFailed': '¡No se pudo actualizar la localidad!',
    },
    'localityType': {
        'originDestination': {
            'long': 'Origen/Destino',
            'short': 'Or/De',
        },
        'borderCrossing': {
            'long': 'Cruce fronterizo',
            'short': 'Cruce',
        },
    },
    'navBar': {
        feedback: {
            technicalSupport: 'Asistencia técnica',
        },
        'loadTenders': 'Ofertas de carga pendientes',
        'logIn': 'Iniciar sesión',
        'logOut': 'Cerrar sesión',
        'mainNavigation': 'Navegación principal',
        'menu': {
            accounting: {
                journalVouchers: 'Pólizas',
                label: 'Contabilidad',
            },
            accounts_receivable: {
                label: 'Cuentas por cobrar',
                payments: 'Pagos',
            },
            accounts_payable: {
                label: 'Cuentas por pagar',
                coordinatedLiquidations: 'Cédulas de liquidación',
            },
            administration: {
                label: 'Administración',
            },
            billing: {
                billingDocuments: 'Documentos de facturación',
                coordinatedFiscalVouchers: 'Liquidaciones',
                label: 'Facturación',
            },
            'catalogs': {
                'bankAccounts': 'Cuentas bancarias',
                'coordinatedRoutes': 'Rutas de coordinados',
                'customers': 'Clientes',
                'customerVariants': 'Cotizaciones',
                'drivers': 'Conductores',
                'exchangeRates': 'Tipo de cambio',
                'freightTemplates': 'Plantillas de flete',
                'freightTripTypes': 'Tipos de movimiento',
                'freightTypes': 'Tipos de flete',
                'invoicingItemTypes': 'Conceptos',
                'issuers': 'Configuración de transportista',
                'journalAccounts': 'Cuentas contables',
                'label': 'Catálogos',
                'localities': 'Localidades',
                'roles': 'Roles',
                'taxRates': 'Tasas/Cuotas de impuesto',
                trailerOwners: 'Propietarios',
                'trailers': 'Remolques',
                'users': 'Usuarios',
                'vehicles': 'Vehículos',
                'vehicleInsurances': 'Seguros vehiculares',
                'vehiclePermits': 'Permisos SCT',
                'voucherFolioSeries': 'Series de folios',
            },
            fiscalVoucher: {
                label: 'Facturación',
            },
            fuel_efficiency: {
                fuelLoads: 'Cargas de combustible',
                fuelPrices: 'Precios de combustible',
                fuelUnits: 'Unidades de combustible',
                label: 'Combustible',
                queries: {
                    dailyFuelConsumed: 'Consumo diario de combustible',
                    fuelConsumed: 'Consumo de combustible',
                    fuelEfficiencies: 'Rendimiento',
                    label: 'Consultas',
                },
            },
            'invoices': 'Facturas',
            'label': 'Menú',
            'query': {
                'accountStatement': 'Estado de cuenta',
                'customerAccountStatement': 'Estado de cuenta clientes',
                'dailyBilling': 'Cobranza diaria',
                'freightTripsByVehicle': 'Movimientos',
                'freightTripsPerInvoice': 'Facturas por flete',
                'invoiceBalanceAging': 'Antigüedad saldos facturas',
                'invoicePayments': 'Pagos facturas',
                'invoicesByVehicle': 'Facturación de servicios',
                'invoicesReceivable': 'Facturas por cobrar',
                'label': 'Consultas',
            },
            'reports': 'Reportes',
            'traffic': {
                'freightMovements': 'Movimientos',
                'freights': 'Fletes',
                'loadTenders': 'Ofertas de carga',
                'label': 'Tráfico',
                'serviceRequestFiles': 'Archivos de mercancías',
            },
        },
    },
    'notExceedAvailableBalanceOfTheInvoice': 'No exceder el balance disponible de la factura',
    notifications: {
        markAsRead: {
            fails: '¡Hubo un error al marcar la notificación como leida!',
        },
    },
    'notIndicated': {
        'long': '(No indicado)',
        'short': '(NI)',
    },
    'origin': {
        'label': 'Origen',
    },
    'payment': {
        'cancel': {
            'requestFailed': '¡Hubo un error al cancelar el pago!',
        },
        'createWithCep': 'Crear con CEP',
        'nullify': {
            'title': 'Anular pago',
            'message': '¿Desea anular el pago {series}-{folio}?',
        },
        'paymentGrid': {
            'columnHeaders': {
                'actions': {'tooltip': 'Acciones', 'value': 'Acciones'},
                'cancel_date': {'tooltip': 'Fecha de cancelación', 'value': 'Fecha cancelación'},
                'cancelled_by': {'tooltip': 'Usuario de cancelación', 'value': 'Usuario cancelación'},
                'created_by': {'tooltip': 'Usuario de creación', 'value': 'Usuario creación'},
                'creation_date': {'tooltip': 'Fecha de creación', 'value': 'Fecha creación'},
                'customer': {'tooltip': 'Razón social', 'value': 'Razón social'},
                'customer_branch': {'tooltip': 'Sucursal', 'value': 'Sucursal'},
                'customer_group': {'tooltip': 'Cliente', 'value': 'Cliente'},
                'document_status': {'tooltip': 'Estatus del documento', 'value': 'Estatus documento'},
                'folio': {'tooltip': 'Folio', 'value': 'Folio'},
                'series': {'tooltip': 'Serie', 'value': 'Serie'},
                'stamp_date': {'tooltip': 'Fecha de timbrado', 'value': 'Fecha timbrado'},
                'stamped_by': {'tooltip': 'Usuario de timbrado', 'value': 'Usuario timbrado'},
                'tax_id': {'tooltip': 'RFC', 'value': 'RFC'},
                'voucher_status': {'tooltip': 'Estatus del CFDI', 'value': 'Estatus CFDI'},
            },
            'downloadCancellationAcknowledgment': 'Acuse cancelación',
        },
        'title': 'Pagos',
        'unhandledGridAction': '¡Acción de grid no manejada!',
        'viewModelFailed': '¡Hubo un error al obtener los catálogos!',
    },
    queries: {
        accountStatement: {
            grid: {
                columnHeaders: {
                    antiquity_code: {
                        tooltip: 'Antigüedad',
                        value: 'Antigüedad',
                    },
                    antiquity_status: {
                        tooltip: 'Estatus de vencimiento',
                        value: 'Estatus vencimiento',
                    },
                    balance: {
                        tooltip: 'Balance',
                        value: 'Balance',
                    },
                    creation_date: {
                        tooltip: 'Fecha de emisión',
                        value: 'Emisión',
                    },
                    credit_days: {
                        tooltip: 'Días de crédito',
                        value: 'Días crédito',
                    },
                    credit_note_applied: {
                        tooltip: 'Notas de crédito aplicadas',
                        value: 'Crédito',
                    },
                    currency_code: {
                        tooltip: 'Moneda',
                        value: 'Moneda',
                    },
                    customer: {
                        tooltip: 'Razón social',
                        value: 'Razón social',
                    },
                    customer_branch: {
                        tooltip: 'Sucursal',
                        value: 'Sucursal',
                    },
                    customer_group: {
                        tooltip: 'Cliente',
                        value: 'Cliente',
                    },
                    days_passed: {
                        tooltip: 'Días transcurridos',
                        value: 'Días transcurridos',
                    },
                    due_date: {
                        tooltip: 'Fecha de vencimiento',
                        value: 'Fecha vencimiento',
                    },
                    expired_days: {
                        tooltip: 'Días vencidos',
                        value: 'Días vencidos',
                    },
                    invoice: {
                        tooltip: 'Factura',
                        value: 'Factura',
                    },
                    iva_08: {
                        tooltip: 'IVA 8%',
                        value: 'IVA 8%',
                    },
                    iva_16: {
                        tooltip: 'IVA 16%',
                        value: 'IVA 16%',
                    },
                    payment_applied: {
                        tooltip: 'Total pagado',
                        value: 'Pagado',
                    },
                    ret_04: {
                        tooltip: 'Ret 4%',
                        value: 'Ret 4%',
                    },
                    subtotal: {
                        tooltip: 'Subtotal',
                        value: 'Subtotal',
                    },
                    tax_id: {
                        tooltip: 'RFC',
                        value: 'RFC',
                    },
                    total: {
                        tooltip: 'Total',
                        value: 'Total',
                    },
                    voucher_status: {
                        tooltip: 'Estatus factura',
                        value: 'Estatus factura',
                    },
                },
            },
            title: 'Consultas - Estado de cuenta',
            customer_groups: {
                placeholder: 'Clientes',
            },
        },
        customerAccountStatement: {
            currency_code: {
                placeholder: 'Moneda',
            },
            customer_id: {
                placeholder: 'Cliente',
            },
            grid: {
                columnHeaders: {
                    antiquity_code: {
                        tooltip: 'Antigüedad',
                        value: 'Antigüedad',
                    },
                    antiquity_status: {
                        tooltip: 'Estatus de vencimiento',
                        value: 'Estatus vencimiento',
                    },
                    credit: {
                        tooltip: 'Abonos',
                        value: 'Abonos',
                    },
                    cfdi_type_id: {
                        tooltip: 'Tipo de documento',
                        value: 'Tipo documento',
                    },
                    currency_code: {
                        tooltip: 'Moneda',
                        value: 'Moneda',
                    },
                    current_balance: {
                        tooltip: 'Saldo al corriente',
                        value: 'Saldo corriente',
                    },
                    customer: {
                        tooltip: 'Razón social',
                        value: 'Razón social',
                    },
                    customer_branch: {
                        tooltip: 'Sucursal',
                        value: 'Sucursal',
                    },
                    customer_group: {
                        tooltip: 'Cliente',
                        value: 'Cliente',
                    },
                    debit: {
                        tooltip: 'Cargos',
                        value: 'Cargos',
                    },
                    document_date: {
                        tooltip: 'Fecha timbrado/cancelación CFDI',
                        value: 'Fecha',
                    },
                    due_date: {
                        tooltip: 'Fecha de vencimiento',
                        value: 'Vencimiento',
                    },
                    folio: {
                        tooltip: 'Folio',
                        value: 'Folio',
                    },
                    series: {
                        tooltip: 'Serie',
                        value: 'Serie',
                    },
                    tax_id: {
                        tooltip: 'RFC',
                        value: 'RFC',
                    },
                    voucher_status: {
                        tooltip: 'Estatus CFDI',
                        value: 'Estatus CFDI',
                    },
                },
            },
            listFailed: '¡No se pudieron obtener los documentos!',
            title: 'Consultas - Estado de cuenta clientes',
        },
        invoicePayments: {
            grid: {
                columnHeaders: {
                    customer: {
                        tooltip: 'Razón social',
                        value: 'Razón social',
                    },
                    customer_branch: {
                        tooltip: 'Sucursal',
                        value: 'Sucursal',
                    },
                    customer_group: {
                        tooltip: 'Cliente',
                        value: 'Cliente',
                    },
                    invoice: {
                        tooltip: 'Factura',
                        value: 'Factura',
                    },
                    invoice_currency_code: {
                        tooltip: 'Moneda factura',
                        value: 'Moneda factura',
                    },
                    iva_08: {
                        tooltip: 'IVA 8%',
                        value: 'IVA 8%',
                    },
                    iva_16: {
                        tooltip: 'IVA 16%',
                        value: 'IVA 16%',
                    },
                    payment: {
                        tooltip: 'Pago',
                        value: 'Pago',
                    },
                    payment_amount: {
                        tooltip: 'Importe pagado',
                        value: 'Importe pagado',
                    },
                    payment_date: {
                        tooltip: 'Fecha de pago',
                        value: 'Fecha pago',
                    },
                    payment_currency_code: {
                        tooltip: 'Moneda pago',
                        value: 'Moneda pago',
                    },
                    ret: {
                        tooltip: 'Retención',
                        value: 'Retención',
                    },
                    subtotal: {
                        tooltip: 'Subtotal neto',
                        value: 'Subtotal neto',
                    },
                    subtotal_iva_0: {
                        tooltip: 'Subtotal IVA 0%',
                        value: 'Subtotal IVA 0%',
                    },
                    subtotal_iva_08: {
                        tooltip: 'Subtotal IVA 8%',
                        value: 'Subtotal IVA 8%',
                    },
                    subtotal_iva_16: {
                        tooltip: 'Subtotal IVA 16%',
                        value: 'Subtotal IVA 16%',
                    },
                    subtotal_iva_exempt: {
                        tooltip: 'Subtotal IVA EXENTO',
                        value: 'Subtotal IVA EXENTO',
                    },
                    subtotal_iva_not_object: {
                        tooltip: 'Subtotal IVA NO OBJETO',
                        value: 'Subtotal IVA NO OBJETO',
                    },
                    tax_id: {
                        tooltip: 'RFC',
                        value: 'RFC',
                    },
                },
            },
            title: 'Consultas - Pagos facturas',
            customer_groups: {
                placeholder: 'Clientes',
            },
        },
    },
    'remarkModal': {
        'customer': 'Cliente: ',
        'confirmCancellation': 'Confirmar cancelación de oferta de carga',
        'load_tender': 'Oferta de carga: ',
        'remarks': 'Agregar observaciones (Opcional)',
        'title': 'oferta de carga',
        'characters_not_allowed': 'Los observaciones no pueden contener los siguientes caracteres: > ~ ^ *',
    },
    'Replace': 'Sustituir',
    'replaceFiscalVoucher': 'Sustituir CFDI',
    'report': {
        'Customers': 'Clientes',
        'Date': 'Fecha',
        'Download': 'Descargar',
        'Drivers': 'Conductores',
        'Freight movements by client': 'Movimientos por cliente',
        'Freight movements by driver': 'Movimientos por conductor',
        'Freight movements per invoice': 'Movimientos por factura',
        'Generate': 'Generar',
        'Invoices by branch': 'Facturas por sucursal',
        'invoicesPerClient': 'Facturas por cliente',
        'pendingBalanceInvoicesPerClient': 'Facturas cuentas por cobrar',
        'report_type': 'Tipo de reporte',
        'Reports': 'Reportes',
        'select_an_option': 'Selecciona una opción',
    },
    'routes': {
        'accounts_receivable': {
            'payments': {
                'create': 'Crear pago',
                'edit': 'Editar pago',
                'show': 'Ver pago',
                'index': 'Pagos',
            },
        },
        bank_accounts: {
            create: 'Crear cuenta bancaria',
            edit: 'Editar cuenta bancaria',
            index: 'Cuentas bancarias',
            show: 'Ver cuenta bancaria',
        },
        coordinated_fiscal_vouchers: {
            index: 'Liquidaciones',
        },
        coordinated_liquidations: {
            edit: 'Editar cédula de liquidación',
            index: 'Cédulas de liquidación',
            show: 'Ver cédula de liquidación',
        },
        coordinated_routes: {
            create: 'Crear ruta',
            edit: 'Editar ruta',
            index: 'Rutas',
            show: 'Ver ruta',
        },
        'credit_notes': {
            'create': 'Crear nota de crédito',
            'edit': 'Editar nota de crédito',
            'show': 'Ver nota de crédito',
        },
        'billing_documents': {
            'index': 'Documentos facturables',
        },
        'customer_groups': {
            'customers': {
                'create': 'Crear',
                'edit': 'Editar',
            },
        },
        'customer_variants': {
            'index': 'Cotizaciones',
        },
        'freight_drivers': {
            create: 'Crear conductor',
            edit: 'Editar conductor',
            index: 'Conductores',
            show: 'Ver conductor',
        },
        'freight_movements': 'Movimientos',
        'freight_templates': {
            'create': 'Crear plantilla de flete',
            'edit': 'Editar plantilla de flete',
            'index': 'Plantillas de flete',
            'show': 'Plantilla de flete',
        },
        'freight_trip_types': {
            'index': 'Tipos de movimiento',
        },
        'freight_trailers': {
            'index': 'Remolques',
        },
        'freight_types': {
            'create': 'Crear tipo de flete',
            'edit': 'Editar tipo de flete',
            'index': 'Tipos de flete',
            'customer_variants': {
                'create': 'Agregar cliente',
                'edit': 'Editar cliente',
            },
        },
        'freights': {
            'create': 'Crear flete',
            'edit': 'Editar flete',
            'index': 'Fletes',
            'show': 'Flete',
        },
        fuel_efficiencies: {
            daily_fuel_consumed: {
                index: 'Consumo diario de combustible',
            },
            fuel_consumed: {
                index: 'Consumo de combustible',
            },
            index: 'Rendimiento de combustible',
        },
        fuel_loads: {
            'create': 'Crear carga de combustible',
            'edit': 'Editar carga de combustible',
            'index': 'Cargas de combustible',
            'show': 'Ver carga de combustible',
        },
        fuel_prices: {
            create: 'Crear precio de combustible',
            edit: 'Editar precio de combustible',
            index: 'Precios de combustible',
            show: 'Ver precio de combustible',
        },
        fuel_units: {
            index: 'Unidades de combustible',
            edit: 'Editar unidad de combustible',
            create: 'Crear unidad de combustible',
            show: 'Ver unidad de combustible',
        },
        'home': 'Inicio',
        'invoices': {
            'freights': {
                'index': 'Agregar flete a factura',
            },
        },
        'invoice_balance_aging': 'Antigüedad saldos facturas',
        'invoicing_vouchers': {
            'fiscal_voucher_addendas': {
                'edit': 'Addendas',
            },
        },
        issuers: {
            index: 'Configuraciones de transportista',
            edit: 'Editar configuración de transportista',
            create: 'Crear configuración de transportista',
            show: 'Ver configuración de transportista',
        },
        'journal_accounts': {
            'index': 'Cuentas contables',
        },
        'journal_voucher_extracts': {
            'create': 'Crear extracto',
            index: 'Ver extractos',
        },
        'load_tenders': {
            'index': 'Ofertas de carga',
            'reject': 'Rechazar oferta de carga',
            'show': 'Oferta de carga',
        },
        'localities': {
            'index': 'Localidades',
        },
        'payments': {
            'index': 'Pagos',
        },
        'queries': {
            'account_statement': 'Consultas - Estado de cuenta',
            'customer_account_statement': 'Consultas - Estado de cuenta clientes',
            'daily_billing': 'Consultas - Cobranza diaria',
            'freight_trips_by_vehicle': 'Consultas - Movimientos',
            'freight_trips_per_invoice': 'Consultas - Facturas por flete',
            'invoice_balance_aging': 'Consultas - Antigüedad saldos factura',
            'invoices_by_vehicle': 'Consultas - Facturación de servicios',
            'invoice_payments': 'Consultas - Pagos facturas',
            'invoices_receivable': 'Facturas por cobrar',
        },
        'reports': 'Reportes',
        'service_request_files': {
            'index': 'Archivos de mercancías',
        },
        'trailer_owners': {
            create: 'Crear propietario',
            edit: 'Editar propietario',
            index: 'Propietarios',
            show: 'Ver propietario',
        },
        'vehicles': {
            'create': 'Crear vehículo',
            'edit': 'Editar vehículo',
            'index': 'Vehículos',
            'show': 'Ver vehículo',
            'without_vehicle_insurance': 'Vehículos sin seguro vehicular',
        },
        'vehicle_insurances': {
            'index': 'Seguros vehiculares',
            'to_expire': 'Seguros vehicular por expirar en los próximos 30 días',
        },
        'vehicle_permits': {
            'index': 'Permisos SCT',
        },
        'voucher_folio_series': {
            'index': 'Series de folios',
        },
    },
    'selectCellEditor': {
        'loadFailed': '¡No se pudieron cargar las opciones!',
    },
    'serviceRequest': {
        downloadTemplate: {
            title: 'Plantilla archivo de mercancías',
            failed: '¡No se pudo descargar el archivo!',
        },
        'form': {
            'customerGroup': {
                'label': 'Cliente',
            },
            'merchandiseFile': {
                'label': 'Archivo de mercancías',
                'select': 'Haga clic para seleccionar y subir archivo',
            },
            'reference': {
                'label': 'Referencia',
                'validationError': 'La referencia debe contener entre 1 y 30 caracteres.',
            },
        },
        'getStopsFailed': '¡No se pudieron obtener los datos de las paradas!',
        'saveFailed': '¡El archivo no se pudo guardar!',
        'saveSuccess': 'El archivo se esta procesando, espera el resultado por correo.',
        serviceRequestFile: {
            serviceRequestFileLocality: {
                assignLocalitiesFailed: '¡Hubo un error al guardar la asignación de localidades!',
                form: {
                    address_locality_label: 'Dirección localidad',
                    locality_label: 'Localidad',
                    stop_label: 'Parada',
                },
                listCustomerLocalitiesFailed: '¡Hubo un error al obtener las localidades asignadas con el cliente!',
                listFailed: '¡Hubo un error al obtener las localidades!',
                title: 'Asignar localidades',
            },
        },
        'title': 'Subir archivo de mercancías',
        'updateStopsFailed': '¡No se pudieron actualizar las paradas!',
    },
    serviceRequestFiles: {
        cancel: {
            fails: '¡Hubo un error al cancelar el archivo de mercancías!',
            message: '¿Desea cancelar la solicitud de servicio {reference}?',
            success: 'La solicitud de servicio fue cancelada exitosamente.',
            title: 'Cancelar solicitud de servicio',
        },
        grid: {
            actions: {
                assign_localities: 'Asignar localidades',
                cancel: 'Cancelar',
                download_merchandises: 'Archivo de mercancías',
                download_merchandise_log: 'Resultado de procesamiento',
            },
            columns: {
                actions: 'Acciones',
                created_at: 'Fecha',
                created_by_user: 'Subido por',
                customer_group_id: 'Cliente',
                file_name: 'Archivo',
                freight_id: 'Flete',
                international_transport_type: 'Transporte internacional',
                reference: 'Referencia',
                status: 'Estatus',
                version: 'Versión',
            },
            status: {
                error_code: {
                    1000: 'asociación de localidades',
                    2000: 'no existe cotización',
                },
            },
        },
        list: {
            error: '¡Hubo un error al obtener los archivos de mercancías!',
        },
        title: 'Archivos de mercancías',
    },
    timepicker: {
        now: 'Hoy',
        selectNow: 'Selecciona Hoy',
        cancel: 'Cancelar',
        set: 'Establecer',
    },
    'trailer': {
        'confirmDelete': {
            'failed': '¡No se pudo eliminar el remolque!',
            'message': '¿Estás seguro de que deseas eliminar el remolque {name}?',
            'notFound': 'No se encontró el remolque {name}',
            'success': 'El remolque se eliminó exitosamente.',
            'title': 'Eliminar remolque',
        },
        'create': {
            'title': 'Crear remolque',
        },
        downloadTemplate: {
            failed: '¡Hubo un error al descargar la plantilla de remolques!',
            title: 'Descargar plantilla de remolques',
        },
        'edit': {
            'title': 'Editar remolque',
        },
        'fields': {
            'last_inspection_date': {
                'label': 'Fecha de última inspección',
            },
            'license_plate': {
                'label': 'Placa',
                'validationError': 'La placa debe contener entre 6 y 7 caracteres alfanuméricos.',
            },
            'measure': {
                'label': 'Medida',
                'validationError': 'La medida debe contener entre 1 y 30 caracteres.',
            },
            'name': {
                'label': 'Nombre',
                'validationError': 'El nombre debe contener entre 1 y 30 caracteres.',
            },
            'origin': {
                'label': 'Origen',
            },
            'serial_number': {
                'label': 'Número de serie',
            },
            'trailer_configuration_code': {
                'label': 'Núm. llantas',
            },
            'trailer_owner_id': {
                'label': 'Propietario',
            },
            'trailer_ownership_id': {
                'label': 'Propiedad',
            },
            'trailer_type_code': {
                'label': 'Tipo',
            },
            weight: {
                label: 'Peso (Tn)',
                validationError: 'El peso debe tener 2 decimales y ser mayor o igual que 0.01.',
            },
        },
        'grid': {
            'actions': 'Acciones',
            'is_active': 'Estatus',
            'last_inspection_date': 'Fecha inspección',
            'license_plate': 'Placa',
            'measure': 'Medida',
            'name': 'Nombre',
            'serial_number': 'No. Serie',
            'origin': 'Origen',
            'trailer_owner_id': 'Propietario',
            'trailer_configuration_code': 'Núm. llantas',
            'trailer_ownership_id': 'Propiedad',
            'trailer_type_code': 'Tipo',
        },
        'origin': {
            'foreign': 'Extranjero',
            'national': 'Nacional',
        },
        'updateTrailer': {
            'failure': '¡No se pudo actualizar el remolque!',
        },
        'getTrailerError': '¡No se pudo obtener el remolque!',
        'getTrailersError': '¡No se pudieron obtener los remolques!',
        'saveFailed': '¡No se pudo guardar el remolque!',
        'saveSuccess': '¡Se guardó exitosamente el remolque!',
        'title': 'Remolques',
    },
    'trailerOwner': {
        'fields': {
            'country': {
                'label': 'País',
            },
            'geo_state': {
                'label': 'Estado',
            },
            is_coordinated: {
                'label': 'Es coordinado',
            },
            'name': {
                'label': 'Nombre',
                'validationError': 'El nombre debe contener entre 1 y 254 caracteres.',
            },
            'postal_code': {
                'label': 'Código postal',
                'validationError': 'El código postal debe contener 5 dígitos.',
            },
            'foreign_postal_code': {
                'label': 'Código postal',
                'validationError': 'El código postal debe coincidir con el patrón requerido por el SAT.',
            },
            'foreign_tax_id': {
                'label': 'Núm. Reg. Trib.',
                'validationError': 'El núm. reg. trib. debe coincidir con el patrón requerido por el SAT.',
            },
            'tax_id': {
                'label': 'RFC',
                'validationError': 'El RFC debe coincidir con el patrón requerido por el SAT.',
            },
        },
        'getFailed': '¡No se pudo obtener el propietario!',
        'getPostalCodeFailed': 'Error al validar el código postal.',
        grid: {
            actions: 'Acciones',
            country: 'País',
            geo_state: 'Estado',
            is_coordinated: 'Régimen de coordinados',
            name: 'Nombre',
            postal_code: 'Código postal',
            tax_id: 'RFC',
        },
        listFailed: '¡Hubo un error al obtener los propietarios!',
        'saveFailed': '¡No se pudo guardar el propietario!',
        'saveSuccess': '¡Se guardó exitosamente el propietario!',
        tabs: {
            trips: 'Movimientos',
        },
        title: 'Propietarios',
    },
    'tripsPerInvoice': {
        'grid': {
            'columnHeaders': {
                'coordinate': {
                    'tooltip': 'Coordenada',
                    'value': 'Coord',
                },
                'currency': {
                    'tooltip': 'Moneda',
                    'value': 'Moneda',
                },
                'customer_branch': {
                    'tooltip': 'Sucursal',
                    'value': 'Sucursal',
                },
                'destination': {
                    'tooltip': 'Destino',
                    'value': 'Destino',
                },
                'discount': {
                    'tooltip': 'Descuento',
                    'value': 'Dto',
                },
                'exchange_rate': {
                    'tooltip': 'Tipo de cambio',
                    'value': 'TC',
                },
                'invoice': {
                    'tooltip': 'Factura',
                    'value': 'Factura',
                },
                'iva_08': {
                    'tooltip': 'IVA 8%',
                    'value': 'IVA 8%',
                },
                'iva_16': {
                    'tooltip': 'IVA 16%',
                    'value': 'IVA 16%',
                },
                'origin': {
                    'tooltip': 'Origen',
                    'value': 'Origen',
                },
                'ret_04': {
                    'tooltip': 'Ret 4%',
                    'value': 'Ret 4%',
                },
                'stamp_date': {
                    'tooltip': 'Fecha factura',
                    'value': 'Fecha fac',
                },
                'started_at': {
                    'tooltip': 'Fecha del movimiento',
                    'value': 'Fecha mov',
                },
                'status': {
                    'tooltip': 'Estatus del movimiento',
                    'value': 'Estatus',
                },
                'subtotal': {
                    'tooltip': 'Subtotal',
                    'value': 'Subtotal',
                },
                'total': {
                    'tooltip': 'Total',
                    'value': 'Total',
                },
                'trailer': {
                    'tooltip': 'Remolque',
                    'value': 'Remolque',
                },
                'type': {
                    'tooltip': 'Tipo de movimiento',
                    'value': 'Tipo',
                },
                'vehicle': {
                    'tooltip': 'Vehículo',
                    'value': 'Vehículo',
                },
            },
        },
        'title': 'Consultas - Facturas por flete',
    },
    upload: {
        cancel: 'Cancelar',
        clearSelectedFiles: 'Limpiar',
        dropFilesHere: 'Arrastrar archivos aqui',
        invalidFileExtension: 'Formato de archivo inválido.',
        invalidFiles: 'Archivo invalido(s). Revisa los requerimientos de los archivos a subir.',
        remove: 'Eliminar',
        retry: 'Reintentar',
        select: 'Seleccionar archivos...',
        total: 'Total',
        uploadSelectedFiles: 'Subir',
    },
    'validation': {
        'attributes': {
            'distance': 'distancia',
            'estimated_arrival': 'hora estimada llegada',
            'estimated_departure': 'hora estimada salida',
            'locality_id': 'localidad',
        },
        'datetime': 'El campo {attribute} no corresponde con una fecha válida.',
        'greaterThan': 'El campo {attribute} debe ser mayor que {other}.',
        'integer': 'El campo {attribute} debe ser un número entero.',
        'max': 'El campo {attribute} debe ser {value} como máximo.',
        'min': 'El campo {attribute} debe ser {value} como mínimo.',
        'numeric': 'El campo {attribute} debe ser un número.',
        'required': 'El campo {attribute} es obligatorio.',
    },
    'vehicle': {
        'confirmDelete': {
            'failed': '¡No se pudo eliminar el vehículo!',
            'message': '¿Estás seguro que deseas eliminar el vehículo {name}?',
            'notFound': 'No se encontró el vehículo {name}',
            'success': 'El vehículo se eliminó exitosamente.',
            'title': 'Eliminar vehículo',
        },
        'create': {
            'title': 'Crear vehículo',
        },
        'createModal': {
            'saveFailed': '¡No se pudo guardar el vehículo!',
            'saveSuccess': '¡Se guardó exitosamente el vehículo!',
        },
        downloadTemplate: {
            failed: '¡Hubo un error al descargar la plantilla de vehículos!',
            title: 'Descargar plantilla de vehículos',
        },
        'edit': {
            'title': 'Editar vehículo',
        },
        'fields': {
            'brand': {
                'label': 'Marca',
            },
            'driver_id': {
                'label': 'Conductor',
            },
            fuel_efficiency: {
                expected_efficiency: {
                    label: 'Rendimiento esperado',
                },
                fuel_type_id: {
                    label: 'Tipo de combustible',
                },
                odometer_unit_code: {
                    label: 'Unidad de odómetro',
                },
            },
            'journal_account': {
                'label': 'Cuenta contable',
                'validationError': 'La cuenta contable es obligatoria.',
            },
            'license_plate': {
                'label': 'Placa',
                'validationError': 'La placa debe coincidir con el patrón requerido por el SAT.',
            },
            'model_year': {
                'label': 'Modelo',
                'validationError': 'El modelo debe coincidir con el patrón requerido por el SAT.',
            },
            'name': {
                'label': 'Nombre',
                'validationError': 'El nombre debe contener entre 1 y 30 caracteres.',
            },
            'ownership_id': {
                'label': 'Propiedad',
            },
            'owner_id': {
                'label': 'Propietario',
            },
            'serie': {
                'label': 'No. de serie',
            },
            vehicle_gross_weight: {
                label: 'Peso bruto vehicular (Tn)',
                validationError: 'El peso bruto vehicular debe tener 2 decimales y ser mayor o igual que 0.01.',
            },
            'vehicle_insurance': {
                'label': 'Seguro vehicular nacional RC',
            },
            'vehicle_permit': {
                'label': 'Permiso SCT',
            },
            'vehicle_type_code': {
                'label': 'Tipo',
            },
        },
        'getVehicleError': '¡No se pudo obtener el vehículo!',
        'grid': {
            'actions': 'Acciones',
            'brand': 'Marca',
            'driver_id': 'Conductor',
            'journal_account': 'Cuenta contable',
            'license_plate': 'Placa',
            'model_year': 'Modelo',
            'ownership_id': 'Propiedad',
            'owner_id': 'Propietario',
            'name': 'Nombre',
            'serie': 'No. de serie',
            'status': 'Estatus',
            'vehicle_type': 'Tipo',
        },
        'label': 'Vehículo',
        'updateVehicle': {
            'failure': '¡No se pudo actualizar el vehículo!',
        },
        'saveFailed': '¡Hubo un error al guardar el vehículo!',
        'saveSuccess': '¡Se guardó exitosamente el vehículo!',
        'title': 'Vehículos',
        'unassign_driver': 'El conductor {driver_name} fue desasignado del vehículo {vehicle_name}',
    },
    'vehicleInsurance': {
        'assignVehicles': {
            'tooltip': 'Vehículos',
            'title': 'Vehículos de la póliza de seguro {policy_number}',
            'failure': '¡No se pudieron asignar los vehículos!',
            'success': '¡Se guardaron los cambios Seguro vehicular-Vehículos exitosamente!',
        },
        'cancel': {
            'title': 'Cancelar seguro vehicular',
            'message': '¿Estás seguro que deseas cancelar el seguro vehicular {policy_number}?',
        },
        'create': {
            'title': 'Crear seguro vehicular',
        },
        'edit': {
            'title': 'Editar seguro vehicular',
        },
        'fields': {
            'company': {
                'label': 'Compañía',
                'validationError': 'La compañía debe contener entre 3 y 50 caracteres',
            },
            'coverages': {
                'label': 'Coberturas',
            },
            'end_date': {
                'label': 'Fecha término',
            },
            'policy_number': {
                'label': 'Núm. póliza',
                'validationError': 'El número de póliza debe contener entre 3 y 30 caracteres',
            },
            'premium': {
                'label': 'Monto prima',
                'validationError': 'El monto de prima debe ser mayor a 0.01',
            },
            'start_date': {
                'label': 'Fecha inicio',
            },
            'type': {
                'label': 'Tipo',
            },
        },
        'getError': '¡No se pudo obtener el seguro vehicular!',
        'getVehicleInsurancesError': '¡No se pudieron obtener los seguros vehiculares!',
        'grid': {
            'actions': 'Acciones',
            'company': 'Compañía',
            'coverages': 'Coberturas',
            'end_date': 'Fecha término',
            'policy_number': 'Núm. póliza',
            'premium': 'Monto prima',
            'start_date': 'Fecha inicio',
            'status': 'Estatus',
            'type': 'Tipo',
        },
        'notFoundError': 'No se encontró el seguro vehicular {policy_number}',
        refreshGrid: 'Actualizar',
        'revert': {
            'tooltip': 'Activar',
            'title': 'Activar seguro vehicular',
            'message': '¿Estás seguro que deseas activar el seguro vehicular {policy_number}?',
        },
        'saveFailed': '¡No se pudo guardar el seguro vehicular!',
        'saveSuccess': '¡Se guardó exitosamente el seguro vehicular!',
        searchByVehicle: 'Búsqueda por vehículo',
        'title': 'Seguros vehiculares',
        'updateStatusFailure': '¡No se pudo cambiar el estatus del seguro vehicular!',
        'updateStatusSuccess': '¡Se cambió el estatus exitosamente del seguro vehicular!',
    },
    'vehiclePermit': {
        'assignVehicles': {
            'failure': '¡No se pudieron asignar los vehículos!',
            'title': 'Vehículos del permiso SCT {number}',
            'tooltip': 'Asociar vehículos',
            'success': '¡Se guardaron los cambios Permiso SCT-Vehículos exitosamente!',
        },
        'cancel': {
            'title': 'Cancelar permiso SCT',
            'message': '¿Estás seguro que deseas cancelar el permiso SCT {number}?',
        },
        'create': {
            'title': 'Crear permiso SCT',
        },
        'edit': {
            'title': 'Editar permiso SCT',
        },
        'fields': {
            'end_date': {
                'label': 'Fecha término',
            },
            'number': {
                'label': 'Número',
                'validationError': 'El número debe contener entre 1 y 50 caracteres',
            },
            'start_date': {
                'label': 'Fecha inicio',
            },
            'vehicle_permit_type_code': {
                'label': 'Tipo',
            },
        },
        'getError': '¡No se pudo obtener el permiso SCT!',
        'grid': {
            'actions': 'Acciones',
            'end_date': 'Fecha término',
            'number': 'Número',
            'start_date': 'Fecha inicio',
            'status': 'Estatus',
            'type': 'Tipo',
            'vehicle': 'Vehículo',
        },
        'notFoundError': 'No se encontró el permiso SCT {number}',
        'revert': {
            'tooltip': 'Revertir cancelación',
            'title': 'Activar permiso SCT',
            'message': '¿Estás seguro que deseas activar el permiso SCT {number}?',
        },
        'saveFailed': '¡No se pudo guardar el permiso SCT!',
        'saveSuccess': '¡Se guardó exitosamente el permiso SCT!',
        'title': 'Permisos SCT',
        'updateStatusFailure': '¡No se pudo cambiar el estatus del permiso SCT!',
        'updateStatusSuccess': '¡Se cambió el estatus exitosamente del permiso SCT!',
    },
    'voucherFolioSeries': {
        'delete': {
            'title': 'Eliminar serie de folio',
            'message': '¿Estás seguro que deseas eliminar la serie de folio {series}?',
            'success': '¡Se eliminó exitosamente la serie de folio!',
            'failure': '¡No se pudo eliminar la serie de folio!',
        },
        'create': {
            'title': 'Crear serie de folio',
        },
        'edit': {
            'title': 'Editar serie de folio',
        },
        'fields': {
            'canceled_journal_voucher_type_id': {
                'label': 'Tipo de póliza de cancelación',
            },
            'folio_end': {
                'label': 'Folio final',
                'validationError': 'El folio final es requerido y debe ser mayor al folio inicial',
            },
            'folio_start': {
                'label': 'Folio inicial',
                'validationError': 'El folio inicial es requerido y debe ser mayor a 0',
            },
            'invoicing_cfdi_type_id': {
                'label': 'Tipo CFDI',
            },
            'series': {
                'label': 'Serie',
                'validationError': 'La serie debe contener entre 1 y 25 caracteres',
            },
            'stamped_journal_voucher_type_id': {
                'label': 'Tipo de póliza de timbrado',
            },
        },
        'getError': '¡No se pudo obtener la serie de folio!',
        'grid': {
            'current_folio': 'Folio actual',
            'folio_end': 'Folio final',
            'folio_start': 'Folio inicial',
            'invoicing_cfdi_type_id': 'Tipo CFDI',
            'stamped_journal_voucher_type_id': 'Tipo de póliza de timbrado',
            'canceled_journal_voucher_type_id': 'Tipo de póliza de cancelación',
            'remaining_folios': 'Folios disponibles',
            'series': 'Serie',
            'actions': 'Acciones',
        },
        'notFoundError': 'No se encontró la serie de folio {series}',
        'saveFailed': '¡No se pudo guardar la serie de folio!',
        'saveSuccess': '¡Se guardó exitosamente la serie de folio!',
        'title': 'Series de folios',
    },
    'Accept': 'Aceptar',
    'Add': 'Agregar',
    'active': 'Activo',
    'addComment': 'Agregar comentario',
    'all': 'Todos',
    'apply': 'Aplicar',
    'Available': 'Disponibles',
    'Cancel': 'Cancelar',
    'Cancelled': 'Cancelado',
    'cancelFiscalVoucher': 'Cancelar CFDI',
    'characters remaining': 'caracteres restantes',
    'close': 'Cerrar',
    'Confirm': 'Confirmar',
    'Could not load values': 'No se pudieron cargar los valores.',
    'Create': 'Crear',
    'Date': 'Fecha',
    'Decline': 'Rechazar',
    'Delete': 'Eliminar',
    'Details': 'Detalles',
    'download': 'Descargar',
    'download_pdf': 'Descargar PDF',
    'download_xml': 'Descargar XML',
    'Edit': 'Editar',
    'emptyList': 'No hay elementos',
    'error': 'Error',
    'export': 'Exportar',
    'filterByCustomer': 'Filtro por cliente',
    'filters': 'Filtros',
    'freightMovement': 'Movimiento de flete',
    'freights': 'Fletes',
    'Home': 'Inicio',
    'inactive': 'Inactivo',
    'invalid': 'Inválido',
    'invalidFilterDateRange': 'Favor de seleccionar un periodo de fechas no mayor a {range} días.',
    'invoices': 'Facturas',
    'import': 'Importar',
    'loadFailed': 'No se pudieron cargar los datos',
    'loading': 'Cargando...',
    'name': 'Nombre',
    'new': 'Nuevo',
    'No': 'No',
    'noFilter': 'Sin filtro',
    'noResultsFound': 'No se encontraron resultados',
    'notify_customer': 'Notificar al cliente',
    'notSpecified': 'No indicado',
    'nullify': 'Anular',
    'previewPdf': 'Vista previa PDF',
    'print_pdf': 'Imprimir PDF',
    'refreshTable': 'Refrescar tabla',
    'reject': 'Rechazar',
    'relationType': 'Tipo de relación',
    'remarks': 'Observaciones',
    'requestFailed': '¡No se pudo procesar la petición!',
    'requestSuccess': 'Se procesó con éxito la petición.',
    'required': 'requerido',
    'retry': 'Reintentar',
    'return': 'Regresar',
    'Save': 'Guardar',
    'Search': 'Búsqueda',
    'Select': 'Seleccionar',
    'Selected': 'Seleccionados',
    'selectFile': 'Haga clic para seleccionar y subir archivo',
    'Show': 'Mostrar',
    'Stamp': 'Timbrar',
    'Submit': 'Enviar',
    'timeAgo': 'Hace {time}',
    'trips': 'Movimientos',
    'typeAtLeastOneCharacters': 'Teclea mínimo 1 caracter',
    'typeAtLeastThreeCharacters': 'Teclea mínimo 3 caracteres',
    'valid': 'Válido',
    'Without selection': 'Sin selección',
    'Yes': 'Sí',
}
